import { Box, Text, BoxProps, Button, Flex, Loader, useMantineTheme, getThemeColor } from '@mantine/core'
import dayjs from 'dayjs'
import useFetchAccount from '../../api/queries/useFetchAccount'
import currencies, { currencyFormat } from '../../utils/currencies'
import { Transaction } from '../../utils/interfaces'
import { IconDocChecked } from '../icons'
import { CSSGrid } from './CSSGrid'
import { colorAliases } from '../../theme/mantineTheme'
import { Flag } from '../Flag'

const BeneficiaryTransactionItem: React.FC<BoxProps & { transaction: Transaction; isFirst: boolean }> = ({ transaction, isFirst, ...props }) => {
  const { isLoading, data: account } = useFetchAccount(transaction.account_id)
  const theme = useMantineTheme()

  if (isLoading) {
    return (
      <Box
        p='xs'
        pl={0}
        c='textPrimary'
        sx={(theme) => ({
          borderTop: isFirst ? undefined : `1pt solid ${getThemeColor('muted', theme)}`,
        })}
        {...props}
      >
        <Flex align='center' justify='center'>
          <Loader />
        </Flex>
      </Box>
    )
  }
  return (
    <Box
      p='xs'
      pl={0}
      key={transaction.id}
      sx={(theme) => ({
        borderTop: isFirst ? undefined : `1pt solid ${getThemeColor('muted', theme)}`,
        [':last-of-type']: {
          borderBottom: `1pt solid ${getThemeColor('muted', theme)}`,
        },
      })}
    >
      <Flex justify='space-between' align='center'>
        <Box c={colorAliases.textInvertSecondary}>
          <Text component='span' fw={700} fz='sm'>
            {dayjs(transaction.created_at).format('YYYY.MM.DD')}
          </Text>
          <Text component='span' fw={700} fz='sm'>
            {dayjs(transaction.created_at).format(' - HH:mm A (Z)')}
          </Text>
        </Box>
        <Flex align='center' c='secondary'>
          <Text fw={300} fz='sm' mb='2xs' mr='3xs'>
            {currencyFormat.format(transaction.amount < 0 ? 0 - transaction.amount : transaction.amount)}
          </Text>
          <Text fw={700} fz='sm' mb='2xs' mr='xs'>
            {transaction.currency}
          </Text>
          <Flag
            countryCode={currencies[transaction.currency]?.countryCodeForFlag}
            square={true}
            style={{
              borderRadius: '50%',
              width: '1rem',
              height: '1rem',
              marginBottom: '0.35rem',
            }}
          />
        </Flex>
      </Flex>
      <CSSGrid pl='2xs' templateColumns={{ base: '1fr', desktop: '3fr 5fr' }}>
        <Box>
          <Flex justify='space-between' lh={1.3} mb='2xs'>
            <Text>Reference</Text>
            <Text c={colorAliases.textInvert} component='b'>
              {transaction.reference}
            </Text>
          </Flex>
          <Flex justify='space-between' lh={1.3}>
            <Text>From</Text>
            <Text c={colorAliases.textInvert}>
              <b>{account?.alias || '-'}</b>
            </Text>
          </Flex>
        </Box>
        <Flex align='center' justify={{ base: 'start', desktop: 'end' }} gap='2xs'>
          <Button
            size='xs'
            p={{ base: '0', desktop: 'inherit' }}
            leftSection={<IconDocChecked style={{ height: '2em', color: getThemeColor('secondary', theme) }} />}
            variant='white'
            color={colorAliases.textInvert}
            onClick={() => {
              window.open('\\dummy\\dummy-invoice.pdf', '_blank')?.focus()
            }}
            styles={{
              leftSection: { marginRight: '0.3rem' },
            }}
          >
            <Box lh='1'>
              Proof of
              <br />
              payment
            </Box>
          </Button>
        </Flex>
      </CSSGrid>
    </Box>
  )
}

export default BeneficiaryTransactionItem

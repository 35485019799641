import { InputBaseProps, MantineTheme, getThemeColor, lighten } from '@mantine/core'

export default function setCustomInputColorStyles(color = 'primary', variant = 'filled') {
  return (theme: MantineTheme, props: InputBaseProps) => ({
    input: {
      borderColor: variant !== 'filled' ? getThemeColor('primary.1', theme) : undefined,
      backgroundColor: variant === 'filled' ? lighten(getThemeColor(color, theme), 0.9) : undefined,
      ':focus, :focus-within': { borderColor: props?.error ? getThemeColor('error', theme) : getThemeColor(color, theme) },
      '&[data-invalid]:focus, &[data-invalid]:focus-within': { borderColor: getThemeColor('error', theme) },
    },
    label: { color: getThemeColor(color, theme) },
  })
}

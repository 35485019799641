import { Button, Group, NumberInput, Radio, Select, SimpleGrid, Text, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { useState } from 'react'
import { IconCloseCross } from '../../../../components/icons'
import { getSelectPropsForCountry } from '../../../../components/inputs/helper'
import { OnboardingCountry, OwnershipType, Person, UboSourceOfWealth } from '../../../../types/onboarding'
import { getLabelForOwnershipType, getLabelForUBOSourceOfWealth } from '../../onboardingUtils'

const ownershipTypeOptions = Object.values(OwnershipType).map((type) => ({
  value: type,
  label: getLabelForOwnershipType(type),
}))

const uboSourceOfWealthOptions = Object.values(UboSourceOfWealth).map((type) => ({
  value: type,
  label: getLabelForUBOSourceOfWealth(type),
}))
interface OwnershipFunctionProps {
  form: ReturnType<typeof useForm<Person>>
}

const OwnershipFunction: React.FC<OwnershipFunctionProps> = ({ form }) => {
  const [additionalCountries, setAdditionalCountries] = useState<OnboardingCountry[]>([])

  const handleAddCountry = () => {
    setAdditionalCountries([...additionalCountries, { countryCode: '', tin: '' }])
    form.setFieldValue('taxIdentification.additionalCountries', [...additionalCountries, { countryCode: '', tin: '' }])
  }

  const handleRemoveCountry = (index: number) => {
    const updatedCountries = [...additionalCountries]
    updatedCountries.splice(index, 1)
    setAdditionalCountries([...updatedCountries])
    form.setFieldValue('taxIdentification.additionalCountries', updatedCountries)
  }

  const handleCountryFieldChange = (index: number, field: string, value: string) => {
    const updatedCountries = [...additionalCountries]
    updatedCountries[index] = { ...updatedCountries[index], [field]: value }
    setAdditionalCountries(updatedCountries)
    form.setFieldValue('taxIdentification.additionalCountries', [...updatedCountries])
  }
  return (
    <>
      <Text size='lg' fw={500} c='secondary' mt='xl' mb='md'>
        Ownership Details
      </Text>
      <SimpleGrid cols={{ base: 1, md: 2 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }} mb='sm'>
        <Select
          labelProps={{ c: 'secondary' }}
          label='Ownership function'
          placeholder='Select ownership function'
          data={ownershipTypeOptions}
          {...form.getInputProps('ownership.type')}
        />
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Tax identification number (TIN) in the country of residence'
          placeholder='Enter TIN'
          {...form.getInputProps('taxIdentification.tinCountryOfResidency')}
        />
      </SimpleGrid>
      <Radio.Group
        mb='sm'
        labelProps={{ c: 'secondary' }}
        label='Is indicated country of residence your only country of residence for tax purposes?'
        {...form.getInputProps('isMultipleTaxResidency')}
        value={form.values.isMultipleTaxResidency === undefined ? undefined : form.values.isMultipleTaxResidency ? 'Yes' : 'No'}
        onChange={(value) => {
          const isMultipleTaxResidency = value === 'Yes'
          form.setFieldValue('isMultipleTaxResidency', isMultipleTaxResidency)
          if (!isMultipleTaxResidency) setAdditionalCountries([])
        }}
      >
        <Group mt='md' gap='md' mb={form.getInputProps('isMultipleTaxResidency').error ? 'sm' : 0}>
          <Radio value='Yes' label='Yes' />
          <Radio value='No' label='No' />
        </Group>
      </Radio.Group>
      {form.values.isMultipleTaxResidency && (
        <>
          {additionalCountries.map((country, index) => (
            <SimpleGrid key={index} cols={{ base: 1, md: 3 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }}>
              <Select
                mb='sm'
                labelProps={{ c: 'secondary' }}
                label={`Country #${index + 1}`}
                placeholder='Select Additional Country'
                searchable
                clearable
                value={country.countryCode}
                {...getSelectPropsForCountry(country?.countryCode || null)}
                onChange={(value) => handleCountryFieldChange(index, 'countryCode', value || '')}
              />
              <TextInput
                labelProps={{ c: 'secondary' }}
                mb='sm'
                label={`Tax identification number (TIN) for Country ${index + 1}`}
                placeholder='Enter TIN'
                value={country.tin}
                onChange={(event) => handleCountryFieldChange(index, 'tin', event.target.value)}
              />
              {index > 0 && (
                <Button
                  leftSection={<IconCloseCross style={{ color: 'black', height: '1rem' }} />}
                  onClick={() => handleRemoveCountry(index)}
                  variant='subtle'
                  h='2.6rem'
                  w='100%'
                  size='xs'
                  mb='sm'
                >
                  Remove Country
                </Button>
              )}
            </SimpleGrid>
          ))}
          <Button variant='filled' color='secondary' h='2.6rem' size='xs' w='100%' onClick={handleAddCountry} mb='sm'>
            + Add additional country of residence
          </Button>
        </>
      )}

      <Radio.Group
        labelProps={{ c: 'secondary' }}
        mb='sm'
        label='Do you have controlling stake, veto rights, or are you the owner of any shares with special voting powers?'
        {...form.getInputProps('ownership.hasVotingControl')}
        value={form.values.ownership.hasVotingControl === undefined ? undefined : form.values.ownership.hasVotingControl ? 'Yes' : 'No'}
        onChange={(value) => {
          form.setFieldValue('ownership.hasVotingControl', value === 'Yes')
        }}
      >
        <Group mt='md' gap='md' mb={form.getInputProps('ownership.hasVotingControl').error ? 'sm' : 0}>
          <Radio value='Yes' label='Yes' />
          <Radio value='No' label='No' />
        </Group>
      </Radio.Group>

      {form.values.ownership.hasVotingControl && (
        <Textarea
          labelProps={{ c: 'secondary' }}
          mb='sm'
          label='Please provide details'
          {...form.getInputProps('ownership.hasVotingControlAdditionalInformation')}
        />
      )}
      <SimpleGrid cols={{ base: 1, md: 2 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }}>
        <NumberInput
          hideControls
          decimalScale={2}
          labelProps={{ c: 'secondary' }}
          label='Share of ownership in the company (%)'
          placeholder='%'
          {...form.getInputProps('ownership.shareSize')}
        />

        <Select
          label='What is the source of wealth of the UBO?'
          labelProps={{ c: 'secondary' }}
          placeholder='Select one'
          data={uboSourceOfWealthOptions}
          {...form.getInputProps('uboSourceOfWealth')}
        />
      </SimpleGrid>
    </>
  )
}

export default OwnershipFunction

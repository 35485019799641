[
  {
    "id": "step_1_basic_details",
    "title": "STEP 1 | Basic Details",
    "description": "Company name, registration number, main clients, service providers, other service entities, etc.",
    "isCompleted": true,
    "href": "step/1"
  },
  {
    "id": "step_2_contact_details",
    "title": "STEP 2 | Contact Details",
    "description": "Registered company address, headquarters address, and contact details.",
    "isCompleted": false,
    "href": "step/2"
  },
  {
    "id": "step_3_advanced_details",
    "title": "STEP 3 | Advanced Details",
    "description": "Company type, structure, account use, and other details.",
    "isCompleted": false,
    "href": "step/3"
  },
  {
    "id": "step_4_representatives",
    "title": "STEP 4 | Representatives",
    "description": "Master Authorised Representative, directors, and Ultimate Beneficial Owners.",
    "isCompleted": false,
    "href": "step/4"
  },
  {
    "id": "step_5_financial_details",
    "title": "STEP 5 | Financial Details",
    "description": "Expected gross turnover, account limits, services, source of income, etc.",
    "isCompleted": true,
    "href": "step/5"
  },
  {
    "id": "step_6_tax_regulations",
    "title": "STEP 6 | Tax Regulations",
    "description": "Tax residency and CRS/FATCA information.",
    "isCompleted": true,
    "href": "step/6"
  },
  {
    "id": "step_7_business_activity",
    "title": "STEP 7 | Business Activity",
    "description": "Detailed description of the business activity and industry/sub-industry that applies to your company.",
    "isCompleted": true,
    "href": "step/7"
  },
  {
    "id": "step_8_data_protection",
    "title": "STEP 8 | Data Protection",
    "description": "GDPR and other applicable legal acts.",
    "isCompleted": false,
    "href": "step/8"
  },
  {
    "id": "step_9_documents",
    "title": "STEP 9 | Documents",
    "description": "Documents to support all the requested information.",
    "isCompleted": false,
    "href": "step/9"
  },
  {
    "id": "step_10_id_verification",
    "title": "STEP 10 | ID Verification",
    "description": "Verify the identity of the Master Authorised Representative.",
    "isCompleted": false,
    "href": "step/10"
  },
  {
    "id": "step_11_review_application",
    "title": "STEP 11 | Review Application",
    "description": "Review the details of the application and submit.",
    "isCompleted": false,
    "href": "step/11"
  }
]

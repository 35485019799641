import { IconChevron, IconMenu } from '../../icons'
import { ActionIcon, Box, Button, Drawer, DrawerProps, Flex, MantineColor, ScrollArea, Text, getThemeColor, useMantineTheme } from '@mantine/core'
import React, { CSSProperties, useState } from 'react'

export interface MenuItemProps {
  textColor?: MantineColor
  descriptionColor?: MantineColor
  borderColor?: MantineColor
  borderWidth?: CSSProperties['borderWidth']
  borderPosition?: 'top' | 'bottom' | 'top and bottom'
  onHoverBackgroundColor?: MantineColor
  title: string
  description: string
  icon?: React.ReactNode
  align?: 'left' | 'right'
  onClick?: () => void
}
export interface MenuProps extends Omit<DrawerProps, 'onClose'> {
  menuButtonTitle?: string | React.ReactNode
  onClose?: () => void
  setOpened: (opened: boolean) => void
}

export const Menu = ({ menuButtonTitle, children, onClose, setOpened, classNames, ...props }: MenuProps) => {
  return (
    <>
      <Drawer
        padding='xl'
        onClose={() => {
          setOpened(false)
          if (onClose) {
            onClose()
          }
        }}
        styles={{
          header: {
            backgroundColor: 'var(--mantine-color-light-0)',
          },
          content: {
            overflow: 'auto',
            backgroundColor: 'var(--mantine-color-light-0)',
          },
          title: {
            width: '100%',
          },
          overlay: {
            backdropFilter: 'blur(5px)',
          },
        }}
        overlayProps={{
          color: '#00000019',
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        {...props}
        closeButtonProps={{
          size: 'lg',
          ...props.closeButtonProps,
        }}
      >
        {children}
      </Drawer>
      <Button
        onClick={() => {
          setOpened(true)
        }}
        color='primary.6'
        variant='subtle'
        py={0}
        px='xs'
        visibleFrom='md'
        leftSection={<IconMenu />}
        ta={'left'}
      >
        {menuButtonTitle}
      </Button>
      <ActionIcon
        onClick={() => {
          setOpened(true)
        }}
        color='primary.6'
        variant='subtle'
        size='lg'
        p={4}
        hiddenFrom='md'
      >
        {<IconMenu />}
      </ActionIcon>
    </>
  )
}

export const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  children,
  title,
  description,
  textColor,
  descriptionColor,
  borderColor = 'white',
  borderWidth = '2pt',
  borderPosition,
  icon,
  align,
  onClick,
}) => {
  const [showSubItems, setShowSubItems] = useState(false)
  const borderStyles: CSSProperties = {}
  const theme = useMantineTheme()
  const themeBorderColor = getThemeColor(borderColor, theme)
  if (borderPosition === 'top' || borderPosition === 'top and bottom') {
    borderStyles.borderTop = `${borderWidth} solid ${themeBorderColor}`
  }

  if (borderPosition === 'bottom' || borderPosition === 'top and bottom') {
    borderStyles.borderBottom = `${borderWidth} solid ${themeBorderColor}`
  }

  return (
    <Flex direction='column' c={textColor}>
      <Flex
        justify='space-between'
        align='center'
        p='md'
        fz='xl'
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          ...borderStyles,
        }}
        key={title}
        onClick={() => {
          if (children !== undefined) setShowSubItems(!showSubItems)
          if (onClick) onClick()
        }}
      >
        <Flex w='100%' c={textColor} justify={align === 'right' ? 'flex-end' : 'flex-start'} ta={align === 'right' ? 'right' : 'left'}>
          {icon && <Box w='10%'>{icon}</Box>}
          <Flex direction='column' c={textColor}>
            <Text fz='lg' lh='1.3' fw={600}>
              {title}
            </Text>
            <Text lh='1.3' fz='sm' c={descriptionColor ?? 'dark.0'}>
              {description}
            </Text>
          </Flex>
        </Flex>
        {children !== undefined && (
          <Box>
            <Box
              component={IconChevron}
              style={{
                transition: 'transform 0.5s ease-in-out 0s',
                transform: showSubItems ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </Box>
        )}
      </Flex>

      {showSubItems && children}
    </Flex>
  )
}

// import { createStyles } from '@mantine/emotion'

// export interface MenuItemProps {
//   textColor?: MantineColor
//   descriptionColor?: MantineColor
//   borderColor?: MantineColor
//   borderWidth?: MantineSize | number | (string & {})
//   borderPosition?: 'top' | 'bottom' | 'top and bottom'
//   onHoverBackgroundColor?: MantineColor
//   title: string
//   description: string
//   icon?: React.ReactNode
//   align?: 'left' | 'right'
//   onClick?: () => void
// }
// export interface MenuProps extends Omit<DrawerProps, 'onClose'> {
//   menuButtonProps?: Partial<ButtonProps>
//   menuButtonTitle?: string | React.ReactNode
//   onClose?: () => void
//   setOpened: (opened: boolean) => void
// }

// const useDrawerStyles = createStyles((theme) => ({
//   header: {
//     backgroundColor: getThemeColor('light.0', theme),
//   },
//   content: {
//     overflow: 'auto',
//     backgroundColor: getThemeColor('light.0', theme),
//   },
//   title: {
//     width: '100%',
//   },
// }))

// export const Menu = ({ menuButtonTitle, menuButtonProps, children, onClose, setOpened, ...props }: MenuProps) => {
//   const { classes } = useDrawerStyles(undefined)
//   return (
//     <>
//       <Drawer
//         padding='xl'
//         size='xl'
//         onClose={() => {
//           setOpened(false)
//           if (onClose) {
//             onClose()
//           }
//         }}
//         {...props}
//         classNames={classes}
//       >
//         {children}
//       </Drawer>
//       <Button
//         onClick={() => {
//           setOpened(true)
//         }}
//         leftSection={!menuButtonProps?.leftSection && !menuButtonProps?.rightSection ? <IconMenu /> : null}
//         color='secondary'
//         variant='subtle'
//         size='medium'
//         py={0}
//         px='xs'
//         {...menuButtonProps}
//       >
//         {menuButtonTitle}
//       </Button>
//     </>
//   )
// }

// export const MenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
//   children,
//   title,
//   description,
//   textColor,
//   descriptionColor,
//   borderColor,
//   borderWidth,
//   borderPosition,
//   onHoverBackgroundColor,
//   icon,
//   align,
//   onClick,
// }) => {
//   const { classes } = useStyles({
//     borderColor,
//     borderWidth,
//     onHoverBackgroundColor,
//     textColor,
//     descriptionColor,
//     borderPosition,
//   })
//   const [showSubItems, setShowSubItems] = useState(false)
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//       <div
//         className={classes.menuItem}
//         key={title}
//         onClick={() => {
//           if (children !== undefined) setShowSubItems(!showSubItems)
//           if (onClick) onClick()
//         }}
//       >
//         <div style={{ display: 'flex', width: '100%' }} className={align === 'right' ? classes.rightAlign : ''}>
//           {icon && <div className={classes.menuIcon}>{icon}</div>}
//           <div style={{ display: 'flex', flexDirection: 'column' }}>
//             <Text className={classes.itemHeader} fw={600}>
//               {title}
//             </Text>
//             <div className={classes.itemDescription}>{description}</div>
//           </div>
//         </div>
//         {children !== undefined && (
//           <Box>
//             <Box
//               component={IconChevron}
//               style={{
//                 transition: 'transform 0.5s ease-in-out 0s',
//                 transform: showSubItems ? 'rotate(180deg)' : 'rotate(0deg)',
//               }}
//             />
//           </Box>
//         )}
//       </div>

//       {showSubItems && children}
//     </div>
//   )
// }

import { Button, FileButton } from '@mantine/core'
import PropTypes from 'prop-types'
import { acceptedDocumentTypes } from '../../utils/constants'
import { IconFundsSend } from '../icons'

function FileUploadButton({ handleFileChange }: { handleFileChange: (file: File | null) => void }) {
  return (
    <FileButton onChange={handleFileChange} accept={acceptedDocumentTypes}>
      {(props) => (
        <Button
          {...props}
          size='sm'
          color='secondary'
          radius='xs'
          variant='filled'
          h='2.1rem'
          leftSection={<IconFundsSend style={{ color: 'white', height: '1em' }} />}
        >
          Attach a document
        </Button>
      )}
    </FileButton>
  )
}

FileUploadButton.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  acceptedDocumentTypes: PropTypes.string,
}

export default FileUploadButton

import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Account } from '../../utils/interfaces'
import { delayEmulation } from '../config'

const fetchAccount = async (accounts: Account[], id: string) => {
  return new Promise<Account | null>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve(accounts?.find((acc) => acc.id === id) ?? null))
      .catch(() => reject(new Error()))
  })
}

export default function useFetchAccount(id = '') {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['accounts', session.accounts, id],
    queryFn: () => fetchAccount(session.accounts, id),
  })
}

import { Anchor, Box, Breadcrumbs, Button, Checkbox, Container, Flex, getThemeColor, Loader, Paper, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchOnboardingInfo from '../../../api/queries/onboarding/useFetchOnboardingInfo'
import useUpdateOnboardingRequest from '../../../api/queries/onboarding/useUpdateOnboardingRequest'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconArrowRight } from '../../../components/icons'
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumber'
import { SearchableSelect, usePropsForCountrySelect } from '../../../components/inputs/SearchableSelect'
import { DEFAULT_ONBOARDING_FORM_FIELDS } from '../../../contexts/onboarding/useOnboardingSessionManager'
import { colorAliases } from '../../../theme/mantineTheme'
import { DeepPartial, OnboardingRequest, OnboardingSteps, Step02ContactDetailsRequest } from '../../../types/onboarding'
import { checkboxStyles, helpBorder } from '../onboardingUtils'

const ContactDetails = () => {
  const navigate = useNavigate()
  const selectedStep = 2
  const { mutate: saveRequest } = useUpdateOnboardingRequest()
  const { data: onboardingInfo, isLoading, isSuccess } = useFetchOnboardingInfo()

  const propsForCountrySelect = usePropsForCountrySelect()

  const [hasDifferentHeadquartersAddress, _setHasDifferentHeadquartersAddress] = useState<boolean>(false)

  const form = useForm<DeepPartial<Step02ContactDetailsRequest>>({
    initialValues: {
      step: OnboardingSteps.STEP_02_CONTACT_DETAILS,
      status: 'in_progress',
      ...DEFAULT_ONBOARDING_FORM_FIELDS,
    },
    validate: {
      company: {
        registeredOfficeAddress: {
          addressLine1: (v) => (v === '' ? 'Please provide the first line of address' : null),
          addressLine2: (v) => (v === '' ? 'Please provide the second line of address' : null),
          city: (v) => (v === '' ? 'Please provide the city' : null),
          postcode: (v) => (v === '' ? 'Please provide the postal/zip code' : null),
          // countryCode: (v) => (v === '' ? 'Please enter the country of incorporation' : null),
        },
        headquartersOfficeAddress: {
          addressLine1: (v) => (hasDifferentHeadquartersAddress && (v === undefined || v === '') ? 'Please provide the first line of address' : null),
          addressLine2: (v) => (hasDifferentHeadquartersAddress && (v === undefined || v === '') ? 'Please provide the second line of address' : null),
          city: (v) => (hasDifferentHeadquartersAddress && (v === undefined || v === '') ? 'Please provide the city' : null),
          postcode: (v) => (hasDifferentHeadquartersAddress && (v === undefined || v === '') ? 'Please provide the postal/zip code' : null),
          countryCode: (v) => (hasDifferentHeadquartersAddress && (v === undefined || v === '') ? 'Please provide the country' : null),
        },
        email: (v) => (v === '' ? 'Please provide an email address to contact the company' : null),
        website: (v) => (v === '' ? 'Please enter the website of the company' : null),
        phoneNumber: (v) =>
          v === undefined || v.countryCode === undefined || v.countryCode === '' || v.number === undefined || v.number === ''
            ? 'Please enter a phone number to contact the company'
            : null,
      },
    },
  })

  const setHasDifferentHeadquartersAddress = (isDifferent: boolean) => {
    _setHasDifferentHeadquartersAddress(isDifferent)
    if (!isDifferent) {
      form.setFieldValue('company.headquartersOfficeAddress', DEFAULT_ONBOARDING_FORM_FIELDS.company?.headquartersOfficeAddress)
    }
  }

  const saveChanges = (continueToNext = false) => {
    form.validate()
    if (form.isValid()) {
      const contactDetails = form.values as Step02ContactDetailsRequest
      if (!hasDifferentHeadquartersAddress) {
        // Headquarters address is the same as registered office address. copying the values as the field `headquartersOfficeAddress` is required
        contactDetails.company.headquartersOfficeAddress = contactDetails.company.registeredOfficeAddress
      }
      saveRequest({ ...form.values } as Step02ContactDetailsRequest, {
        onSuccess: () => (continueToNext ? navigate(`/onboarding/step/${selectedStep + 1}`) : null),
      })
    }
  }

  useEffect(() => {
    if (onboardingInfo && isSuccess && !isLoading) {
      const values = JSON.parse(JSON.stringify(onboardingInfo.onboarding_request)) as DeepPartial<OnboardingRequest>
      if (values.company && values.company.registeredOfficeAddress) {
        values.company.registeredOfficeAddress.countryCode = values.company.countryOfIncorporation
      }
      form.setValues(values)

      setHasDifferentHeadquartersAddress(
        !!onboardingInfo.onboarding_request.company?.headquartersOfficeAddress &&
          JSON.stringify(onboardingInfo.onboarding_request.company?.registeredOfficeAddress) !==
            JSON.stringify(onboardingInfo.onboarding_request.company?.headquartersOfficeAddress)
      )
      form.resetDirty(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingInfo, isLoading, isSuccess])

  if (isLoading || !isSuccess) {
    return (
      <Container py={{ base: '2xl', desktop: '4rem' }} sx={{ isolation: 'isolate' }} w={{ base: '100%', desktop: 'max(37em, 45%)' }}>
        <Paper p='2xl'>
          <Box p='xs' pl={0} c='textPrimary'>
            <Flex align='center' justify='center'>
              <Loader />
            </Flex>
          </Box>
        </Paper>
      </Container>
    )
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 2 | Contact Details</Box>
      </Breadcrumbs>

      <CSSGrid rowGap='lg'>
        <Box lh='1.55' p='1rem' sx={(theme) => ({ background: getThemeColor(colorAliases.light700, theme), ...helpBorder })}>
          <Box fz='sm' fw='bold' c='secondary' pr='1rem' h='2rem'>
            Company registration address
          </Box>
          <Box fz='sm' c='dark.0'>
            Registered office address as it is mentioned in the company documents.
          </Box>
        </Box>

        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Address Line 1'
          placeholder='21 Diagon Avenue'
          {...form.getInputProps('company.registeredOfficeAddress.addressLine1')}
        />
        <TextInput
          labelProps={{ c: 'secondary' }}
          placeholder='Address Line 2'
          label='Address Line 2'
          {...form.getInputProps('company.registeredOfficeAddress.addressLine2')}
        />
        <TextInput labelProps={{ c: 'secondary' }} placeholder='Stockholm' label='Town/City' {...form.getInputProps('company.registeredOfficeAddress.city')} />
        <TextInput
          labelProps={{ c: 'secondary' }}
          placeholder='103 16'
          label='Postal/Zip Code'
          {...form.getInputProps('company.registeredOfficeAddress.postcode')}
        />

        <SearchableSelect
          label='Country of incorporation'
          disabled={true}
          labelProps={{ c: 'secondary' }}
          value={form.values.company?.registeredOfficeAddress?.countryCode}
          description='Country where you will be sending the funds.'
          {...propsForCountrySelect}
        />

        {/* <Select
          disabled={true}
          labelProps={{ c: 'secondary' }}
          label='Country of incorporation'
          value={form.values.company?.registeredOfficeAddress?.countryCode}
          {...getSelectPropsForCountry(form.values.company?.registeredOfficeAddress?.countryCode || null)}
        /> */}

        <Checkbox
          label='My company has a different headquarters address'
          description="Is the company's headquarters/main office at a different location than the registered address?"
          styles={checkboxStyles}
          checked={hasDifferentHeadquartersAddress}
          onChange={(e) => setHasDifferentHeadquartersAddress(e.target.checked)}
        />

        {hasDifferentHeadquartersAddress && (
          <CSSGrid gap='lg' px='lg' py='md' sx={(theme) => ({ background: getThemeColor(colorAliases.light700, theme), border: '1pt dashed gray' })}>
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Address Line 1'
              placeholder='21 Diagon Avenue'
              {...form.getInputProps('company.headquartersOfficeAddress.addressLine1')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='Address Line 2'
              label='Address Line 2'
              {...form.getInputProps('company.headquartersOfficeAddress.addressLine2')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='Stockholm'
              label='Town/City'
              {...form.getInputProps('company.headquartersOfficeAddress.city')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='103 16'
              label='Postal/Zip Code'
              {...form.getInputProps('company.headquartersOfficeAddress.postcode')}
            />

            <SearchableSelect
              label='Country'
              labelProps={{ c: 'secondary' }}
              placeholder='Sweden'
              {...propsForCountrySelect}
              {...form.getInputProps('company.headquartersOfficeAddress.countryCode')}
            />

            {/* <Select
              label='Country'
              labelProps={{ c: 'secondary' }}
              placeholder='Sweden'
              searchable
              {...getSelectPropsForCountry(form.values.company?.headquartersOfficeAddress?.countryCode || null)}
              {...form.getInputProps('company.headquartersOfficeAddress.countryCode')}
            /> */}
          </CSSGrid>
        )}

        <PhoneNumberInput
          label='Company phone number'
          labelProps={{ c: 'secondary' }}
          {...form.getInputProps('company.phoneNumber')}
          // value={
          //   parseToInternationalPhoneNumber(`${form.values.company?.phoneNumber?.countryCode ?? ''}${form.values.company?.phoneNumber?.number ?? ''}`) ?? {
          //     diallingCode: form.values.company?.phoneNumber?.countryCode,
          //     localPhoneNumber: form.values.company?.phoneNumber?.number ?? '',
          //     isValid: false,
          //     countryCode: null,
          //   }
          // }
          // onChange={(ipn) => {
          //   form.setFieldValue('company.phoneNumber', {
          //     countryCode: ipn.diallingCode,
          //     number: ipn.localPhoneNumber,
          //   })
          // }}
          textInputProps={{ placeholder: 'Phone number', fz: 'sm' }}
          searchableSelectProps={{ placeholder: 'Code' }}
        />

        <TextInput labelProps={{ c: 'secondary' }} label='Company email address' placeholder='info@mycompany.com' {...form.getInputProps('company.email')} />
        <TextInput labelProps={{ c: 'secondary' }} placeholder='https://www.mycompany.com' label='Company website' {...form.getInputProps('company.website')} />

        <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto' gap='sm' mt='md'>
          <Button mt='md' onClick={() => navigate(`/onboarding/step/${selectedStep - 1}`)} color='dark'>
            Previous
          </Button>
          <Button h='2.4rem' fz='xs' mt='md' onClick={() => saveChanges(false)} color='primary'>
            Save
          </Button>
          <Button h='2.4rem' fz='xs' mt='md' onClick={() => saveChanges(true)} color='primary'>
            Save and continue
          </Button>
        </CSSGrid>
      </CSSGrid>
    </>
  )
}

export default ContactDetails

// for lazy loading via react-router-dom
export const Component: React.FC = ContactDetails
Component.displayName = 'OnboardingContactDetailsComponent'

import { Anchor, Box, Breadcrumbs, Button, Container, Flex, List, Loader, Select, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchOnboardingInfo from '../../../api/queries/onboarding/useFetchOnboardingInfo'
import useUpdateOnboardingRequest from '../../../api/queries/onboarding/useUpdateOnboardingRequest'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { DEFAULT_ONBOARDING_FORM_FIELDS } from '../../../contexts/onboarding/useOnboardingSessionManager'
import { DeepPartial, OnboardingSteps, Step07BusinessActivityRequest } from '../../../types/onboarding'
import { helpBorder, industrySelectData, subIndustrySelectDataMap } from '../onboardingUtils'
import { IconArrowRight } from '../../../components/icons'
import { colorAliases } from '../../../theme/mantineTheme'

const BusinessActivity = () => {
  const selectedStep = 7
  const navigate = useNavigate()

  const { mutate: saveRequest } = useUpdateOnboardingRequest()

  const { data: onboardingInfo, isLoading, isSuccess } = useFetchOnboardingInfo()

  const form = useForm<DeepPartial<Step07BusinessActivityRequest>>({
    initialValues: {
      step: OnboardingSteps.STEP_07_BUSINESS_ACTIVITY,
      status: 'in_progress',
      ...DEFAULT_ONBOARDING_FORM_FIELDS,
    },
    validate: {
      company: {
        businessActivityDescription: (value) =>
          !value || value.length === 0 ? 'Please enter a description.' : value.length < 50 ? 'Description is too short.' : null,
        businessActivityIndustry: (value) => (value === undefined ? 'Please select the industry of your business' : null),
        businessActivitySubIndustry: (value) => (value === undefined ? 'Please select the industry sub-type of your business' : null),
      },
    },
  })

  // TODO: consider partial saving
  const saveChanges = (continueToNext = false) => {
    form.validate()
    if (form.isValid()) {
      console.log('save details:', form.values, form.isValid())
      saveRequest({ ...form.values } as Step07BusinessActivityRequest, {
        onSuccess: () => (continueToNext ? navigate(`/onboarding/step/${selectedStep + 1}`) : null),
      })
    }
  }

  useEffect(() => {
    if (onboardingInfo && isSuccess && !isLoading) {
      const values = JSON.parse(JSON.stringify(onboardingInfo.onboarding_request)) as never
      form.setValues(values)
      form.resetDirty(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingInfo, isLoading, isSuccess])

  if (isLoading || !isSuccess) {
    return (
      <Container py={{ base: '2xl', desktop: '4rem' }} sx={{ isolation: 'isolate' }} w={{ base: '100%', desktop: 'max(37em, 45%)' }}>
        <Box p='xs' pl={0} c='textPrimary'>
          <Flex align='center' justify='center'>
            <Loader />
          </Flex>
        </Box>
      </Container>
    )
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 7 | Business Activity</Box>
      </Breadcrumbs>

      <CSSGrid gap='lg'>
        <Box p='lg' pb={0} lh='1.55' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
          <Box fz='sm' fw='bold' c='secondary' pr='1rem' lh='1.6'>
            Please provide a detailed description of the business activity of the company applying for the account
          </Box>
          <List px='xs' py='lg' fz='xs'>
            <List.Item>
              If the business is formed within a group structure of companies, please provide a detailed description of the business activities of the group
              forming the business and in addition explain separately for the company applying for the account the exact activity within the group.
            </List.Item>

            <List.Item>If available, please attach a description of your business model.</List.Item>
            <List.Item>If you sell products, please describe the products and how the delivery happens.</List.Item>
          </List>
        </Box>

        <Box>
          <Textarea
            labelProps={{ c: 'secondary' }}
            label='Detailed description of the business activity'
            description={`At least 50 characters required. ${form.values.company?.businessActivityDescription?.length ?? ''} of 50000 characters`}
            minRows={8}
            maxLength={50000}
            {...form.getInputProps('company.businessActivityDescription')}
          />
        </Box>
        <Select
          labelProps={{ c: 'secondary' }}
          label='One core industry that is applicable to your business'
          description=''
          searchable
          {...form.getInputProps('company.businessActivityIndustry')}
          onChange={(v) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            form.getInputProps('company.businessActivityIndustry').onChange(v)
            form.setFieldValue('businessActivitySubIndustry', null)
          }}
          data={industrySelectData}
        />
        <Select
          labelProps={{ c: 'secondary' }}
          disabled={form.values.company?.businessActivityIndustry === undefined}
          label='Type of industry that is applicable to your business'
          searchable
          {...form.getInputProps('company.businessActivitySubIndustry')}
          data={subIndustrySelectDataMap[form.values.company?.businessActivityIndustry as never] ?? []}
        />
      </CSSGrid>

      <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto' gap='sm' mt='md'>
        <Button h='2.4rem' size='xs' mt='md' color='dark' onClick={() => navigate(`/onboarding/step/${selectedStep - 1}`)}>
          Previous
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(false)}>
          Save
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(true)}>
          Save and continue
        </Button>
      </CSSGrid>
    </>
  )
}

export default BusinessActivity

// for lazy loading via react-router-dom
export const Component: React.FC = BusinessActivity
Component.displayName = 'OnboardingBusinessActivityComponent'

import React from 'react'
import { List, ThemeIcon, rem } from '@mantine/core'
import { IconCrossCircle, IconTickCircle } from '../../../components/icons'
import { ClientRole } from '../../../types/onboarding'
import { colorAliases } from '../../../theme/mantineTheme'

interface ListSummaryProps {
  roles: ClientRole[]
}

const ListSummary: React.FC<ListSummaryProps> = ({ roles }) => {
  const allRoles: ClientRole[] = Object.values(ClientRole)

  const formatRole = (role: ClientRole) => {
    const roleFormats: { [key: string]: string } = {
      MAR: `Master Authorized Representative (${role})`,
      UBO: `Ultimate Beneficial Owner (${role})`,
      DIRECTOR: 'Director',
    }
    return roleFormats[role]
  }

  const getRoleMessage = (role: ClientRole): string => {
    const roleStatus = roles.includes(role) ? 'Added' : 'Missing'
    return `${roleStatus} ${formatRole(role)}`
  }

  return (
    <List spacing='xs' size='sm' mb='md' center>
      {allRoles.map((role) => (
        <List.Item
          key={role}
          c='textPrimary'
          icon={
            <ThemeIcon color={!roles.includes(role) ? colorAliases.surfaceLightgray : 'secondary'} radius='xl'>
              {roles.includes(role) ? (
                <IconTickCircle style={{ width: rem(16), height: rem(16) }} />
              ) : (
                <IconCrossCircle style={{ width: rem(16), height: rem(16) }} />
              )}
            </ThemeIcon>
          }
        >
          {getRoleMessage(role)}
        </List.Item>
      ))}
    </List>
  )
}

export default ListSummary

import { ActionIcon, Box, Collapse, Flex, getThemeColor, Paper, PaperProps, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconDownArrow } from '../../../../components/icons'
import { colorAliases } from '../../../../theme/mantineTheme'

const TimingsArea: React.FC<PaperProps> = ({ ...otherPaperProps }) => {
  const [opened, { toggle }] = useDisclosure(true)
  return (
    <Paper p='lg' {...otherPaperProps}>
      <Flex justify='center' align='center' gap='xs'>
        <Title order={3} fz='lg' ta='center'>
          Processing and transaction times
        </Title>
        <ActionIcon variant='transparent' color={colorAliases.borderHighlight} onClick={toggle}>
          <Box component={IconDownArrow} h='2xs' sx={{ transform: !opened ? 'rotate(-90deg)' : undefined, transition: 'transform 200ms linear' }} />
        </ActionIcon>
      </Flex>
      <Collapse in={opened} transitionDuration={500}>
        <Box ta='center' py='md' sx={(theme) => ({ borderBottom: `1px solid ${getThemeColor(colorAliases.textInvert, theme)}` })}>
          <Title order={3} c='secondary' fz='md' ta='center' mb='sm'>
            Sending to another Leikur account
          </Title>
          <Text lh={1.2} fz='sm' color='textMuted.8'>
            Send funds to another Leikur account holder using an email address. Funds are immediately available.
          </Text>
        </Box>
        <Box ta='center' py='md'>
          <Title order={3} c='secondary' fz='md' ta='center' mb='sm'>
            Sending to an IBAN (SEPA)
          </Title>
          <Text lh={1.2} fz='sm' c='textMuted.8'>
            Initiate a SEPA standard bank transfer to any IBAN bank account in participating EU and EFTA countries. Funds are available in 1-2 business days.
          </Text>
        </Box>
      </Collapse>
    </Paper>
  )
}

export default TimingsArea

import { CSSVariablesResolver, defaultVariantColorsResolver, MantineProvider, parseThemeColor, rem, rgba, VariantColorsResolver } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/carousel/styles.css'

import { emotionTransform, MantineEmotionProvider } from '@mantine/emotion'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { SessionProvider } from './contexts/SessionContext'
import mantineTheme from './theme/mantineTheme'
import './index.css'

// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(advancedFormat)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
// dayjs.extend(isSameOrBefore)

const queryClient = new QueryClient()

// Mount the app
const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!) // createRoot(container!) if you use TypeScript

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-primary-text': theme.colors.primary[4],
    '--mantine-color-body': theme.colors.light[4],
    '--mantine-color-text': theme.colors.dark[4],
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.negative[4],
    '--mantine-line-height': '1.15',
  },
  light: {
    '--mantine-color-primary-text': theme.colors.primary[4],
    '--mantine-color-body': theme.colors.light[4],
    '--mantine-color-text': theme.colors.dark[4],
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.negative[4],
    '--mantine-line-height': '1.15',
  },
  dark: {
    '--mantine-color-primary-text': theme.colors.primary[4],
    '--mantine-color-body': theme.colors.light[4],
    '--mantine-color-text': theme.colors.dark[4],
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.negative[4],
    '--mantine-line-height': '1.15',
  },
})

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)

  // variant color resolvers example from Card Noir
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })
  if (input.variant === 'leikur-light') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: 'none',
      color: parsedColor.value,
    }
  }
  if (input.variant === 'leikur-dark') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: 'none',
      color: parsedColor.value,
    }
  }
  if (input.variant === 'outline') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(1)} solid ${parsedColor.value}`,
      color: parsedColor.value,
    }
  }
  if (input.variant === 'filled') {
    return {
      background: parsedColor.value,
      hover: rgba(parsedColor.value, 0.15),
      border: 'none',
      color: 'var(--mantine-color-white-0)',
    }
  }
  if (input.variant === 'subtle') {
    return {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: 'none',
      color: parsedColor.value,
    }
  }

  return defaultResolvedColors
}

root.render(
  <React.StrictMode>
    <MantineProvider theme={{ ...mantineTheme, variantColorResolver }} cssVariablesResolver={resolver} stylesTransform={emotionTransform}>
      <MantineEmotionProvider>
        <SessionProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </SessionProvider>
      </MantineEmotionProvider>
    </MantineProvider>
  </React.StrictMode>
)

import { Flex, Select, SimpleGrid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { getSelectPropsForCountry } from '../../../../components/inputs/helper'
import { Person } from '../../../../types/onboarding'

interface AddressProps {
  form: ReturnType<typeof useForm<Person>>
}

const Address: React.FC<AddressProps> = ({ form }) => (
  <>
    <Text size='lg' fw={500} c='secondary' mt='xl' mb='md'>
      Address
    </Text>
    <SimpleGrid cols={{ base: 1, md: 2 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }}>
      <TextInput labelProps={{ c: 'secondary' }} label='Address line 1' placeholder='Enter address line 1' {...form.getInputProps('address.addressLine1')} />
      <TextInput labelProps={{ c: 'secondary' }} label='Address line 2' placeholder='Enter address line 2' {...form.getInputProps('address.addressLine2')} />
      <TextInput labelProps={{ c: 'secondary' }} label='Town/City' placeholder='Enter town/city' {...form.getInputProps('address.city')} />
      <Flex gap={{ base: 'sm', desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Postal/Zip code'
          placeholder='Enter postal/zip code'
          w='50%'
          {...form.getInputProps('address.postcode')}
        />
        <Select
          labelProps={{ c: 'secondary' }}
          label='Country of residence'
          placeholder='Select country'
          searchable
          clearable
          w='50%'
          {...getSelectPropsForCountry(form.values.address?.countryCode || null)}
          {...form.getInputProps('address.countryCode')}
        />
      </Flex>
    </SimpleGrid>
  </>
)

export default Address

import { Button, Flex, Text } from '@mantine/core'
import { ContextModalProps, modals } from '@mantine/modals'
import { CSSGrid } from '../common/CSSGrid'
import { IconCloseCross } from '../icons'
import ModalWrapper from './ModalWrapper'
import { colorAliases } from '../../theme/mantineTheme'

const RemoveRepresentative = ({ context, id, innerProps }: ContextModalProps<{ deleteRepresentative: () => void }>) => {
  const handleDeleteRepresentative = () => {
    innerProps.deleteRepresentative()
    context.closeModal(id)
  }

  return (
    <ModalWrapper title='Remove Representative' themeColor='primary' onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' p='sm'>
        <Text size='md' ta='center' color='textPrimary'>
          Are you sure you want to remove this representative completely?
        </Text>
        <Flex align='center' justify='right' gap='sm'>
          <Button
            w={125}
            rightSection={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            radius='xs'
            color={colorAliases.borderSubtle}
            c={colorAliases.textInvert}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>
          <Button w={125} radius='xs' variant='filled' color='primary' onClick={handleDeleteRepresentative}>
            Confirm
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForRemoveRepresentativeProps = {
  deleteRepresentative: (representativeId: string) => void
  onClose?: () => void
}
export function openRemoveRepresentativeModal({ deleteRepresentative, onClose }: OpenModalForRemoveRepresentativeProps) {
  return () =>
    modals.openContextModal({
      modal: 'removeRepresentative',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        deleteRepresentative,
      },
    })
}

export default RemoveRepresentative

import { Group, Radio } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { Person } from '../../../../types/onboarding'

enum DirectorPosition {
  EXECUTIVE = 'EXECUTIVE',
  NON_EXECUTIVE = 'NON_EXECUTIVE',
}

interface DirectorsProps {
  form: ReturnType<typeof useForm<Person>>
}

const Directors: React.FC<DirectorsProps> = ({ form }) => (
  <Radio.Group
    mt='xl'
    mb='lg'
    labelProps={{ c: 'secondary', fz: 'lg', fw: 500, mb: 'md' }}
    label='Does the director hold an executive or non-executive position?'
    {...form.getInputProps('isDirectorExecutive')}
    value={
      form.values.isDirectorExecutive === undefined ? undefined : form.values.isDirectorExecutive ? DirectorPosition.EXECUTIVE : DirectorPosition.NON_EXECUTIVE
    }
    onChange={(value) => {
      form.setFieldValue('isDirectorExecutive', value === 'Executive')
    }}
  >
    <Group mt='xs' gap='md' mb={form.getInputProps('isDirectorExecutive').error ? 'sm' : 0}>
      <Radio
        value={DirectorPosition.EXECUTIVE}
        label='Executive'
        sx={{
          label: { color: 'var(--mantine-color-dark-4)', fontWeight: 500 },
        }}
      />
      <Radio
        value={DirectorPosition.NON_EXECUTIVE}
        label='Non-Executive'
        sx={{
          label: { color: 'var(--mantine-color-dark-4)', fontWeight: 500 },
        }}
      />
    </Group>
  </Radio.Group>
)

export default Directors

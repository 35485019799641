import { useContext } from 'react'
import { OnboardingFormContext } from './OnboardingFormProvider'

export const useOnboardingForm = () => {
  const context = useContext(OnboardingFormContext)
  if (!context) {
    throw new Error('useOnboardingForm must be used within an OnboardingFormProvider')
  }
  return context
}

import { Box, Center, Loader, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import useUpdatePaymentRequest from '../../../../api/queries/useUpdatePaymentRequest'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import PaymentBeneficiarySummary from '../PaymentBeneficiarySummary'
import { EMAIL_REGEX, regexValidate } from '../../../../components/inputs/email-validation'

const RequestDetails: React.FC<{ request: PaymentRequest }> = ({ request }) => {
  const { isLoading: isUpdatingPaymentRequest, mutate: updatePaymentRequest } = useUpdatePaymentRequest()

  const paymentRequestForm = useForm<PaymentRequest>({
    initialValues: request,
    validate: {
      beneficiaries: {
        reference: (value) => (value === undefined || value.length === 0 ? 'Please enter a reference' : null),
        amount: (value) => (value !== undefined && value > 0 ? null : 'Please enter an amount greater than 0'),
        popEmail: (value) => (regexValidate(value ?? '', false, EMAIL_REGEX) ? null : 'Please enter a valid email address'),
        popMobileNumber: (value) => (value === undefined || value.isValid ? null : 'Please enter a valid phone number'),
      },
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
  })

  useEffect(() => {
    paymentRequestForm.setValues(JSON.parse(JSON.stringify(request)) as PaymentRequest)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  return isUpdatingPaymentRequest ? (
    <Center py='lg'>
      <Loader color='secondary' />
    </Center>
  ) : (
    <Box>
      {request.status !== PaymentRequestStatus.PENDING_APPROVAL && (
        <Box py='xs' px={{ base: 'md', desktop: 0 }} bg='white'>
          <Text fz='md' fw={600} lh={1.5}>
            {request.status === PaymentRequestStatus.PUSHED_BACK ? 'Required amendments' : 'Rejection reason'}
          </Text>
          <Text fz='sm' lh={1.5} c='textPrimary'>
            {request.statusNote}
          </Text>
        </Box>
      )}
      <Box>
        {request.beneficiaries.map((beneficiary, index, beneficiaries) => (
          <PaymentBeneficiarySummary
            paymentRequestStatus={request.status}
            key={beneficiary.id}
            isLastInGroup={index === beneficiaries.length - 1}
            paymentBeneficiary={beneficiary}
            paymentRequestForm={paymentRequestForm}
            updatePaymentRequest={updatePaymentRequest}
          />
        ))}
      </Box>
    </Box>
  )
}

export default RequestDetails

import { ActionIcon, Box, Divider, getThemeColor, Paper, Text, Title, useMantineTheme, Grid, Group, Card } from '@mantine/core'
import { useState } from 'react'
import SimpleInstructions from '@/components/common/Simple Instructions'
import { IconAdjustCircle, IconEditModifyPen, IconHelpQuestion, IconTickCircle } from '@/components/icons'
import { colorAliases } from '@/theme/mantineTheme'
import onboardingSteps from './onboardingSteps.json'
import { useNavigate, useParams } from 'react-router-dom'

const OnboardingOverview: React.FC = () => {
  const [openHelp, setOpenHelp] = useState(false)
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { invitationCode } = useParams()

  return (
    <>
      {openHelp && (
        <SimpleInstructions
          title='Application Process'
          description="Simply complete each step below and submit your application after reviewing it. You will be notified once it's processed."
          dismissHelp={() => setOpenHelp(false)}
        />
      )}
      <Paper p={{ base: '3xs', desktop: 'lg' }} miw={360} mih={206} pos='relative' sx={{ zIndex: 1 }}>
        <Title fz={{ base: 'xl', desktop: '2xl' }} ta='center' order={2}>
          Welcome to Leikur!
        </Title>
        <ActionIcon variant='transparent' onClick={() => setOpenHelp(true)} color='secondary'>
          <IconHelpQuestion style={{ color: getThemeColor(colorAliases.borderSubtle, theme), height: '1.2em', width: '1.55em' }} />
        </ActionIcon>
        <Divider color='dark' size='xs' mt='sm' />
        <Box ta='center' pt='md' pb='sm' c='secondary' maw='90ch' mx='auto'>
          <Text fw='bold' p='md'>
            Click through our intuitive UI to breeze through each step of your onboarding process effortlessly.
            <br />
            From verifying your identity to setting up accounts, our UI is designed for simplicity. Just follow the prompts and complete each stage at your own
            pace. Need help? Reach out anytime!
          </Text>
        </Box>
        <div>
          <Grid>
            {onboardingSteps.map((step) => (
              <Grid.Col span={4} key={step.id}>
                <Card shadow='sm' padding={0} radius='md' withBorder mih='12rem'>
                  <Group justify='space-between' p='md' mb='md' bg={getThemeColor(step.isCompleted ? 'dark.0' : 'secondary', theme)}>
                    <Title c='light' order={5}>
                      {step.title}
                    </Title>
                    <ActionIcon variant='transparent' size='sm' onClick={() => navigate(`/onboarding/${step.href}/${invitationCode}`)}>
                      <IconEditModifyPen style={{ color: 'white' }} />
                    </ActionIcon>
                  </Group>
                  <Text size='sm' py='md' px='xl' ta='center' fw='bold'>
                    {step.description}
                  </Text>
                  <Group justify='center' c={step.isCompleted ? 'green' : 'red'}>
                    {step.isCompleted ? <IconTickCircle style={{ height: '1.5em' }} /> : <IconAdjustCircle style={{ height: '1.5em' }} />}
                    <Text tt='uppercase'>{step.isCompleted ? 'Completed' : 'Incomplete'}</Text>
                  </Group>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </div>
      </Paper>
    </>
  )
}

export default OnboardingOverview

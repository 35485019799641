import { getThemeColor, MantineTheme } from '@mantine/core'
import { EmotionHelpers, EmotionStyles } from '@mantine/emotion'
import {
  AnnualGrossTurnover,
  BusinessActivityIndustry,
  BusinessActivitySubIndustry,
  CompanyType,
  CrsFatcaType,
  MonthlyGrossTurnover,
  MonthlyTransactionVolume,
  OwnershipType,
  PurposeOfAccount,
  RequestedProduct,
  SourceOfIncome,
  UboSourceOfWealth,
} from '../../types/onboarding'

export const withBorder = (theme: MantineTheme, u: EmotionHelpers) => ({
  [u.largerThan('desktop')]: {
    padding: '1rem',
    borderLeft: `solid 1px ${getThemeColor('backgroundMuted.3', theme)}`,
  },
})

export const helpBorder = (theme: MantineTheme) => ({
  // [theme.fn.smallerThan('desktop')]: {
  padding: '0.5rem',
  paddingBlock: '1rem',
  backgroundColor: getThemeColor('light', theme),
  borderRadius: theme.radius['xs'],
  // },
})

export const checkboxStyles: EmotionStyles = (theme: MantineTheme) => ({
  label: { color: getThemeColor('textPrimary', theme), fontWeight: 'normal' },
})

export function getLabelForEnum(enumString: AnnualGrossTurnover | MonthlyGrossTurnover | MonthlyTransactionVolume | RequestedProduct) {
  let label = enumString.replace(/_/g, ' ')
  label = label.charAt(0)?.toUpperCase() + label.substring(1).toLowerCase().replace('eur', 'EUR')
  return label
}

export function getOptionsFromEnum(myEnum: typeof AnnualGrossTurnover | typeof MonthlyGrossTurnover | typeof MonthlyTransactionVolume) {
  return Object.keys(myEnum).map((type) => {
    return {
      value: myEnum[type as never],
      label: getLabelForEnum(type as never),
    }
  })
}

export function getLabelForCRSFatcaType(type: CrsFatcaType) {
  switch (type) {
    case CrsFatcaType.ACTIVE_FINANCIAL_ENTITY:
      return 'Active Non Financial Entity/Non Financial Foreign Entity'
    case CrsFatcaType.PASSIVE_FINANCIAL_ENTITY:
      return 'Passive Non Financial Entity/Non Financial Foreign Entity'
    case CrsFatcaType.FINANCIAL_AND_FOREIGN_ENTITY:
      return 'Financial Entity/Financial Foreign Entity'
    default:
      return type
  }
}

export const industrySelectData = [
  {
    value: BusinessActivityIndustry.ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES,
    label: 'Accommodation and food service activities',
  },
  {
    value: BusinessActivityIndustry.ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES,
    label: 'Administrative and support service activities',
  },
  {
    value: BusinessActivityIndustry.AGRICULTURE_FORESTRY_AND_FISHING,
    label: 'Agriculture, forestry and fishing',
  },
  {
    value: BusinessActivityIndustry.ARTS_ENTERTAINMENT_AND_RECREATION,
    label: 'Arts, entertainment and recreation',
  },
  {
    value: BusinessActivityIndustry.CONSTRUCTION,
    label: 'Construction',
  },
  {
    value: BusinessActivityIndustry.EDUCATION,
    label: 'Education',
  },
  {
    value: BusinessActivityIndustry.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY,
    label: 'Electricity, gas, steam and air conditioning supply',
  },
  {
    value: BusinessActivityIndustry.FINANCIAL_AND_INSURANCE_ACTIVITIES,
    label: 'Financial and insurance activities',
  },
  {
    value: BusinessActivityIndustry.GAMBLING_AND_BETTING_ACTIVITIES,
    label: 'Gambling and betting activities',
  },
  {
    value: BusinessActivityIndustry.HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES,
    label: 'Human health and social work activities',
  },
  {
    value: BusinessActivityIndustry.INFORMATION_AND_COMMUNICATION,
    label: 'Information and communication',
  },
  {
    value: BusinessActivityIndustry.MANUFACTURING,
    label: 'Manufacturing',
  },
  {
    value: BusinessActivityIndustry.MINING_AND_QUARRYING,
    label: 'Mining and quarrying',
  },
  {
    value: BusinessActivityIndustry.OTHER_SERVICE_ACTIVITIES,
    label: 'Other service activities',
  },
  {
    value: BusinessActivityIndustry.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES,
    label: 'Professional, scientific and technical activities',
  },
  {
    value: BusinessActivityIndustry.PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY,
    label: 'Public administration and defence; compulsory social security',
  },
  {
    value: BusinessActivityIndustry.REAL_ESTATE_ACTIVITIES,
    label: 'Real estate activities',
  },
  {
    value: BusinessActivityIndustry.TRANSPORTATION_AND_STORAGE,
    label: 'Transportation and storage',
  },
  {
    value: BusinessActivityIndustry.WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES,
    label: 'Water supply; sewerage, waste management and remediation activities',
  },
  {
    value: BusinessActivityIndustry.WHOLESALE_AND_RETAIL_TRADE,
    label: 'Wholesale and retail trade',
  },
]

export const subIndustrySelectDataMap = {
  [BusinessActivityIndustry.ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES,
      label: 'Accommodation and food service activities',
    },
  ],
  [BusinessActivityIndustry.ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES]: [
    { value: BusinessActivitySubIndustry.EMPLOYMENT_ACTIVITIES, label: 'Employment activities' },
    {
      value: BusinessActivitySubIndustry.OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES,
      label: 'Office administrative, office support and other business support activities',
    },
    { value: BusinessActivitySubIndustry.RENTAL_AND_LEASING_ACTIVITIES, label: 'Rental and leasing activities' },
    {
      value: BusinessActivitySubIndustry.SECURITY_AND_INVESTIGATION_ACTIVITIES,
      label: 'Security and investigation activities',
    },
    {
      value: BusinessActivitySubIndustry.SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES,
      label: 'Services to buildings and landscape activities',
    },
    {
      value: BusinessActivitySubIndustry.SECURITY_AND_INVESTIGATION_ACTIVITIES,
      label: 'Security and investigation activities',
    },
    {
      value: BusinessActivitySubIndustry.TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES,
      label: 'Travel agency, tour operator and other reservation service and related activities',
    },
  ],
  [BusinessActivityIndustry.AGRICULTURE_FORESTRY_AND_FISHING]: [
    {
      value: BusinessActivitySubIndustry.CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES,
      label: 'Crop and animal production, hunting and related service activities',
    },
    {
      value: BusinessActivitySubIndustry.FISHING_AND_AQUACULTURE,
      label: 'Fishing and aquaculture',
    },
    {
      value: BusinessActivitySubIndustry.FORESTRY_AND_LOGGING,
      label: 'Forestry and logging',
    },
  ],
  [BusinessActivityIndustry.ARTS_ENTERTAINMENT_AND_RECREATION]: [
    {
      value: BusinessActivitySubIndustry.CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES,
      label: 'Creative, arts and entertainment activities',
    },
    {
      value: BusinessActivitySubIndustry.LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES,
      label: 'Libraries, archives, museums and other cultural activities',
    },
    {
      value: BusinessActivitySubIndustry.SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES,
      label: 'Sports activities and amusement and recreation activities',
    },
  ],
  [BusinessActivityIndustry.CONSTRUCTION]: [
    {
      value: BusinessActivitySubIndustry.CONSTRUCTION,
      label: 'Construction',
    },
  ],
  [BusinessActivityIndustry.EDUCATION]: [
    {
      value: BusinessActivitySubIndustry.EDUCATION,
      label: 'Education',
    },
  ],
  [BusinessActivityIndustry.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY]: [
    {
      value: BusinessActivitySubIndustry.ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY,
      label: 'Electricity, gas, steam and air conditioning supply',
    },
  ],
  [BusinessActivityIndustry.FINANCIAL_AND_INSURANCE_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.ACTIVITIES_OF_HOLDING_COMPANIES,
      label: 'Activities of holding companies',
    },
    {
      value: BusinessActivitySubIndustry.BANKS_OR_CREDIT_UNIONS,
      label: 'Banks or Credit Unions',
    },
    {
      value: BusinessActivitySubIndustry.CONSUMER_CREDIT_PAYDAY_LOANS_PAWNBROKERS_PREPAID_AND_OR_GIFT_CARDS,
      label: 'Consumer credit, payday loans, pawnbrokers, prepaid and/or gift cards',
    },
    {
      value: BusinessActivitySubIndustry.CROWDFUNDING,
      label: 'Crowdfunding ',
    },
    {
      value: BusinessActivitySubIndustry.INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY,
      label: 'Insurance, reinsurance and pension funding, except compulsory social security',
    },
    {
      value: BusinessActivitySubIndustry.PAYMENT_SERVICE_PROVIDERS_ELECTRONIC_MONEY_INSTITUTIONS,
      label: 'Payment service providers, Electronic Money Institutions',
    },
    {
      value: BusinessActivitySubIndustry.PHYSICAL_CURRENCY_EXCHANGE,
      label: 'Physical currency exchange',
    },
    {
      value: BusinessActivitySubIndustry.PRIVATE_BANKING,
      label: 'Private Banking',
    },
    {
      value: BusinessActivitySubIndustry.TRADING_FX_AND_INVESTMENT,
      label: 'Trading, FX and Investment',
    },
    {
      value: BusinessActivitySubIndustry.VIRTUAL_CURRENCY,
      label: 'Virtual Currency',
    },
  ],
  [BusinessActivityIndustry.GAMBLING_AND_BETTING_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.LOTTERY,
      label: 'Lottery',
    },
    {
      value: BusinessActivitySubIndustry.ONLINE_GAMING_OR_GAMBLING,
      label: 'Online gaming / Gambling',
    },
    {
      value: BusinessActivitySubIndustry.PAYING_AGENT,
      label: 'Paying agent',
    },
    {
      value: BusinessActivitySubIndustry.PHYSICAL_BETTING_OR_BOOKMAKER,
      label: 'Physical Betting / Bookmaker',
    },
    {
      value: BusinessActivitySubIndustry.PHYSICAL_CASINOS,
      label: 'Physical Casinos',
    },
    {
      value: BusinessActivitySubIndustry.PHYSICAL_SLOT_MACHINES,
      label: 'Physical Slot Machines',
    },
  ],
  [BusinessActivityIndustry.HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.HUMAN_HEALTH_ACTIVITIES,
      label: 'Human health activities',
    },
    {
      value: BusinessActivitySubIndustry.RESIDENTIAL_CARE_AND_SOCIAL_WORK_ACTIVITIES,
      label: 'Residential care and Social work activities',
    },
  ],
  [BusinessActivityIndustry.INFORMATION_AND_COMMUNICATION]: [
    {
      value: BusinessActivitySubIndustry.COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES,
      label: 'Computer programming, consultancy and related activities',
    },
    {
      value: BusinessActivitySubIndustry.INFORMATION_SERVICE_ACTIVITIES,
      label: 'Information service activities',
    },
    {
      value: BusinessActivitySubIndustry.MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES,
      label: 'Motion picture, video and television programme production, sound recording and music publishing activities',
    },
    {
      value: BusinessActivitySubIndustry.PROGRAMMING_AND_BROADCASTING_ACTIVITIES,
      label: 'Programming and broadcasting activities',
    },
    {
      value: BusinessActivitySubIndustry.PUBLISHING_ACTIVITIES,
      label: 'Publishing activities',
    },
    {
      value: BusinessActivitySubIndustry.TELECOMMUNICATIONS,
      label: 'Telecommunications',
    },
  ],
  [BusinessActivityIndustry.MANUFACTURING]: [
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_BASIC_METALS,
      label: 'Manufacture of basic metals,fabricated metal products, except machinery and equipment',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS,
      label: 'Manufacture of basic pharmaceutical products and pharmaceutical preparations',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_BEVERAGES,
      label: 'Manufacture of beverages',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS,
      label: 'Manufacture of chemicals and chemical products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS,
      label: 'Manufacture of coke and refined petroleum products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS,
      label: 'Manufacture of computer, electronic and optical products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_ELECTRICAL_EQUIPMENT,
      label: 'Manufacture of electrical equipment',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_FOOD_PRODUCTS,
      label: 'Manufacture of food products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_FURNITURE,
      label: 'Manufacture of furniture',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS,
      label: 'Manufacture of leather and related products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_NEC,
      label: 'Manufacture of machinery and equipment n.e.c.',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS,
      label: 'Manufacture of motor vehicles, trailers and semi-trailers',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS,
      label: 'Manufacture of other non-metallic mineral products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT,
      label: 'Manufacture of other transport equipment',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS,
      label: 'Manufacture of paper and paper products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS,
      label: 'Manufacture of rubber and plastic products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_TEXTILES,
      label: 'Manufacture of textiles',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_TOBACCO_PRODUCTS,
      label: 'Manufacture of tobacco products',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_WEAPONS_AND_AMMUNITION,
      label: 'Manufacture of weapons and ammunition',
    },
    {
      value: BusinessActivitySubIndustry.MANUFACTURE_OF_WEARING_APPAREL,
      label: 'Manufacture of wearing apparel',
    },
    {
      value:
        BusinessActivitySubIndustry.MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS,
      label: 'Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_MANUFACTURING,
      label: 'Other manufacturing',
    },
    {
      value: BusinessActivitySubIndustry.PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA,
      label: 'Printing and reproduction of recorded media',
    },
    {
      value: BusinessActivitySubIndustry.REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT,
      label: 'Repair and installation of machinery and equipment',
    },
  ],
  [BusinessActivityIndustry.MINING_AND_QUARRYING]: [
    {
      value: BusinessActivitySubIndustry.EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS,
      label: 'Extraction of crude petroleum and natural gas',
    },
    {
      value: BusinessActivitySubIndustry.MINING_OF_COAL_AND_LIGNITE,
      label: 'Mining of coal and lignite',
    },
    {
      value: BusinessActivitySubIndustry.MINING_OF_METAL_ORES,
      label: 'Mining of metal ores',
    },
    {
      value: BusinessActivitySubIndustry.MINING_SUPPORT_SERVICE_ACTIVITIES,
      label: 'Mining support service activities',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_MINING_AND_QUARRYING,
      label: 'Other mining and quarrying',
    },
  ],
  [BusinessActivityIndustry.OTHER_SERVICE_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.ACTIVITIES_OF_MEMBERSHIP_ORGANIZATIONS,
      label: 'Activities of membership organisations',
    },
    {
      value: BusinessActivitySubIndustry.FUNERAL_AND_RELATED_ACTIVITIES,
      label: 'Funeral and related activities',
    },
    {
      value: BusinessActivitySubIndustry.HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT,
      label: 'Hairdressing and other beauty treatment',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_PERSONAL_SERVICE_ACTIVITIES_NEC,
      label: 'Other personal service activities n.e.c.',
    },
    {
      value: BusinessActivitySubIndustry.PHYSICAL_WELL_BEING_ACTIVITIES,
      label: 'Physical well-being activities',
    },
    {
      value: BusinessActivitySubIndustry.REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS,
      label: 'Repair of computers and personal and household goods',
    },
    {
      value: BusinessActivitySubIndustry.WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS,
      label: 'Washing and (dry-)cleaning of textile and fur products',
    },
  ],
  [BusinessActivityIndustry.PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES,
      label: 'Activities of head offices; management consultancy activities',
    },
    {
      value: BusinessActivitySubIndustry.ADVERTISING_AND_MARKET_RESEARCH,
      label: 'Advertising and market research',
    },
    {
      value: BusinessActivitySubIndustry.ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS,
      label: 'Architectural and engineering activities; technical testing and analysis',
    },
    {
      value: BusinessActivitySubIndustry.LEGAL_AND_ACCOUNTING_ACTIVITIES,
      label: 'Legal and accounting activities',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES,
      label: 'Other professional, scientific and technical activities',
    },
    {
      value: BusinessActivitySubIndustry.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT,
      label: 'Scientific research and development ',
    },
    {
      value: BusinessActivitySubIndustry.VETERINARY_AND_PET_CARE_SERVICES,
      label: 'Veterinary and pet care services',
    },
  ],
  [BusinessActivityIndustry.PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY]: [
    {
      value: BusinessActivitySubIndustry.PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY,
      label: 'Public administration and defence; compulsory social security',
    },
  ],
  [BusinessActivityIndustry.REAL_ESTATE_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.REAL_ESTATE_ACTIVITIES,
      label: 'Real estate activities',
    },
  ],
  [BusinessActivityIndustry.TRANSPORTATION_AND_STORAGE]: [
    {
      value: BusinessActivitySubIndustry.AIR_TRANSPORT,
      label: 'Air transport',
    },
    {
      value: BusinessActivitySubIndustry.LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES,
      label: 'Land transport and transport via pipelines',
    },
    {
      value: BusinessActivitySubIndustry.POSTAL_AND_COURIER_ACTIVITIES,
      label: 'Postal and courier activities',
    },
    {
      value: BusinessActivitySubIndustry.WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION,
      label: 'Warehousing and support activities for transportation',
    },
    {
      value: BusinessActivitySubIndustry.WATER_TRANSPORT,
      label: 'Water transport',
    },
  ],
  [BusinessActivityIndustry.WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES]: [
    {
      value: BusinessActivitySubIndustry.WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES,
      label: 'Water supply; sewerage, waste management and remediation activities',
    },
  ],
  [BusinessActivityIndustry.WHOLESALE_AND_RETAIL_TRADE]: [
    {
      value: BusinessActivitySubIndustry.DISPENSING_CHEMIST_IN_SPECIALISED_STORES,
      label: 'Dispensing chemist in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.NON_SPECIALISED_WHOLESALE_TRADE,
      label: 'Non-specialised wholesale trade',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES,
      label: 'Other retail sale of new goods in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.OTHER_SPECIALISED_WHOLESALE,
      label: 'Other specialised wholesale',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_IN_NON_SPECIALISED_STORES,
      label: 'Retail sale in non-specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES,
      label: 'Retail sale of automotive fuel in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES,
      label: 'Retail sale of clothing in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES,
      label: 'Retail sale of cosmetic and toilet articles in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES,
      label: 'Retail sale of cultural and recreation goods in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES,
      label: 'Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES,
      label: 'Retail sale of food, beverages and tobacco in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES,
      label: 'Retail sale of footwear and leather goods in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES,
      label: 'Retail sale of information and communication equipment in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES,
      label: 'Retail sale of medical and orthopaedic goods in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES,
      label: 'Retail sale of other household equipment in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES,
      label: 'Retail sale of second-hand goods in stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES,
      label: 'Retail sale of watches and jewellery in specialised stores',
    },
    {
      value: BusinessActivitySubIndustry.RETAIL_TRADE_IN_MARKETPLACES,
      label: 'Retail trade in marketplaces',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES,
      label: 'Wholesale and retail trade and repair of motor vehicles and motorcycles',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS,
      label: 'Wholesale of agricultural raw materials and live animals',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO,
      label: 'Wholesale of food, beverages and tobacco',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_OF_HOUSEHOLD_GOODS,
      label: 'Wholesale of household goods',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT,
      label: 'Wholesale of information and communication equipment',
    },
    {
      value: BusinessActivitySubIndustry.WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES,
      label: 'Wholesale of other machinery, equipment and supplies',
    },
  ],
}

export function getLabelForPurposeOfAccount(purpose: PurposeOfAccount) {
  switch (purpose) {
    case PurposeOfAccount.EMPLOYEE_REMUNERATION_SALARY:
      return 'Employee remuneration / Salary'
    case PurposeOfAccount.INTRA_GROUP_TRANSACTIONS_CLIENTS:
      return 'Intra-group transactions'
    case PurposeOfAccount.OTHER:
      return 'Other'
    case PurposeOfAccount.PAYMENTS_TO_SERVICE_PROVIDER:
      return 'Payments to service providers'
    case PurposeOfAccount.RECEIVING_FUNDS_FROM_YOUR_CLIENTS:
      return 'Receiving funds from clients'
    case PurposeOfAccount.RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS:
      return 'Receiving funds on behalf of company(ies) or person(s)'
    case PurposeOfAccount.RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS:
      return 'Receiving merchant processing settlements'
    default:
      return purpose
  }
}

export function getLabelForSourceOfIncome(souce: SourceOfIncome) {
  switch (souce) {
    case SourceOfIncome.DIVIDENDS:
      return 'Dividends'
    case SourceOfIncome.DONATIONS:
      return 'Donations'
    case SourceOfIncome.INTEREST:
      return 'Interest'
    case SourceOfIncome.BUSINESS_PROCEEDS:
      return 'Business proceeds'
    case SourceOfIncome.OTHER:
      return 'Other'
    default:
      return souce
  }
}

export function getLabelForCompanyType(companyType: CompanyType) {
  switch (companyType) {
    case CompanyType.ASSOCIATION:
      return 'Association'
    case CompanyType.CHARITABLE_ORGANIZATION:
      return 'Charitable organization'
    case CompanyType.FOUNDATION:
      return 'Foundation'
    case CompanyType.GOVERNMENT_OWNED_COMPANY:
      return 'Government-owned company'
    case CompanyType.HOLDING_COMPANY:
      return 'Holding company'
    case CompanyType.NON_PROFIT_ORGANIZATION:
      return 'Non-profit organization'
    case CompanyType.ONE_PERSON_COMPANY_OR_SOLE_PROPRIETORSHIP:
      return 'One person company/sole proprietorship'
    case CompanyType.PARTNERSHIP:
      return 'Partnership'
    case CompanyType.PRIVATELY_OWNED_COMPANY:
      return 'Privately owned company'
    case CompanyType.PUBLICLY_TRADED_COMPANY:
      return 'Publicly traded company'
    case CompanyType.TRADING_COMPANY:
      return 'Trading company'
    case CompanyType.TRUST:
      return 'Trust'
    case CompanyType.OTHER:
      return 'Other'
    default:
      return companyType
  }
}

export function getLabelForOwnershipType(ownershipType: OwnershipType): string {
  switch (ownershipType) {
    case OwnershipType.BENEFICIARY:
      return 'Beneficiary'
    case OwnershipType.DIRECT_OR_INDIRECT_SHAREHOLDER:
      return 'Direct or indirect shareholder'
    case OwnershipType.FIDUCIARY:
      return 'Fiduciary'
    case OwnershipType.INDIVIDUAL_WHO_OTHERWISE_EXERCISES_CONTROL:
      return 'Individual who otherwise exercises control'
    case OwnershipType.PROTECTOR:
      return 'Protector'
    case OwnershipType.SETTLOR:
      return 'Settlor'
    case OwnershipType.TRUSTEE:
      return 'Trustee'
    case OwnershipType.OTHER:
      return 'Other'
    default:
      return ownershipType
  }
}

export function getLabelForUBOSourceOfWealth(source: UboSourceOfWealth) {
  switch (source) {
    case UboSourceOfWealth.EMPLOYMENT_INCOME:
      return 'Employment income'
    case UboSourceOfWealth.BUSINESS_PROFITS_OR_DIVIDENDS:
      return 'Business profits or dividends'
    case UboSourceOfWealth.SALE_OF_INVESTMENTS_OR_LIQUIDATION_OF_INVESTMENT_PORTFOLIO:
      return 'Sale of investments or liquidation of investment portfolio'
    case UboSourceOfWealth.SALE_OF_PROPERTY:
      return 'Sale of property'
    case UboSourceOfWealth.LOAN_AND_FINANCING:
      return 'Loan and financing'
    case UboSourceOfWealth.INHERITANCE:
      return 'Inheritance'
    case UboSourceOfWealth.GIFT:
      return 'Gift'
    case UboSourceOfWealth.OTHER:
      return 'Other'
    default:
      return source
  }
}

import {
  Box,
  BoxProps,
  ElementProps,
  InlineStyles,
  MantineSpacing,
  PolymorphicFactory,
  StyleProp,
  StylesApiProps,
  filterProps,
  parseStyleProps,
  polymorphicFactory,
  useMantineTheme,
  useProps,
  useRandomClassName,
  useStyles,
} from '@mantine/core'
import { CSSGRID_SYSTEM_PROPS } from './cssgrid-props'

export type CSSGridStylesNames = 'root'

// eslint-disable-next-line no-use-before-define
export interface CSSGridProps extends Omit<BoxProps, 'styles'>, StylesApiProps<CSSGridFactory>, ElementProps<'div'> {
  /** gap CSS property */
  gap?: StyleProp<MantineSpacing>

  /** row-gap CSS property */
  rowGap?: StyleProp<MantineSpacing>

  /** column-gap CSS property */
  columnGap?: StyleProp<MantineSpacing>

  /** align-items CSS property */
  alignItems?: StyleProp<React.CSSProperties['alignItems']>

  /** align-content CSS property */
  alignContent?: StyleProp<React.CSSProperties['alignContent']>

  /** justify-items CSS property */
  justifyItems?: StyleProp<React.CSSProperties['justifyItems']>

  /** justify-content CSS property */
  justifyContent?: StyleProp<React.CSSProperties['justifyContent']>

  /** grid-auto-flow CSS property */
  autoFlow?: StyleProp<React.CSSProperties['gridAutoFlow']>

  /** grid-auto-columns CSS property */
  autoColumns?: StyleProp<React.CSSProperties['gridAutoColumns']>

  /** grid-auto-Rows CSS property */
  autoRows?: StyleProp<React.CSSProperties['gridAutoRows']>

  /** grid-template-areas CSS property */
  templateAreas?: StyleProp<React.CSSProperties['gridTemplateAreas']>

  /** grid-template-columns CSS property */
  templateColumns?: StyleProp<React.CSSProperties['gridTemplateColumns']>

  /** grid-template-rows CSS property */
  templateRows?: StyleProp<React.CSSProperties['gridTemplateRows']>
}

export type CSSGridFactory = PolymorphicFactory<{
  props: CSSGridProps
  defaultRef: HTMLDivElement
  defaultComponent: 'div'
  stylesNames: CSSGridStylesNames
}>

const defaultProps: Partial<CSSGridProps> = {}

export const CSSGrid = polymorphicFactory<CSSGridFactory>((_props, ref) => {
  const props = useProps('CSSGrid', defaultProps, _props)
  const {
    classNames,
    className,
    style,
    styles,
    unstyled,
    vars,
    gap,
    rowGap,
    columnGap,
    alignItems,
    alignContent,
    justifyItems,
    justifyContent,
    autoFlow,
    autoColumns,
    autoRows,
    templateAreas,
    templateColumns,
    templateRows,
    ...others
  } = props

  const getStyles = useStyles<CSSGridFactory>({
    name: 'CSSGrid',
    props,
    className,
    style,
    classNames,
    classes: { root: '' },
    styles: {
      root: {
        display: 'grid',
      },
    },
    unstyled,
    vars,
  })

  const theme = useMantineTheme()
  const responsiveClassName = useRandomClassName()
  const parsedStyleProps = parseStyleProps({
    styleProps: {
      gap,
      rowGap,
      columnGap,
      alignItems,
      alignContent,
      justifyItems,
      justifyContent,
      autoFlow,
      autoColumns,
      autoRows,
      templateAreas,
      templateColumns,
      templateRows,
    },
    theme,
    data: CSSGRID_SYSTEM_PROPS,
  })

  return (
    <>
      {parsedStyleProps.hasResponsiveStyles && (
        <InlineStyles selector={`.${responsiveClassName}`} styles={parsedStyleProps.styles} media={parsedStyleProps.media} />
      )}
      <Box
        ref={ref}
        {...getStyles('root', {
          className: responsiveClassName,
          style: filterProps(parsedStyleProps.inlineStyles),
        })}
        {...others}
      />
    </>
  )
})

CSSGrid.displayName = '@mantine/core/CSSGrid'

import { Container, Loader } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import useFetchBeneficiaries from '../../../api/queries/useFetchBeneficiaries'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { BeneficiaryProvider } from '../../../contexts/BeneficiaryContext'
import { Beneficiary } from '../../../utils/interfaces'
import BeneficiaryCentreList from './BeneficiaryCentreList'
import BeneficiaryCentreSearchIndividual from './BeneficiaryCentreSearchIndividual'

export enum FILTER_ENTITY_TYPE {
  Individual = 'individual',
  Corporate = 'corporate',
  All = 'all',
}
export interface BeneficiaryFilter {
  nameOrAliasOrReference: string
  entity: FILTER_ENTITY_TYPE
  status: 'active' | 'inactive' | 'pending'
  country: string
  currency: string
}

const BeneficiaryCentre = () => {
  const { isLoading, data: beneficiaries } = useFetchBeneficiaries()

  const [filters, setFilters] = useSetState<BeneficiaryFilter>({
    nameOrAliasOrReference: '',
    entity: FILTER_ENTITY_TYPE.All,
    country: '',
    currency: '',
    status: 'active',
  })

  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState<Beneficiary[]>([])

  useEffect(() => {
    if (beneficiaries) setFilteredBeneficiaries(beneficiaries)
  }, [beneficiaries])

  useEffect(() => {
    setFilteredBeneficiaries(
      beneficiaries?.filter((beneficiary) => {
        if (filters.entity !== FILTER_ENTITY_TYPE.All && beneficiary.entity_type !== filters.entity) {
          return false
        }
        if (filters.country !== null && beneficiary.country !== filters.country) {
          return false
        }
        if (filters.currency !== '' && (beneficiary.currency as string) !== filters.currency) {
          return false
        }
        if (
          !(
            beneficiary.name.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase()) ||
            beneficiary.alias.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase()) ||
            beneficiary.reference?.toLowerCase().includes(filters.nameOrAliasOrReference.toLowerCase())
          )
        ) {
          return false
        }
        return true
      }) ?? []
    )
  }, [beneficiaries, filters])

  if (isLoading) {
    return (
      <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Container>
    )
  }

  return (
    <BeneficiaryProvider>
      <Container my='lg' py={{ base: 'xl', desktop: '3xl' }}>
        <CSSGrid
          columnGap={{ base: 'xl', desktop: 'xl' }}
          rowGap='xl'
          alignItems='start'
          templateAreas={{
            base: '"filters" "list"',
            desktop: '"filters" "list"',
          }}
          templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
        >
          <BeneficiaryCentreSearchIndividual filters={filters} setFilters={setFilters} />
          <BeneficiaryCentreList beneficiaries={filteredBeneficiaries} />
        </CSSGrid>
      </Container>
    </BeneficiaryProvider>
  )
}

export default BeneficiaryCentre

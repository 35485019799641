import dayjs from 'dayjs'
import { produce } from 'immer'
import { OnboardingSession, OnboardingSessionReducerAction, OnboardingSessionReducerActionType } from '../../types/onboarding'

export default (saveToLocalStorage: (os: OnboardingSession) => void) => (session: OnboardingSession, action: OnboardingSessionReducerAction) => {
  const nextState = produce(session, (draft) => {
    switch (action.type) {
      case OnboardingSessionReducerActionType.UPDATE_ONBOARDING_REQUEST:
        draft.last_update = dayjs().toISOString()

        if (action.payload.company) {
          draft.onboarding_request.company = {
            ...draft.onboarding_request.company,
            ...action.payload.company,
          }
        }
        if (action.payload.account) {
          draft.onboarding_request.account = {
            ...draft.onboarding_request.account,
            ...action.payload.account,
          }
        }
        if (action.payload.persons) {
          draft.onboarding_request.persons = [...action.payload.persons]
        }
        if (action.payload.other) {
          draft.onboarding_request.other = {
            ...draft.onboarding_request.other,
            ...action.payload.other,
          }
        }

        draft.steps_status_map[action.payload.step].status = action.payload.status
        draft.steps_status_map[action.payload.step].last_modified_by = 'admin'
        draft.steps_status_map[action.payload.step].last_modified_date = dayjs().toISOString()
        break
    }
  })
  saveToLocalStorage(nextState)
  return nextState
}

import { ActionIcon, Box, Collapse, Flex, getThemeColor, Text, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'

import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconDownArrow, IconFundsCard, IconFundsReceive, IconFundsSend, IconFundsTransfer } from '../../../../components/icons'
import currencies, { currencyFormat } from '../../../../utils/currencies'
import { Transaction } from '../../../../utils/interfaces'
import formatIBAN from '../../../../utils/formatIBAN'
import { INTERNAL_TRANSFER_REFERENCE_SUFFIX } from '../../../../utils/constants'
import { colorAliases } from '../../../../theme/mantineTheme'
import { Flag } from '../../../../components/Flag'
import { lighten } from '@mantine/core'

enum TransactionType {
  INTERNAL_TRANSFER_OUT = 'INTERNAL_TRANSFER_OUT',
  INTERNAL_TRANSFER_IN = 'INTERNAL_TRANSFER_IN',
  CARD_PAYMENT = 'CARD_PAYMENT',
  BANK_TRANSFER_IN = 'BANK_TRANSFER_IN',
  BANK_TRANSFER_OUT = 'BANK_TRANSFER_OUT',
}

const getTransactionMetadata = (transaction: Transaction, groupThemeAccent: string) => {
  const transactionType =
    transaction.type !== 'bank-transfer'
      ? TransactionType.CARD_PAYMENT
      : transaction.reference.indexOf(INTERNAL_TRANSFER_REFERENCE_SUFFIX) > -1
      ? transaction.amount >= 0
        ? TransactionType.INTERNAL_TRANSFER_IN
        : TransactionType.INTERNAL_TRANSFER_OUT
      : transaction.amount >= 0
      ? TransactionType.BANK_TRANSFER_IN
      : TransactionType.BANK_TRANSFER_OUT

  switch (transactionType) {
    case TransactionType.CARD_PAYMENT:
      return {
        transactionType: transactionType,
        description: 'Spent at',
        icon: <Box component={IconFundsCard} c={groupThemeAccent} sx={{ gridArea: 'icon', height: '1.5em' }} />,
      }
    case TransactionType.INTERNAL_TRANSFER_IN:
      return {
        transactionType: transactionType,
        description: 'Internal transfer from',
        icon: <Box component={IconFundsTransfer} c={groupThemeAccent} sx={{ gridArea: 'icon', height: '1.5em' }} />,
      }
    case TransactionType.INTERNAL_TRANSFER_OUT:
      return {
        transactionType: transactionType,
        description: 'Internal transfer to',
        icon: <Box component={IconFundsTransfer} c={groupThemeAccent} sx={{ gridArea: 'icon', height: '1.5em' }} />,
      }
    case TransactionType.BANK_TRANSFER_IN:
      return {
        transactionType: transactionType,
        description: 'Received from',
        icon: <Box component={IconFundsReceive} c={groupThemeAccent} sx={{ gridArea: 'icon', height: '1.5em' }} />,
      }
    case TransactionType.BANK_TRANSFER_OUT:
      return {
        transactionType: transactionType,
        description: 'Sent to',
        icon: <Box component={IconFundsSend} sx={{ gridArea: 'icon' }} />,
      }
  }
}

const TransactionItem: React.FC<{ transaction: Transaction; groupThemeAccent: string }> = ({ transaction, groupThemeAccent, ...props }) => {
  const theme = useMantineTheme()
  const [showDetails, showDetailsHandler] = useDisclosure(false)
  const transactionMeta = getTransactionMetadata(transaction, groupThemeAccent)

  return (
    <Box
      {...props}
      py='sm'
      sx={{
        [':nth-of-type(odd)']: {
          background: getThemeColor(colorAliases.surfaceLight, theme),
          borderRadius: theme.spacing.xs,
        },
      }}
    >
      <CSSGrid
        templateColumns={{ base: 'auto 1fr auto', desktop: 'auto 1fr auto auto' }}
        templateAreas={{
          base: '"icon description arrow" "icon name amount"',
          desktop: '"icon description amount arrow" "icon name amount arrow"',
        }}
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={() => showDetailsHandler.toggle()}
        px='sm'
        rowGap='3xs'
        columnGap='sm'
      >
        {transactionMeta.icon}
        <Box c={colorAliases.brandSecondary} fz='sm' sx={{ gridArea: 'description' }}>
          <Text fz='sm' fw='200'>
            {transactionMeta.description}
          </Text>
        </Box>
        <Box c={colorAliases.textInvertSecondary} fz='sm' sx={{ gridArea: 'name' }}>
          <Text fz='sm' fw='600'>
            {transaction.counterparty.account_holder_name}
          </Text>
        </Box>
        <Flex
          fz='sm'
          sx={{ gridArea: 'amount' }}
          gap='2xs'
          align='center'
          c={
            transactionMeta.transactionType === TransactionType.BANK_TRANSFER_OUT || transactionMeta.transactionType === TransactionType.INTERNAL_TRANSFER_OUT
              ? getThemeColor('textPrimary', theme)
              : getThemeColor(groupThemeAccent, theme)
          }
        >
          {currencyFormat.format(transaction.amount) + '  ' + transaction.currency}
          <Flag
            countryCode={currencies[transaction.currency]?.countryCodeForFlag}
            square={true}
            sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
            style={{
              borderRadius: '50%',
              width: '1rem',
              height: '1rem',
            }}
          />
        </Flex>
        <ActionIcon
          variant='transparent'
          color='secondary'
          size='xs'
          onClick={() => showDetailsHandler.toggle()}
          sx={{ gridArea: 'arrow', justifySelf: 'end' }}
        >
          <Box
            component={IconDownArrow}
            sx={{
              transform: showDetails ? 'rotateX(180deg)' : undefined,
              transition: 'transform 0.5s ease-in-out 0s',
              width: '1rem',
            }}
          />
        </ActionIcon>
      </CSSGrid>
      <Collapse in={showDetails} transitionDuration={500} transitionTimingFunction='ease'>
        <Box
          // c='dark.9'
          mt='sm'
          mx={{ desktop: 'sm' }}
          py='md'
          px={{ base: 'sm', desktop: '3xs' }}
          sx={{
            color: lighten(getThemeColor('dark', theme), 0.1),
            borderTop: `1pt solid ${getThemeColor(groupThemeAccent, theme)}`,
          }}
        >
          {transactionMeta.transactionType.startsWith('INTERNAL_') || transaction.amount > 0 ? (
            <>
              <Text fw='700' fz='sm' mb='xs'>
                Transaction details
              </Text>
              <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} columnGap='md' rowGap='sm'>
                <Box>
                  <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                    <Text>Transaction received</Text>
                    <Text ta='right' fw='600'>
                      {currencyFormat.format(transaction.amount) + '  ' + transaction.currency}
                    </Text>
                    <Text>Estimated arrival</Text>
                    <Text ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Text>
                  </CSSGrid>
                </Box>
                <Box>
                  <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                    <Text>Reference</Text>
                    <Text ta='right' fw='600'>
                      {transaction.reference}
                    </Text>
                    <Text>Transaction number</Text>
                    <Text ta='right'>{transaction.id}</Text>
                  </CSSGrid>
                </Box>
              </CSSGrid>
            </>
          ) : (
            <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} columnGap='md' rowGap='sm'>
              <Box>
                <Text fw='700' fz='sm' mb='xs'>
                  Transaction details
                </Text>
                <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                  <Text>Transaction status</Text>
                  <Text ta='right'>Completed</Text>
                  <Text>3rd Party fees</Text>
                  <Text ta='right'>2.50 EUR</Text>
                  <Text>Leikur fee</Text>
                  <Text ta='right'>1.50 EUR</Text>
                </CSSGrid>
                <Box color='muted' my='xs' />
                <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                  <Text>Total spent</Text>
                  <Box fw='600' ta='right'>
                    {currencyFormat.format(Math.abs(transaction.amount)) + '  ' + transaction.currency}
                  </Box>
                  <Text>Authorization</Text>
                  <Text fw='600' ta='right'>
                    USERONE
                  </Text>
                </CSSGrid>
                <Box color='muted' my='xs' />
                <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                  <Text>Transaction type</Text>
                  <Text ta='right'>{`${transaction.counterparty.type.toUpperCase() || ''} Transfer`}</Text>
                  <Text>Date</Text>
                  <Text ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Text>
                  <Text>Time (local)</Text>
                  <Text ta='right'>{dayjs(transaction.created_at).format('HH:mm')}</Text>
                  <Text>Transaction number</Text>
                  <Text ta='right'>{transaction.id}</Text>
                </CSSGrid>
              </Box>
              <Box>
                <Text fw='700' fz='sm' mb='xs'>
                  Payment details
                </Text>
                <CSSGrid fz='sm' templateColumns='1fr auto' gap='3xs'>
                  <Text>Name</Text>
                  <Text ta='right'>{transaction.counterparty.account_holder_name}</Text>
                  <Text>Country</Text>
                  <Text ta='right'>N/A</Text>
                  <Text>IBAN</Text>
                  <Text ta='right'>{formatIBAN(transaction.counterparty.iban)}</Text>
                </CSSGrid>
              </Box>
            </CSSGrid>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

export default TransactionItem

import { mkConfig, generateCsv, download, ConfigOptions } from 'export-to-csv'
import { AcceptedData } from 'export-to-csv/output/lib/types'

export function generateCSV<
  T extends {
    [k: string]: AcceptedData
    [k: number]: AcceptedData
  }
>(data: T[]) {
  const csvConfig = mkConfig({ useKeysAsHeaders: true })
  return generateCsv(csvConfig)(data)
}

export function downloadCSV<
  T extends {
    [k: string]: AcceptedData
    [k: number]: AcceptedData
  }
>(data: T[], configOptions?: ConfigOptions) {
  const csvConfig = mkConfig({ useKeysAsHeaders: true, ...configOptions })
  return download(csvConfig)(generateCsv({ ...csvConfig })(data))
}

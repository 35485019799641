import { ActionIcon, Paper, Text } from '@mantine/core'
import { IconCloseCross } from '../../icons'
import { CSSGrid } from '../CSSGrid'
import { DescriptionBox } from './DescriptionBox'

const SimpleInstructions: React.FC<{
  dismissHelp: () => void
  title: string | React.ReactNode
  description: string
}> = ({ dismissHelp, title, description }) => {
  return (
    <Paper p='lg' mb={{ base: 'xl', desktop: 'xl' }}>
      <CSSGrid gap='1rem' c='secondary' alignItems='center' templateColumns='auto 1fr auto'>
        <Text fw='bold' fz='lg' lh='1.25'>
          {title}
        </Text>
        <DescriptionBox>{description}</DescriptionBox>
        <ActionIcon color='muted' size='sm' variant='transparent' display={{ base: 'none', desktop: 'block' }} onClick={dismissHelp}>
          <IconCloseCross />
        </ActionIcon>
      </CSSGrid>
    </Paper>
  )
}

export default SimpleInstructions

import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  getThemeColor,
  Group,
  Paper,
  Radio,
  Select,
  Stack,
  TextInput,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconArrowRight, IconPlusClear } from '@/components/icons'
import { helpBorder } from '../onboardingUtils'
import TaxRegulationsModal from './TaxRegulationsModal'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { colorAliases } from '@/theme/mantineTheme'
import { useParams } from 'react-router-dom'
import { CrsFatcaType } from '@/api/org/types'
import { FormEvent } from 'react'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'

const crsFatcaOptions: { value: CrsFatcaType; label: string }[] = [
  { value: 'ACTIVE_FINANCIAL_ENTITY', label: 'Active Financial Entity' },
  { value: 'PASSIVE_FINANCIAL_ENTITY', label: 'Passive Financial Entity' },
  { value: 'FINANCIAL_AND_FOREIGN_ENTITY', label: 'Financial and Foreign Entity' },
]

const TaxRegulations = () => {
  const [helpOpened, { open: openHelp, close: closeHelp }] = useDisclosure(false)
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (
      validateFields([
        'company.taxIdentification.tinCountryOfIncorporation',
        'company.taxIdentification.additionalCountries',
        'company.crsFatcaType',
      ])
    ) {
      saveForm()
    }
  }

  return (
    <Paper p={{ base: '3xs', desktop: 'lg' }}>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href={`/onboarding/overview/${invitationCode}`} c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 6 | Tax Regulations</Box>
      </Breadcrumbs>
      <form onSubmit={handleSubmit}>
        <Stack>
          <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
            <Box fz='sm' fw='bold' c='secondary' pr='1rem'>
              Tax residency
            </Box>
            <Box fz='xs' c={colorAliases.textInvertSecondary}>
              Tax regulations require us to collect information regarding the account holder&apos;s tax residency.{' '}
              <br />
              We may be required to share this information with the relevant tax authorities
            </Box>
          </Box>
          <Select
            labelProps={{ c: 'secondary' }}
            label='Company country of residence for tax purpose'
            {...getSelectPropsForCountry(null)}
          />
          <TextInput
            labelProps={{ c: 'secondary' }}
            label='Tax Identification Number (TIN)'
            description='If TIN is not available, please explain.'
            {...form.getInputProps('company.taxIdentification.tinCountryOfIncorporation')}
          />
          {form.values.company?.taxIdentification?.additionalCountries?.map((_taxCountry, index) => (
            <Stack
              gap='xl'
              key={index}
              px='lg'
              py='md'
              sx={(theme) => ({
                background: getThemeColor(colorAliases.surfaceLight, theme),
                border: `1px solid ${getThemeColor(colorAliases.surfaceLightgray, theme)}`,
              })}
            >
              <Select
                labelProps={{ c: 'secondary' }}
                label='Company country of residence for tax purpose'
                searchable
                {...getSelectPropsForCountry(
                  form.values.company?.taxIdentification?.additionalCountries?.[index]?.countryCode
                )}
                {...form.getInputProps(`company.taxIdentification.additionalCountries.${index}.countryCode`)}
              />
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Tax Identification Number (TIN)'
                description='If TIN is not available, please explain.'
                descriptionProps={{ c: 'dark', pt: '0.25rem' }}
                {...form.getInputProps(`company.taxIdentification.additionalCountries.${index}.tin`)}
              />
              <Button
                bg='negative'
                c='error'
                onClick={() => form.removeListItem('company.taxIdentification.additionalCountries', index)}
                sx={{ justifySelf: 'end' }}
              >
                Remove
              </Button>
            </Stack>
          ))}
          {form.values.company?.taxIdentification?.additionalCountries?.length === undefined ||
            (form.values.company.taxIdentification.additionalCountries.length < 2 && (
              <Button
                color='secondary'
                h='2.6rem'
                size='xs'
                w='100%'
                onClick={() =>
                  form.insertListItem('company.taxIdentification.additionalCountries', {
                    countryCode: null,
                    tin: '',
                  })
                }
                leftSection={<IconPlusClear style={{ color: 'white', height: '1rem' }} />}
              >
                Add another tax residency country
              </Button>
            ))}

          <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
            <Box fz='sm' fw='bold' c='secondary' pr='1rem'>
              CRS/FATCA entity type
            </Box>
            <Box fz='xs' c={colorAliases.textInvertSecondary} pr='1rem'>
              Click&nbsp;
              <Anchor component='button' type='button' c='primary' fw='bold' onClick={openHelp}>
                here
              </Anchor>
              &nbsp; to learn more about Active and Passive Non Financial Entity/Non Financial Foreign Entity.
              <TaxRegulationsModal opened={helpOpened} onClose={closeHelp} />
            </Box>
          </Box>
          <Radio.Group
            labelProps={{ c: 'secondary' }}
            label='Is the company an Active or Passive Non Financial Entity/Non Financial Foreign Entity for CRS/FATCA purposes?'
            {...form.getInputProps('company.crsFatcaType')}
          >
            <Stack gap='sm' mt='sm' mb={form.getInputProps('company.crsFatcaType').error ? 'sm' : 0}>
              {crsFatcaOptions.map((option) => (
                <Radio key={option.value} value={option.value} label={option.label} />
              ))}
            </Stack>
          </Radio.Group>
          <Group>
            <Button h='2.4rem' size='xs' mt='md' color='dark' type='button'>
              Previous
            </Button>
            <div style={{ flexGrow: 1 }} />
            <Button size='xs' h='2.4rem' mt='md' color='primary' type='submit'>
              Save
            </Button>
            <Button size='xs' h='2.4rem' mt='md' color='primary' type='button'>
              Save and continue
            </Button>
          </Group>
        </Stack>
      </form>
    </Paper>
  )
}

export default TaxRegulations

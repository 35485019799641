import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Container, Paper, Title } from '@mantine/core'

const AwaitingEmailVerification = () => {
  const { state } = useLocation()

  const navigate = useNavigate()

  return (
    <Container py={{ base: 'xl', desktop: '3xl' }} sx={{ isolation: 'isolate' }} my='2xl'>
      <Paper p={{ base: 'lg', desktop: '2xl' }}>
        <Title order={1} color='primary' weight={600} align='center' mt='2rem' mb='1rem' size={'h1'} p='0.5rem'>
          Great, please verify your email address to continue{' '}
        </Title>
        <div>
          An email verification link has been sent to the email address you provided:
          <br />
          <br />
          <div>{state.email}</div>
          <br />
          Please click on the link in the email to verify your email address and continue with your application setup.
          <br />
          <br />
          If the confirmation email does not arrive within the next few minutes, please check your junk, bulk or spam email folder.
          <br />
          <br />
          <Button
            px='2rem'
            mb='2rem'
            color='orange'
            onClick={() => {
              navigate(`/sign-up/verify/${state.token}/${encodeURI(state.email)}`)
            }}
          >
            SKIP
          </Button>
        </div>
      </Paper>
    </Container>
  )
}

export default AwaitingEmailVerification

import { Select, SimpleGrid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { getSelectPropsForCountry } from '../../../../components/inputs/helper'
import { ClientRole, Person } from '../../../../types/onboarding'

interface PersonDetailsProps {
  form: ReturnType<typeof useForm<Person>>
}

const PersonDetails: React.FC<PersonDetailsProps> = ({ form }) => (
  <>
    <Text size='lg' fw={500} c='secondary' mb='md'>
      Person Details
    </Text>
    <SimpleGrid cols={{ base: 1, md: 2 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }}>
      <TextInput labelProps={{ c: 'secondary' }} label='First name' placeholder='Enter first name' {...form?.getInputProps('firstName')} />
      <TextInput labelProps={{ c: 'secondary' }} label='Last name' placeholder='Enter last name' {...form?.getInputProps('lastName')} />
      {form.values.roles.includes(ClientRole.MAR) && (
        <TextInput labelProps={{ c: 'secondary' }} label='Email' placeholder='Enter Email' {...form?.getInputProps('email')} />
      )}
      {!form.values.roles.includes(ClientRole.MAR) && (
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Date of birth'
          placeholder='Select date of birth'
          type='date'
          {...form.getInputProps('dateOfBirth')}
        />
      )}
      {!form.values.roles.includes(ClientRole.MAR) && (
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Place of birth (optional)'
          placeholder='Enter place of birth'
          {...form.getInputProps('placeOfBirth')} // Not sure where this must be set to? wait for api release
        />
      )}
      {!form.values.roles.includes(ClientRole.MAR) && (
        <Select
          label='Country of citizenship'
          labelProps={{ c: 'secondary' }}
          placeholder='Select country of citizenship'
          searchable
          clearable
          {...getSelectPropsForCountry(form.values.citizenshipCountryCode || null)}
          {...form.getInputProps('citizenshipCountryCode')}
        />
      )}
      {form.values.roles.includes(ClientRole.DIRECTOR) && form.values.roles.length === 1 && (
        <Select
          label='Country of residence'
          labelProps={{ c: 'secondary' }}
          placeholder='Select country of residence'
          searchable
          clearable
          {...getSelectPropsForCountry(form.values.address?.countryCode || null)}
          {...form.getInputProps('address.countryCode')}
        />
      )}
    </SimpleGrid>
  </>
)

export default PersonDetails

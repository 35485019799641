import { CloseButton, Combobox, ComboboxProps, InputBase, InputBaseProps, ScrollArea, UseComboboxOptions, useCombobox } from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import { useEffect, useMemo } from 'react'

export type SearchableSelectItem = {
  value: string
  label: string
  labelNode?: React.ReactNode
  leftSectionNode?: React.ReactNode
  searchString: string
  [key: string]: unknown
}

export interface SearchableSelectProps extends InputBaseProps {
  items: SearchableSelectItem[]
  value?: string
  defaultValue?: string
  onChange?: (v: string) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  error?: string | boolean
  comboboxOptions?: UseComboboxOptions
  comboboxProps?: Omit<ComboboxProps, 'onOptionSubmit' | 'store'>
  placeholder?: string
}

function ifNotEmpty<T>(value: T[], fallback: T[]): T[] {
  return value.length > 0 ? value : fallback
}

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  items,
  value,
  defaultValue,
  onChange,
  onBlur,
  onFocus,
  error,
  placeholder,
  comboboxOptions,
  comboboxProps,
  ...inputBaseProps
}) => {
  const combobox = useCombobox({
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active', { scrollIntoView: true }),
    onDropdownClose: () => combobox.resetSelectedOption(),
    ...comboboxOptions,
  })

  const [_value, setValue] = useUncontrolled({
    value,
    defaultValue,
    finalValue: '',
    onChange,
  })

  const selectedOption = items.find((item) => item.value === _value)

  const [search, setSearch] = useUncontrolled({
    finalValue: selectedOption?.label ?? '',
  })

  const filteredOptions = ifNotEmpty(
    items.filter((item) => item.searchString.toLowerCase().includes(search.toLowerCase().trim())),
    items
  )

  useEffect(() => {
    if (selectedOption) {
      setSearch(selectedOption.label)
    }
  }, [selectedOption, setSearch])

  const options = useMemo(
    () =>
      filteredOptions.map((item) => (
        <Combobox.Option value={item.value} key={item.value} c={item.value === _value ? 'primary' : undefined}>
          {item.labelNode ?? item.label}
        </Combobox.Option>
      )),
    [filteredOptions, _value]
  )

  return (
    <Combobox
      withinPortal={false}
      {...comboboxProps}
      onOptionSubmit={(val) => {
        setValue(val)
        if (!val || val === '') {
          setSearch('')
        }
        combobox.closeDropdown()
      }}
      store={combobox}
    >
      <Combobox.Target>
        <InputBase
          {...inputBaseProps}
          value={search}
          error={error}
          onChange={(event) => {
            setSearch(event.target.value)
            combobox.openDropdown()
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={(event) => {
            combobox.openDropdown()
            onFocus?.(event)
          }}
          onBlur={(event) => {
            combobox.closeDropdown()
            setSearch(selectedOption ? selectedOption.label : '')
            onBlur?.(event)
          }}
          leftSection={selectedOption?.leftSectionNode}
          rightSection={
            _value !== '' ? (
              <CloseButton
                size='sm'
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => {
                  setValue('')
                  setSearch('')
                }}
                aria-label='Clear value'
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={_value === '' ? 'none' : 'all'}
          placeholder={placeholder}
        >
          {/* {search || <Input.Placeholder>{placeholder ?? ''}</Input.Placeholder>} */}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            <ScrollArea.Autosize type='scroll' mah={200}>
              {options}
            </ScrollArea.Autosize>
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

import { Box, Button, Divider, Flex, Loader, Text } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import useAddBeneficiary from '../../../../api/queries/useAddBeneficiary'
import useEditBeneficiary from '../../../../api/queries/useEditBeneficiary'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconArrowLeft, IconSaveExport } from '../../../../components/icons'
import { LogoLeikur, LogoSepa } from '../../../../components/logos'
import formatIBAN from '../../../../utils/formatIBAN'
import { Beneficiary as BeneficiaryInterface, PaymentType } from '../../../../utils/interfaces'
import currencies from '../../../../utils/currencies'
import { Flag } from '../../../../components/Flag'
import { getCountry } from '../../../../components/inputs/countries'

type Props = {
  back: () => void
  beneficiaryForm: UseFormReturnType<BeneficiaryInterface>
  editing?: boolean
}

const FinaliseBeneficiary: React.FC<Props> = ({ back, beneficiaryForm, editing = false }) => {
  const navigate = useNavigate()

  const { mutate: addBeneficiary, isLoading: isAddLoading, isSuccess: isAddSuccess } = useAddBeneficiary(() => navigate('/beneficiary-centre'))
  const { mutate: editBeneficiary, isLoading: isEditLoading, isSuccess: isEditSuccess } = useEditBeneficiary(() => navigate('/beneficiary-centre'))

  // const [target, setTarget] = useState<'exit' | 'pay'>('exit')

  const saveBeneficiary = () => {
    // setTarget(target)
    beneficiaryForm.validate()
    if (beneficiaryForm.isValid()) {
      if (editing) {
        editBeneficiary(beneficiaryForm.values)
      } else {
        addBeneficiary({
          ...beneficiaryForm.values,
          default_payment: { amount: 500, ...beneficiaryForm.values.default_payment },
          account_details:
            beneficiaryForm.values.account_details.type === PaymentType.LEIKUR
              ? { ...beneficiaryForm.values.account_details, email: beneficiaryForm.values.email }
              : beneficiaryForm.values.account_details,
          country: beneficiaryForm.values.country ?? 'LT',
        })
      }
    }
  }

  if (isAddLoading || isAddSuccess || isEditLoading || isEditSuccess) {
    return (
      <Box px='xl' display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Box>
    )
  }
  return (
    <Box px='xl' c='textMuted'>
      <Text size='lg' fw={700} c='secondary' mb='lg'>
        Please review the details of this beneficiary.
      </Text>
      <CSSGrid templateColumns={{ desktop: '1fr 1fr' }} gap='xl' alignContent='center'>
        <Flex fz='3rem' align='center' justify='center' hiddenFrom='desktop'>
          {beneficiaryForm.values.payment_type == PaymentType.SEPA ? <LogoSepa style={{ height: '1em' }} /> : <LogoLeikur style={{ height: '1em' }} />}
        </Flex>
        <CSSGrid gap='2xs'>
          <Text mb='xs' fw='bold'>
            These details cannot be changed:
          </Text>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Name:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.name}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Alias:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.alias}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Email:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.email}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Beneficiary type:</Text>
            <Text fw={500} c='secondary' tt='capitalize'>
              {beneficiaryForm.values.entity_type}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Currency:</Text>
            <Flex gap='3xs' fw={500} c='textMuted' justify='end' lh='1.3' align='center'>
              <Flag
                countryCode={currencies[beneficiaryForm.values.currency]?.countryCodeForFlag}
                square={true}
                style={{
                  borderRadius: '50%',
                  width: '1rem',
                  height: '1rem',
                }}
              />
              {beneficiaryForm.values.currency}
            </Flex>
          </Flex>
          {beneficiaryForm.values.payment_type == PaymentType.SEPA && (
            <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
              <Text>Country:</Text>
              <Flex fw={400} c='secondary'>
                <Flag countryCode={beneficiaryForm.values.country} square={false} />
                &nbsp;&nbsp;{getCountry(beneficiaryForm.values.country).name}
              </Flex>
            </Flex>
          )}
          {beneficiaryForm.values.account_details.type == PaymentType.SEPA && (
            <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
              <Text>IBAN:</Text>
              <Text fw={500} c='secondary'>
                {formatIBAN(beneficiaryForm.values.account_details.iban)}
              </Text>
            </Flex>
          )}
        </CSSGrid>
        <Flex fz='5rem' align='center' justify='center' visibleFrom='desktop'>
          {beneficiaryForm.values.payment_type == PaymentType.SEPA ? <LogoSepa style={{ height: '1em' }} /> : <LogoLeikur style={{ height: '1em' }} />}
        </Flex>
      </CSSGrid>
      <Divider my='xl' />
      <Text mb='xs' fw={500} my='xl'>
        These details can be changed or set <i>per transaction</i>:
      </Text>
      <CSSGrid templateColumns={{ desktop: '1fr 1fr' }} gap='xl' alignContent='center'>
        <CSSGrid gap='2xs'>
          <Flex justify='space-between' direction={{ base: 'column' }}>
            <Text>Reference:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.reference || '-'}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Default payment amount:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.default_payment?.amount || '100'} {beneficiaryForm.values.currency}
            </Text>
          </Flex>
          <Text fw={500} c='secondary' mt='lg'>
            Proof of Payment
          </Text>
          <Divider w='20rem' />
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Email address:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.default_payment?.popEmail || '-'}
            </Text>
          </Flex>
          <Flex justify='space-between' direction={{ base: 'column', desktop: 'row' }}>
            <Text>Mobile number:</Text>
            <Text fw={500} c='secondary'>
              {beneficiaryForm.values.default_payment?.popMobileNumber?.formattedPhoneNumber || '-'}
            </Text>
          </Flex>
        </CSSGrid>
        {(beneficiaryForm.values.default_payment?.documents ?? []).length > 0 && (
          <Box>
            <Text color='textMuted'>Supporting documents:</Text>
            {beneficiaryForm.values.default_payment?.documents?.map((document, index) => (
              <Text key={index} color='secondary'>
                {document.document_name}
              </Text>
            ))}
          </Box>
        )}
      </CSSGrid>

      <Flex justify='right' align='center' gap='sm' wrap='wrap' my='xl'>
        <Button radius='xl' variant='filled' leftSection={<IconArrowLeft style={{ height: '1.3em', width: '1.3em' }} />} color='textMuted' onClick={back}>
          Back
        </Button>
        <Button radius='xl' variant='filled' leftSection={<IconSaveExport style={{ color: 'white' }} />} color='secondary' onClick={saveBeneficiary}>
          Save beneficiary
        </Button>
        {/* <Button
          radius={'xl'}
          variant='filled'
          leftSection={<IconFundsSend style={{ height: '10px', width: '10px' }} />}
          leftIconColor='white'
          color='success'
          onClick={() => {
            saveBeneficiary('pay')
          }}
        >
          Save & Pay this beneficiary
        </Button> */}
      </Flex>
      <Box>
        <Text size='xs' c='textMuted'>
          NOTE - It is your responsibility to ensure that the details you provide when creating a beneficiary are accurate.
          <br />
          Incorrect details could result in an unsuccessful payment or funds paid to an incorrect account
        </Text>
      </Box>
    </Box>
  )
}

export default FinaliseBeneficiary

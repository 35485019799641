import { CompanyType } from '@/api/org/types'
import { IconArrowRight, IconCloseCross, IconViewInfo } from '@/components/icons'
import { getMultiSelectPropsForCountry, getSelectPropsForCountry } from '@/components/inputs/helper'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import {
  Breadcrumbs,
  Anchor,
  Box,
  Select,
  Radio,
  Group,
  MultiSelect,
  TextInput,
  Tooltip,
  UnstyledButton,
  Stack,
  Flex,
  Text,
  getThemeColor,
  MantineTheme,
  Button,
  Paper,
} from '@mantine/core'
import React, { FormEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

function convertBooleanToYesNoUndefined(value: boolean | undefined) {
  if (value === true) return 'Yes'
  if (value === false) return 'No'
  return undefined
}

const companyOptions: { value: CompanyType; label: string }[] = [
  { value: 'ASSOCIATION', label: 'Association' },
  { value: 'CHARITABLE_ORGANIZATION', label: 'Charitable Organization' },
  { value: 'FOUNDATION', label: 'Foundation' },
  { value: 'GOVERNMENT_OWNED_COMPANY', label: 'Government Owned Company' },
  { value: 'HOLDING_COMPANY', label: 'Holding Company' },
  { value: 'NotProvided', label: 'Not Provided' },
  { value: 'NON_PROFIT_ORGANIZATION', label: 'Non-Profit Organization' },
  { value: 'ONE_PERSON_COMPANY_OR_SOLE_PROPRIETORSHIP', label: 'One Person Company or Sole Proprietorship' },
  { value: 'OTHER', label: 'Other' },
  { value: 'PARTNERSHIP', label: 'Partnership' },
  { value: 'PRIVATELY_OWNED_COMPANY', label: 'Privately Owned Company' },
  { value: 'PUBLICLY_TRADED_COMPANY', label: 'Publicly Traded Company' },
  { value: 'TRADING_COMPANY', label: 'Trading Company' },
  { value: 'TRUST', label: 'Trust' },
]

const AdvancedDetails = () => {
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()
  const [isGovGroup, setIsGovGroup] = useState<boolean>()
  const [isStockGroup, setIsStockGroup] = useState<boolean>()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (
      validateFields([
        'company.companyType',
        'company.companyTypeOther',
        'company.isSubsidiary',
        'company.group.stockExchange.listedSubsidiaries.legalName',
        'company.group.stockExchange.listedSubsidiaries.isinCode',
        'company.group.governmentalLicense.licensedSubsidiaries.legalName',
        'company.group.governmentalLicense.licensedSubsidiaries.licensePublicSource',
        'company.shareholders.isOwnedByNominee',
        'company.shareholders.fullName',
        'company.shareholders.regulatingCountryCode',
        'company.governmentalLicense.isLicensed',
        'company.governmentalLicense.licensePublicSource',
        'company.stockExchange.isListed',
        'company.stockExchange.isinCode',
        'account.receiveFunds.countries',
        'account.sendFunds.countries',
      ])
    ) {
      saveForm()
    }
  }

  return (
    <Paper p={{ base: '3xs', desktop: 'lg' }}>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='dark' mb='xl' fw={500} lh='1.3'>
        <Anchor href={`/onboarding/overview/${invitationCode}`} c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step {3} | Advanced Details</Box>
      </Breadcrumbs>
      <form onSubmit={handleSubmit}>
        <Select
          label='What is your company type?'
          labelProps={{ c: 'secondary' }}
          placeholder='Select company type'
          data={companyOptions}
          {...form.getInputProps('company.companyType')}
        />
        <Radio.Group
          labelProps={{ c: 'secondary' }}
          label='Is the company part of a larger group structure of companies forming the overall business?'
          value={convertBooleanToYesNoUndefined(form.values.company.isSubsidiary)}
          onChange={(value) => {
            const isPartOfGroup = value === 'Yes'
            form.setFieldValue('company.isSubsidiary', isPartOfGroup)

            if (!isPartOfGroup) {
              form.setFieldValue('company.group.stockExchange.listedSubsidiaries', [])
              form.setFieldValue('company.group.governmentalLicense.licensedSubsidiaries', [])
              setIsStockGroup(undefined)
              setIsGovGroup(undefined)
            }
          }}
          errorProps={{ mt: 'xs', c: 'red' }}
        >
          <Group mt='xs'>
            <Radio value='Yes' label='Yes' />
            <Radio value='No' label='No' />
          </Group>
        </Radio.Group>
        {form.values.company.isSubsidiary && (
          <>
            <Radio.Group
              label='Are any of the other companies in the group structure forming the overall business listed on any stock exchange?'
              value={convertBooleanToYesNoUndefined(isStockGroup)}
              onChange={(value) => {
                const isStockGroup = value === 'Yes'
                setIsStockGroup(isStockGroup)
                form.setFieldValue(
                  'company.group.stockExchange.listedSubsidiaries',
                  isStockGroup ? [{ legalName: '', isinCode: '' }] : []
                )
              }}
              pl='sm'
              labelProps={{
                c: 'dark',
                fw: 500,
                pl: 'lg',
                sx: (theme: MantineTheme) => ({
                  borderLeft: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}`,
                }),
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {isStockGroup && (
              <>
                {form.values.company?.group?.stockExchange?.listedSubsidiaries?.map((_subsidiary, index) => (
                  <React.Fragment key={index}>
                    <Flex gap='lg' align='start' pl='sm'>
                      <TextInput
                        w='50%'
                        labelProps={{ c: 'secondary' }}
                        label='Company Name'
                        placeholder='Company Name'
                        {...form.getInputProps(`company.group.stockExchange.listedSubsidiaries.${index}.legalName`)}
                      />
                      <TextInput
                        w='50%'
                        labelProps={{ c: 'secondary' }}
                        label='ISIN code'
                        placeholder='ISIN code'
                        rightSection={
                          <Tooltip color='secondary' label='International Securities Identification Number (ISIN)'>
                            <UnstyledButton variant='outline'>
                              <IconViewInfo style={{ height: '1rem' }} />
                            </UnstyledButton>
                          </Tooltip>
                        }
                        {...form.getInputProps(`company.group.stockExchange.listedSubsidiaries.${index}.isinCode`)}
                      />
                    </Flex>
                    {index > 0 && (
                      <Button
                        ml='sm'
                        leftSection={<IconCloseCross style={{ color: 'black', height: '1rem' }} />}
                        onClick={() => form.removeListItem('company.group.stockExchange.listedSubsidiaries', index)}
                        variant='subtle'
                        size='sm'
                      >
                        Remove Company
                      </Button>
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant='filled'
                  color='secondary'
                  h='2.6rem'
                  size='xs'
                  onClick={() =>
                    form.insertListItem('company.group.stockExchange.listedSubsidiaries', {
                      legalName: '',
                      isinCode: '',
                    })
                  }
                  ml='sm'
                >
                  + Add another company
                </Button>
              </>
            )}
            <Radio.Group
              label='Are any of the other companies in the group structure forming the overall business licensed by any government or government supported institution?'
              pl='sm'
              value={convertBooleanToYesNoUndefined(isGovGroup)}
              onChange={(value) => {
                const isGovGroup = value === 'Yes'
                setIsGovGroup(isGovGroup)
                form.setFieldValue(
                  'company.group.governmentalLicense.licensedSubsidiaries',
                  isGovGroup ? [{ legalName: '', licensePublicSource: '' }] : []
                )
              }}
              labelProps={{
                c: 'dark',
                fw: 500,
                pl: 'lg',
                sx: (theme: MantineTheme) => ({
                  borderLeft: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}`,
                }),
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {isGovGroup && (
              <>
                {form.values.company?.group?.governmentalLicense?.licensedSubsidiaries?.map((_institution, index) => (
                  <React.Fragment key={index}>
                    <Flex gap='lg' align='start' pl='sm'>
                      <TextInput
                        w='50%'
                        labelProps={{ c: 'secondary' }}
                        label='Company Name'
                        placeholder='Company Name'
                        {...form.getInputProps(
                          `company.group.governmentalLicense.licensedSubsidiaries.${index}.legalName`
                        )}
                      />
                      <TextInput
                        w='50%'
                        labelProps={{ c: 'secondary' }}
                        label='Public source for validation'
                        placeholder='Public source where the license can be validated'
                        rightSection={
                          <Tooltip color='secondary' label='If license is not available, please explain.'>
                            <UnstyledButton variant='outline'>
                              <IconViewInfo style={{ height: '1rem' }} />
                            </UnstyledButton>
                          </Tooltip>
                        }
                        {...form.getInputProps(
                          `company.group.governmentalLicense.licensedSubsidiaries.${index}.licensePublicSource`
                        )}
                      />
                    </Flex>
                    {index > 0 && (
                      <Button
                        leftSection={<IconCloseCross style={{ color: 'black', height: '0.6rem' }} />}
                        onClick={() =>
                          form.removeListItem('company.group.governmentalLicense.licensedSubsidiaries', index)
                        }
                        variant='subtle'
                        h='2.6rem'
                        size='xs'
                        ml='sm'
                      >
                        Remove company
                      </Button>
                    )}
                  </React.Fragment>
                ))}
                <Button
                  variant='filled'
                  color='secondary'
                  h='2.6rem'
                  size='xs'
                  onClick={() =>
                    form.insertListItem('company.group.governmentalLicense.licensedSubsidiaries', {
                      legalName: '',
                      licensePublicSource: '',
                    })
                  }
                  ml='sm'
                >
                  + Add another company
                </Button>
              </>
            )}
          </>
        )}

        <Radio.Group
          label='Is the company licensed by any government or government supported institution?'
          labelProps={{ c: 'secondary' }}
          {...form.getInputProps('company.governmentalLicense.isLicensed')}
          value={convertBooleanToYesNoUndefined(form.values.company?.governmentalLicense?.isLicensed)}
          onChange={(value) => {
            const isLicensed = value === 'Yes'
            form.setFieldValue('company.governmentalLicense.isLicensed', isLicensed)
            if (!isLicensed) {
              form.setFieldValue('company.governmentalLicense.licensePublicSource', '')
            }
          }}
          errorProps={{ mt: 'xs', c: 'red' }}
        >
          <Group mt='xs'>
            <Radio value='Yes' label='Yes' />
            <Radio value='No' label='No' />
          </Group>
        </Radio.Group>
        {form.values.company?.governmentalLicense?.isLicensed && (
          <TextInput
            label='Public source where the license can be validated'
            labelProps={{ c: 'secondary' }}
            rightSection={
              <Tooltip color='secondary' label='If license is not available, please explain.'>
                <UnstyledButton variant='outline'>
                  <IconViewInfo style={{ height: '1rem' }} />
                </UnstyledButton>
              </Tooltip>
            }
            {...form.getInputProps('company.governmentalLicense.licensePublicSource')}
          />
        )}

        <Radio.Group
          label='Is the company owned directly or indirectly by a nominee shareholder?'
          labelProps={{ c: 'secondary' }}
          {...form.getInputProps('company.shareholders.isOwnedByNominee')}
          value={convertBooleanToYesNoUndefined(form.values.company?.shareholders?.isOwnedByNominee)}
          onChange={(value) => {
            const isOwnedByShareholder = value === 'Yes'
            form.setFieldValue('company.shareholders.isOwnedByNominee', isOwnedByShareholder)
            if (!isOwnedByShareholder) {
              form.setFieldValue('company.shareholders.nominee.fullName', '')
              form.setFieldValue('company.shareholders.nominee.regulatingCountryCode', 'ZZ')
            }
          }}
          errorProps={{ mt: 'xs', c: 'red' }}
        >
          <Group mt='xs'>
            <Radio value='Yes' label='Yes' />
            <Radio value='No' label='No' />
          </Group>
        </Radio.Group>
        {form.values?.company?.shareholders?.isOwnedByNominee && (
          <Flex direction='column' gap='sm'>
            <Text fz='sm' fw='600' w='100%'>
              Shareholder details
            </Text>

            <Stack gap='md'>
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Full name of the nominee shareholder'
                {...form.getInputProps('company.shareholders.nominee.fullName')}
              />

              <Select
                label='Country where the nominee shareholder is regulated'
                labelProps={{ c: 'secondary' }}
                defaultValue=''
                searchable
                clearable
                {...getSelectPropsForCountry(
                  form.values?.company?.shareholders?.nominee?.regulatingCountryCode || null
                )}
                {...form.getInputProps('company.shareholders.nominee.regulatingCountryCode')}
              />
            </Stack>
          </Flex>
        )}

        <Radio.Group
          label='Is the company listed on any stock exchange?'
          labelProps={{ c: 'secondary' }}
          {...form.getInputProps('company.stockExchange.isListed')}
          value={convertBooleanToYesNoUndefined(form.values.company?.stockExchange?.isListed)}
          onChange={(value) => {
            const isListed = value === 'Yes'
            form.setFieldValue('company.stockExchange.isListed', isListed)
            if (!isListed) {
              form.setFieldValue('company.stockExchange.isinCode', '')
            }
          }}
          errorProps={{ mt: 'xs', c: 'red' }}
        >
          <Group mt='xs'>
            <Radio value='Yes' label='Yes' />
            <Radio value='No' label='No' />
          </Group>
        </Radio.Group>
        {form.values.company?.stockExchange?.isListed && (
          <TextInput
            label='ISIN code'
            labelProps={{ c: 'secondary' }}
            rightSection={
              <Tooltip color='secondary' label='International Securities Identification Number (ISIN)'>
                <UnstyledButton variant='outline'>
                  <IconViewInfo style={{ height: '1rem' }} />
                </UnstyledButton>
              </Tooltip>
            }
            {...form.getInputProps('company.stockExchange.isinCode')}
          />
        )}

        <MultiSelect
          label='List the countries you will receive funds from'
          multiple
          labelProps={{ c: 'secondary' }}
          searchable
          placeholder='Select countries'
          {...form.getInputProps('account.receiveFunds.countries')}
          {...getMultiSelectPropsForCountry()}
        />
        <MultiSelect
          label='List the countries you will send funds to'
          multiple
          labelProps={{ c: 'secondary' }}
          searchable
          placeholder='Select countries'
          {...form.getInputProps('account.sendFunds.countries')}
          {...getMultiSelectPropsForCountry()}
        />
        <Group justify='space-between'>
          <Button h='2.4rem' size='xs' mt='md' color='dark'>
            Previous
          </Button>
          <Button type='submit' mt='lg'>
            Save
          </Button>
          <Button type='button' mt='lg'>
            Save and continue
          </Button>
        </Group>
      </form>
    </Paper>
  )
}

export default AdvancedDetails

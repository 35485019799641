import { Box, Button, Flex, Loader, NumberInput, Select, Text, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import { useEffect, useState } from 'react'
import useFetchAccountGroupsByIBAN from '../../api/queries/useFetchAccountGroupsByIBAN'
import useInternalTransfer from '../../api/queries/useInternalTransfer'
import { colorAliases } from '../../theme/mantineTheme'
import currencies, { currencyFormat } from '../../utils/currencies'
import { ExchangeInfo, getExchangeInfo } from '../../utils/exchange_rates'
import { Currency, InternalTransferRequest } from '../../utils/interfaces'
import { Flag } from '../Flag'
import { CSSGrid } from '../common/CSSGrid'
import { IconCloseCross, IconDownArrow, IconTickMarkYes } from '../icons'
import ModalWrapper from './ModalWrapper'
import { getPropsForCurrencySearchableSelect } from '../common/currencyDropdownHelper'
import { SearchableSelect } from '../inputs/SearchableSelect'

const InternalAccountTransfer = ({ context, id, innerProps }: ContextModalProps<{ selectedIban?: string }>) => {
  const { mutate: makeInternalTransfer, isLoading: isTransferLoading, isSuccess } = useInternalTransfer()

  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()

  const [exchangeInfo, setExchangeInfo] = useState<ExchangeInfo>()

  const form = useForm<{
    amount: number | ''
    fromAccountGroup: string | null
    toAccountGroup: string | null
    fromCurrency: Currency | null
    toCurrency: Currency | null
    reference: string
  }>({
    initialValues: {
      fromAccountGroup: innerProps.selectedIban || null,
      toAccountGroup: null,
      fromCurrency: innerProps.selectedIban ? Currency.EUR : null,
      toCurrency: null,
      amount: '',
      reference: '',
    },
    validate: {
      fromAccountGroup: isNotEmpty('Please select an account'),
      toAccountGroup: isNotEmpty('Please select an account'),
      fromCurrency: isNotEmpty('Please select a currency'),
      toCurrency: (value, values) =>
        isNotEmpty('Please select an currency')(value) ??
        (value === values.fromCurrency && values.toAccountGroup === values.fromAccountGroup ? 'Please choose a different currency' : null),

      amount: (value, values) =>
        value === '' || value <= 0
          ? 'Please enter a valid amount.'
          : (accountGroupsByIBAN?.[values.fromAccountGroup ?? -1]?.accounts.find((acc) => acc.currency === values.fromCurrency)?.balance || Infinity) < value
          ? 'The amount is greater than the available balance.'
          : null,
      reference: isNotEmpty('Please enter a reference'),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (
      form.values.amount !== '' &&
      form.values.fromCurrency !== null &&
      form.values.toCurrency !== null &&
      form.values.fromCurrency !== form.values.toCurrency
    ) {
      if (
        form.values.amount !== exchangeInfo?.fromAmount ||
        form.values.fromCurrency !== exchangeInfo?.fromCurrency ||
        form.values.toCurrency !== exchangeInfo?.toCurrency
      ) {
        setExchangeInfo(
          getExchangeInfo({
            amount: form.values.amount,
            fromCurrency: form.values.fromCurrency,
            toCurrency: form.values.toCurrency,
          })
        )
      }
    }
  }, [form, exchangeInfo])

  const sendInternalTransferRequest = () => {
    form.validate()
    if (form.isValid() && form.values.fromAccountGroup && form.values.toAccountGroup && accountGroupsByIBAN) {
      makeInternalTransfer({
        fromAccount: accountGroupsByIBAN[form.values.fromAccountGroup].accounts.find((acc) => acc.currency === form.values.fromCurrency)?.id,
        toAccount: accountGroupsByIBAN[form.values.toAccountGroup].accounts.find((acc) => acc.currency === form.values.toCurrency)?.id,
        amount: form.values.amount,
        reference: form.values.reference,
      } as InternalTransferRequest)
    }
  }

  if (isLoading || accountGroupsByIBAN === undefined || isTransferLoading) {
    return (
      <CSSGrid py='xs' gap='md' alignContent='center' justifyItems='center' mih={300}>
        <Loader />
      </CSSGrid>
    )
  }

  if (isSuccess && form.values.amount !== '' && form.values.fromAccountGroup && form.values.toAccountGroup) {
    return (
      <ModalWrapper onClose={() => context.closeModal(id)}>
        <CSSGrid gap='md' alignContent='center' justifyItems='center' mih={300} fz={{ base: 'md', desktop: 'xl' }} pb='md'>
          <Text fz={{ base: 'xl', desktop: '2xl' }} fw='bold' color='success'>
            Transaction successful!
          </Text>
          <Box c='success' my='lg' fz={{ base: 14, desktop: 16 }}>
            <IconTickMarkYes style={{ height: '4.5em' }} />
          </Box>
          <Box c='textPrimary' ta='center'>
            <Box fw='bold'>
              {currencyFormat.format(exchangeInfo ? exchangeInfo.toAmount : form.values.amount)} {form.values.toCurrency}
            </Box>{' '}
            was transfered
          </Box>
          <Flex direction={{ base: 'column', desktop: 'row' }} justify='space-between' c='textPrimary' align='center' fw='bold' gap='md' mt='md'>
            <Flex gap='xs' align='center'>
              <Flag
                countryCode={currencies[form.values.fromCurrency as Currency]?.countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '10px',
                  width: '2.25em',
                  height: '2.25em',
                }}
              />
              <Box>
                <Box>{accountGroupsByIBAN[form.values.fromAccountGroup].alias}</Box>
                <Box>
                  {currencyFormat.format(exchangeInfo ? exchangeInfo.fromAmount : form.values.amount)} {form.values.fromCurrency}
                </Box>
              </Box>
            </Flex>
            <Flex c='success' hiddenFrom='desktop'>
              <IconDownArrow style={{ height: '0.7em' }} />
            </Flex>
            <Flex c='success' visibleFrom='desktop'>
              <IconDownArrow style={{ height: '0.7em', transform: 'rotate(270deg)' }} />
            </Flex>
            <Flex gap='xs' align='center'>
              <Flag
                countryCode={currencies[form.values.toCurrency as Currency]?.countryCodeForFlag}
                square={true}
                sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                style={{
                  borderRadius: '10px',
                  width: '2.25em',
                  height: '2.25em',
                }}
              />
              <Box>
                <Box>{accountGroupsByIBAN[form.values.toAccountGroup].alias}</Box>
                <Box>
                  {currencyFormat.format(exchangeInfo ? exchangeInfo.toAmount : form.values.amount)} {form.values.toCurrency}
                </Box>
              </Box>
            </Flex>
          </Flex>

          {form.values.fromCurrency !== form.values.toCurrency && exchangeInfo && (
            <Box c='textMuted' fz='sm' lh={1.3}>
              <Box>
                Exchange rate: <b>{exchangeInfo.exchangeRate}</b>
              </Box>
            </Box>
          )}
          <Button
            mt='md'
            radius='xl'
            leftSection={<IconCloseCross style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='success'
            onClick={() => context.closeModal(id)}
          >
            Close
          </Button>
        </CSSGrid>
      </ModalWrapper>
    )
  }

  const supportedFromCurrencies =
    form.values.fromAccountGroup && Object.hasOwn(accountGroupsByIBAN, form.values.fromAccountGroup)
      ? accountGroupsByIBAN[form.values.fromAccountGroup].accounts.map((acc) => acc.currency)
      : []

  const supportedToCurrencies =
    form.values.toAccountGroup && Object.hasOwn(accountGroupsByIBAN, form.values.toAccountGroup)
      ? accountGroupsByIBAN[form.values.toAccountGroup].accounts
          .map((acc) => acc.currency)
          .filter((c) => (form.values.fromAccountGroup === form.values.toAccountGroup ? c !== form.values.fromCurrency : true))
      : []

  return (
    <ModalWrapper title='Transfer funds internally' onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} pb='md'>
        <Select
          labelProps={{ c: 'secondary', bg: 'white' }}
          searchable
          data={[
            ...Object.values(accountGroupsByIBAN).map((acc) => {
              return { label: acc.alias, value: acc.iban }
            }),
          ]}
          label='Transfer from'
          description='Select the account from which the amount will be deducted'
          {...form.getInputProps('fromAccountGroup')}
          onChange={(v) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            form.getInputProps('fromAccountGroup').onChange(v)
            form.setFieldValue('fromCurrency', Currency.EUR)
            if (v === form.values.toAccountGroup && form.values.toCurrency === Currency.EUR) {
              form.setFieldValue('toCurrency', null)
            }
          }}
        />

        <SearchableSelect
          labelProps={{ c: 'secondary' }}
          disabled={!form.values.fromAccountGroup}
          {...getPropsForCurrencySearchableSelect(supportedFromCurrencies, form.values.fromCurrency)}
          label='From currency'
          description={
            form.values.fromAccountGroup && form.values.fromCurrency
              ? `Available balance: ${currencyFormat.format(
                  accountGroupsByIBAN[form.values.fromAccountGroup].accounts.find((acc) => acc.currency === form.values.fromCurrency)?.balance ?? 0
                )} ${form.values.fromCurrency}`
              : 'Select a currency from the list'
          }
          variant='filled'
          {...form.getInputProps('fromCurrency')}
        />

        <Select
          labelProps={{ c: 'secondary' }}
          data={[
            ...Object.values(accountGroupsByIBAN).map((acc) => {
              return { label: acc.alias, value: acc.iban }
            }),
          ]}
          label='Deposit to'
          description='Select the account to credit'
          {...form.getInputProps('toAccountGroup')}
          onChange={(v) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            form.getInputProps('toAccountGroup').onChange(v)
            if (form.values.fromAccountGroup !== v) {
              form.setFieldValue('toCurrency', Currency.EUR)
            } else {
              if (form.values.toCurrency === form.values.fromCurrency) {
                form.setFieldValue('toCurrency', null)
              }
            }
          }}
        />

        <SearchableSelect
          labelProps={{ c: 'secondary' }}
          disabled={!form.values.toAccountGroup}
          {...getPropsForCurrencySearchableSelect(supportedToCurrencies, form.values.toCurrency)}
          label='To currency'
          description='Select a currency from the list'
          variant='filled'
          {...form.getInputProps('toCurrency')}
        />
        <NumberInput
          labelProps={{ c: 'secondary' }}
          hideControls
          label='Amount'
          description='Enter amount to transfer'
          variant='filled'
          decimalScale={2}
          {...form.getInputProps('amount')}
        />
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='Reference'
          description='Enter a reference for this transaction'
          variant='filled'
          {...form.getInputProps('reference')}
        />

        {form.values.fromCurrency !== form.values.toCurrency && exchangeInfo ? (
          <Box c='textMuted' fz='xs' fs='italic' lh={1.3}>
            <Box>
              1 {exchangeInfo.fromCurrency} ≈ {exchangeInfo.exchangeRate} {exchangeInfo.toCurrency}
            </Box>
            <Box>
              {currencyFormat.format(exchangeInfo.fromAmount)} {exchangeInfo.fromCurrency} ≈ {currencyFormat.format(exchangeInfo.toAmount)}{' '}
              {exchangeInfo.toCurrency}
            </Box>
          </Box>
        ) : (
          <Box />
        )}

        <Flex align='center' justify='right' gap='sm'>
          <Button
            w={125}
            rightSection={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            radius='xs'
            color={colorAliases.borderSubtle}
            c={colorAliases.textInvert}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>

          <Button
            w={125}
            radius='xs'
            variant='filled'
            color='primary'
            leftSection={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            onClick={sendInternalTransferRequest}
          >
            Transfer
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForInternalAccountTransferProps = {
  selectedIban?: string
  onClose?: () => void
}

export function openModalForInternalAccountTransfer({ selectedIban, onClose }: OpenModalForInternalAccountTransferProps = {}) {
  return () =>
    modals.openContextModal({
      modal: 'internalAccountTransfer',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'xl',
      innerProps: {
        selectedIban: selectedIban,
      },
    })
}

export default InternalAccountTransfer

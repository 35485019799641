import { createContext, ReactNode, useEffect, useState } from 'react'
import { UseFormReturnType } from '@mantine/form'
import { useGetOnboardingDetails, useSubmitPartialOnboardingDetails } from '@/api/org/hooks'
import { useParams } from 'react-router-dom'
import LoadingOrError from '../../components/loader/LoadingOrError'
import { BusinessOnboardingRequest } from '@/api/org/types'
import { Container } from '@mantine/core'
import { useOnboardingFormValidation } from './useOnboardingFormValidation'

interface OnboardingFormContextType {
  form: UseFormReturnType<BusinessOnboardingRequest>
  saveForm: () => void
  validateFields: (fields: string[]) => boolean
}

export const OnboardingFormContext = createContext<OnboardingFormContextType | null>(null)

export const OnboardingFormProvider = ({ children }: { children: ReactNode }) => {
  const { invitationCode } = useParams()
  const [formVersion, setFormVersion] = useState<number>(0)

  const form = useOnboardingFormValidation()

  const {
    data: onboardingDetailsData,
    isSuccess: isOnboardingDetailsSuccess,
    isLoading: isOnboardingDetailsLoading,
    isError: isOnboardingDetailsError,
    error: onboardingDetailsError,
    refetch: refetchOnboardingDetailsData,
  } = useGetOnboardingDetails(invitationCode ?? '', {
    enabled: !!invitationCode,
  })

  const {
    mutate: submitOnboardingDetails,
    // isSuccess: isSubmitOnboardingSuccess,
    // isLoading: isSubmitOnboardingLoading,
    isError: isSubmitOnboardingError,
    error: submitOnboardingError,
  } = useSubmitPartialOnboardingDetails()

  const saveForm = () => {
    if (invitationCode) submitOnboardingDetails({ invitationCode, onboardingDetails: { businessOnboardingRequest: form.values, version: formVersion } })
  }

  const retryForm = async () => {
    await refetchOnboardingDetailsData()
    if (invitationCode) submitOnboardingDetails({ invitationCode, onboardingDetails: { businessOnboardingRequest: form.values, version: formVersion } })
  }

  useEffect(() => {
    if (isSubmitOnboardingError && submitOnboardingError?.code === 'Org.FormDataIsStale') {
      retryForm()
    }
  }, [isSubmitOnboardingError, submitOnboardingError])

  const validateFields = (fields: string[]): boolean => {
    const validationResults = fields.map((field) => form.validateField(field))
    const allFieldsValid = validationResults.every((result) => result.hasError === false)
    return allFieldsValid
  }

  const isOnboardingEmpty = isOnboardingDetailsError && onboardingDetailsError.code === 'Org.OnboardingDataNotFound'

  useEffect(() => {
    if (isOnboardingDetailsSuccess) {
      form.setValues((currentValues) => ({
        ...currentValues,
        ...onboardingDetailsData?.data,
      }))

      setFormVersion(onboardingDetailsData?.version ?? 0)
    }
  }, [isOnboardingDetailsSuccess, isOnboardingDetailsError, onboardingDetailsData])

  if (isOnboardingDetailsLoading || (isOnboardingDetailsError && !isOnboardingEmpty)) {
    return <LoadingOrError isLoading={isOnboardingDetailsLoading} isError={isOnboardingDetailsError} />
  }

  return (
    <OnboardingFormContext.Provider value={{ form, saveForm, validateFields }}>
      <Container size='xl' p='xl'>
        {children}
      </Container>
    </OnboardingFormContext.Provider>
  )
}

import { BusinessOnboardingRequest } from '@/api/org/types'
import { useForm } from '@mantine/form'

export const useOnboardingFormValidation = () => {
  const form = useForm<BusinessOnboardingRequest>({
    initialValues: {
      company: {
        externalReference: '',
        name: '',
        dateOfIncorporation: '',
        registrationNumber: '',
        countryOfIncorporation: undefined,
        businessActivityDescription: '',
        businessActivityIndustry: undefined,
        businessActivitySubIndustry: undefined,
        registeredOfficeAddress: {
          addressLine1: '',
          addressLine2: '',
          postcode: '',
          city: '',
          countryCode: undefined,
        },
        headquartersOfficeAddress: {
          addressLine1: '',
          addressLine2: '',
          postcode: '',
          city: '',
          countryCode: undefined,
        },
        email: '',
        phoneNumber: {
          countryCode: '',
          number: '',
        },
        website: '',
        companyType: undefined,
        companyTypeOther: '',
        isSubsidiary: undefined,
        group: {
          stockExchange: {
            listedSubsidiaries: [],
          },
          governmentalLicense: {
            licensedSubsidiaries: [],
          },
        },
        governmentalLicense: {
          isLicensed: undefined,
          licensePublicSource: '',
          fileId: '',
        },
        stockExchange: {
          isListed: undefined,
          isinCode: '',
        },
        shareholders: {
          isOwnedByNominee: undefined,
          nominee: {
            fullName: '',
            regulatingCountryCode: undefined,
          },
        },
        numberOfEmployees: undefined,
        crsFatcaType: undefined,
        taxIdentification: {
          tinCountryOfIncorporation: '',
          additionalCountries: [],
        },
        beneficialOwnershipDocument: [],
        extractFromCompanyRegistry: [],
        uboStructureChart: [],
        memorandumArticleOfAssociation: [],
        amlFraudProcedures: [],
        certificateIncorporation: [],
        certificateIncumbency: [],
        purposeOfAccount: [],
        businessActivityDocuments: [],
        moneyFlowChart: [],
        companyGroupChart: [],
      },
      account: {
        purposesOfAccount: [],
        purposesOfAccountOther: '',
        purposesOfAccountAdditionalInformation: {
          serviceProviders: [],
          clients: [],
          receiveFundsOnBehalfOfOthers: '',
        },
        receiveFunds: {
          countries: [],
        },
        sendFunds: {
          countries: [],
        },
        sourceOfIncome: [],
        sourceOfIncomeOther: '',
        segregatedAccountDocument: [],
        monthlyGrossTurnover: undefined,
        annualGrossTurnover: undefined,
        monthlyTransactionVolume: undefined,
        limits: {
          daily: 0,
          monthly: 0,
        },
        requestedProducts: [],
      },
      persons: [
        {
          personId: '',
          externalReference: '',
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          personalCode: '',
          citizenshipCountryCode: undefined,
          roles: [],
          identityDocument: {
            fileId: '',
            number: '',
            issueDate: '',
            expiryDate: '',
            issuingCountryCode: undefined,
            type: undefined,
          },
          powerOfAttorney: [],
          pep: {
            isPep: undefined,
            position: '',
          },
          address: {
            addressLine1: '',
            addressLine2: '',
            postcode: '',
            city: '',
            countryCode: undefined,
          },
          taxIdentification: {
            tinCountryOfIncorporation: '',
            additionalCountries: [],
          },
          ownership: {
            type: undefined,
            typeOther: '',
            shareSize: 0,
            hasVotingControl: undefined,
            hasVotingControlAdditionalInformation: '',
          },
          uboSourceOfWealth: undefined,
          isDirectorExecutive: undefined,
        },
      ],
      additionalDocuments: [],
      hasAcceptedPrivacyPolicy: false,
    },
    validate: {
      company: {
        name: (v: string) => (v.length > 2 ? null : 'Company name must be at least 3 characters'),
        dateOfIncorporation: (v) =>
          /^\d{4}-\d{2}-\d{2}$/.test(v.toString()) ? null : 'Invalid date format (YYYY-MM-DD)',
        registrationNumber: (v) => (v ? null : 'Registration number is required'),
        companyType: (value) => {
          return !value ? 'Please select the company type' : null
        },
        email: (v) =>
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v)
            ? null
            : 'Invalid email address',
        businessActivityDescription: (v) =>
          v === '' ? 'Please select an account purpose' : null,
        phoneNumber: {
          countryCode: (v) =>
            v.length > 0 ? null : 'Country code is required',
          number: (v) => (v.length > 0 ? null : 'Phone number is required'),
        },
        website: (v) =>
          v.startsWith('http') ? null : 'Website must be a valid URL',
        numberOfEmployees: (v) =>
          v === null ? null : 'Number of employees must be greater than zero',
        group: {
          stockExchange: {
            listedSubsidiaries: {
              isinCode: (value) =>
                value === undefined || value === ''
                  ? 'Please specify the ISIN code'
                  : null,
              legalName: (value) =>
                value === undefined || value === ''
                  ? 'Please specify the legal name'
                  : null,
            },
          },
          governmentalLicense: {
            licensedSubsidiaries: {
              licensePublicSource: (value) =>
                value === undefined || value === '' ? 'Please specify the public source' : null,
              legalName: (value) => (value === undefined || value === '' ? 'Please specify the legal name' : null),
            },
          },
        },
        shareholders: {
          isOwnedByNominee: (value) => {
            return value === undefined
              ? 'Please select whether the company is owned by a nominee shareholder'
              : null
          },
          nominee: {
            fullName: (value, values) =>
              values?.company?.shareholders?.isOwnedByNominee &&
              (value === undefined || value === '')
                ? 'Please provide the full name of the nominee shareholder'
                : null,
            regulatingCountryCode: (value, values) =>
              values?.company?.shareholders?.isOwnedByNominee && !value
                ? 'Please select the regulating country for the shareholder'
                : null,
          },
        },
        governmentalLicense: {
          isLicensed: (value) =>
            value === undefined
              ? 'Please select whether the company is licensed'
              : null,
          licensePublicSource: (value, values) =>
            values.company?.governmentalLicense?.isLicensed &&
            (value === undefined || value === '')
              ? 'Please provide the public source where the license can be validated'
              : null,
        },
        stockExchange: {
          isListed: (value) =>
            value === undefined ? 'Please select whether the company is listed on any stock exchange' : null,
          isinCode: (value, values) =>
            values.company?.stockExchange?.isListed &&
            (value === undefined || value === '')
              ? 'Please provide the ISIN code for the stock exchange listing'
              : null,
        },
        taxIdentification: {
          tinCountryOfIncorporation: (v) => (v === undefined ? 'Please fill this field.' : null),
          additionalCountries: {
            countryCode: (v) => (v === undefined || v === null ? 'Please specify the residency country.' : null),
            tin: (v) => (v === undefined || v === '' ? 'Please fill this field.' : null),
          },
        },
        crsFatcaType: (v) => (v === undefined ? 'Please select an option.' : null),
      },
      account: {
        purposesOfAccount: (value) =>
          value.length > 0
            ? null
            : 'At least one purpose of account is required',
        purposesOfAccountAdditionalInformation: {
          serviceProviders: {
            locationCountryCode: (v) =>
              v === undefined || v === null
                ? 'Please specify the country'
                : null,
            name: (v) => (v && v.length > 0 ? null : 'Please provide a name'),
          },
          clients: {
            locationCountryCode: (v) =>
              v === undefined || v === null
                ? 'Please specify the country'
                : null,
            name: (v) => (v && v.length > 0 ? null : 'Please provide a name'),
          },
          receiveFundsOnBehalfOfOthers: (v, values) => {
            if (
              values.account?.purposesOfAccount?.includes('RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS')
            ) {
              return v === undefined || v === '' ? 'Please enter a company or person name' : null
            }
          },
        },
        purposesOfAccountOther: (v, values) => {
          if (values.account?.purposesOfAccount?.includes('OTHER')) {
            return v === undefined || v === ''
              ? 'Please describe the purpose of the account'
              : null
          }
        },
        monthlyGrossTurnover: (value) => (value === null ? null : 'Monthly gross turnover must be greater than zero'),
        annualGrossTurnover: (value) => (value === null ? null : 'Annual gross turnover must be greater than zero'),
        monthlyTransactionVolume: (value) =>
          value === null ? null : 'Monthly transaction volume must be greater than zero',
        receiveFunds: {
          countries: (value) =>
            value === undefined || value.length === 0
              ? 'Please select at least one country to receive funds from'
              : null,
        },
        sendFunds: {
          countries: (value) =>
            value === undefined || value.length === 0 ? 'Please select at least one country to send funds to' : null,
        },
      },
      persons: (value) =>
        value.length > 0 ? null : 'At least one person must be added',
    },
  })

  return form
}

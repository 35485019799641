import { Container, Loader } from '@mantine/core'
import { ReactNode, useState } from 'react'
import useFetchAccountGroupsByIBAN from '../../../api/queries/useFetchAccountGroupsByIBAN'
import DashboardGroup from './DashboardGroup'

const Dashboard = () => {
  const [overlay, _setOverlay] = useState<ReactNode>()
  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()

  const setOverlay = (val: ReactNode) => {
    _setOverlay(val)
    const body = document.getElementById('body')
    if (body) body.style.overflow = val ? 'hidden' : 'auto'
  }

  if (isLoading) {
    return (
      <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Container>
    )
  }

  return (
    <>
      <Container h='100%' pt='2.75rem' sx={{ isolation: 'isolate' }}>
        {accountGroupsByIBAN &&
          Object.values(accountGroupsByIBAN).map((accountGroup) => {
            return <DashboardGroup key={accountGroup.iban} group={accountGroup} setOverlay={setOverlay} />
          })}
      </Container>
      {overlay}
    </>
  )
}

export default Dashboard

import { Box, Center, Collapse, useMantineTheme, Loader, Paper, TextInput, Title, Chip, Flex, getThemeColor, lighten, Container } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import useFetchPaymentRequests from '../../../../api/queries/useFetchPaymentRequests'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconSearch } from '../../../../components/icons'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import RequestDetailsArea from './RequestDetailsArea'
import RequestSummary from './RequestSummary'
import RequestDetails from './RequestDetails'
import { useMediaQuery } from '@mantine/hooks'
import { colorAliases } from '../../../../theme/mantineTheme'

// TODO refactor to separate use of status vs payment creation type
export interface RequestFilter {
  status: PaymentRequestStatus.REJECTED | PaymentRequestStatus.PUSHED_BACK | PaymentRequestStatus.PENDING_APPROVAL
}

const PaymentRequests: React.FC = () => {
  const largeScreen = useMediaQuery('(min-width: 62em)') // 62em => desktop breakpoint

  const [paymentStatusFilter, _setPaymentStatusFilter] = useState<PaymentRequestStatus>(PaymentRequestStatus.PENDING_APPROVAL)

  const [selectedPaymentRequestIndex, setSelectedPaymentRequestIndex] = useState(0)

  const [filteredData, setFilteredData] = useState<PaymentRequest[]>([])

  const setPaymentStatusFilter = (v: PaymentRequestStatus) => {
    _setPaymentStatusFilter(v)
    setFilteredData([])
    setSelectedPaymentRequestIndex(0)
  }

  const { isLoading, data } = useFetchPaymentRequests([paymentStatusFilter])
  const [nameFilter, setNameFilter] = useState('')

  const theme = useMantineTheme()

  const chipStyles = {
    iconWrapper: { display: 'none' },
    label: {
      fontSize: '0.8rem',
      paddingTop: '1.3rem',
      paddingBottom: '1.3rem',
      height: '2.5rem',
      backgroundColor: 'transparent',
      width: '100%',
      justifyContent: 'center',
      border: `1px solid ${getThemeColor('transparentBlack.3', theme)}`,
      '&[data-checked]:not([data-disabled])': {
        color: getThemeColor('white.5', theme),
        borderColor: 'transparent',
        backgroundColor: getThemeColor('dark.0', theme),
        ':hover': {
          backgroundColor: lighten(getThemeColor('dark.0', theme), 0.1),
        },
        ':active': {
          backgroundColor: getThemeColor('dark.0', theme),
        },
      },
      '&[data-variant="filled"]': {
        borderColor: 'transparent',
        backgroundColor: `${getThemeColor('transparentBlack.3', theme)}`,
        ':hover': {
          backgroundColor: lighten(getThemeColor('dark.0', theme), 0.1),
        },
        ':active': {
          backgroundColor: getThemeColor('dark.0', theme),
        },
      },
    },
  }

  useEffect(() => {
    if (data) {
      setFilteredData(
        data.filter((request) => {
          return (
            request.internal_reference.toLowerCase().includes(nameFilter.toLowerCase()) ||
            request.beneficiaries.some(
              (b) => b.name.toLowerCase().includes(nameFilter.toLowerCase()) || b.reference.toLowerCase().includes(nameFilter.toLowerCase())
            )
          )
        })
      )
    }
  }, [data, nameFilter])

  return (
    <>
      <Container py={{ base: 'xl', desktop: '3xl' }}>
        <CSSGrid gap={{ base: 'xl', desktop: '3xl' }} alignItems='start' templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}>
          <Paper shadow='none' miw={390} mih={206} bg={colorAliases.surfaceLightgray}>
            <Box pos='relative'>
              <CSSGrid px='xl' py='2xl' pt='lg' templateColumns='1fr auto' gap='2xs'>
                <Title fz='lg' ta='center'>
                  {paymentStatusFilter === PaymentRequestStatus.PENDING_APPROVAL
                    ? 'Pending'
                    : paymentStatusFilter === PaymentRequestStatus.PUSHED_BACK
                    ? 'Pushed-back'
                    : 'Rejected'}
                  &nbsp;payment requests
                </Title>
              </CSSGrid>

              <Box px='xl' mb='xl'>
                <TextInput
                  placeholder='Search by name or reference...'
                  rightSection={<Box component={IconSearch} h='1em' c='secondary' />}
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.currentTarget.value)}
                />
              </Box>

              <Flex mb='lg' px='xl' gap='sm'>
                <Chip.Group multiple={false} value={paymentStatusFilter} onChange={(v) => setPaymentStatusFilter(v as PaymentRequestStatus)}>
                  <Chip
                    styles={chipStyles}
                    radius='xs'
                    ta='center'
                    checked={paymentStatusFilter === PaymentRequestStatus.PENDING_APPROVAL}
                    value={PaymentRequestStatus.PENDING_APPROVAL}
                    size='sm'
                    style={{ flex: 1 }}
                  >
                    Pending
                  </Chip>
                  <Chip
                    styles={chipStyles}
                    radius='xs'
                    size='sm'
                    ta='center'
                    checked={paymentStatusFilter === PaymentRequestStatus.PUSHED_BACK}
                    value={PaymentRequestStatus.PUSHED_BACK}
                    style={{ flex: 1 }}
                  >
                    Pushed-back
                  </Chip>
                  <Chip
                    styles={chipStyles}
                    radius='xs'
                    size='sm'
                    ta='center'
                    checked={paymentStatusFilter === PaymentRequestStatus.REJECTED}
                    value={PaymentRequestStatus.REJECTED}
                    style={{ flex: 1 }}
                  >
                    Rejected
                  </Chip>
                </Chip.Group>
              </Flex>
              <Box sx={(theme) => ({ borderBottomLeftRadius: theme.radius.lg, borderBottomRightRadius: theme.radius.lg })}>
                {isLoading ? (
                  <Center
                    py='xl'
                    bg='white'
                    sx={(theme) => ({
                      borderBottomLeftRadius: theme.radius.lg,
                      borderBottomRightRadius: theme.radius.lg,
                    })}
                  >
                    <Loader color='secondary' />
                  </Center>
                ) : (
                  filteredData && (
                    <Box mb='xl'>
                      {filteredData.map((request, index) => (
                        <Box key={request.id}>
                          <RequestSummary
                            key={request.id}
                            isSelected={index === selectedPaymentRequestIndex}
                            onSelect={() => {
                              setSelectedPaymentRequestIndex(index)
                              // not for mobile -> window.scrollTo(0, 0)
                            }}
                            request={request}
                          />
                          {!largeScreen && (
                            <Collapse in={index === selectedPaymentRequestIndex} display={{ desktop: 'none' }}>
                              <RequestDetails request={filteredData[selectedPaymentRequestIndex]} />
                            </Collapse>
                          )}
                        </Box>
                      ))}
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Paper>

          {filteredData?.[selectedPaymentRequestIndex] ? (
            largeScreen ? (
              <RequestDetailsArea request={filteredData[selectedPaymentRequestIndex]} />
            ) : undefined
          ) : (
            <Paper p='lg'>
              <Box pb='sm' sx={(theme) => ({ borderBottom: `2pt solid ${getThemeColor('brownGroupThemeAccent', theme)}` })}>
                <Center>
                  <Title fz={{ base: 'xl', desktop: 'xl' }} fw='bold' c='secondary' ta='center'>
                    No requests to show.
                  </Title>
                </Center>
              </Box>
            </Paper>
          )}
        </CSSGrid>
      </Container>
    </>
  )
}

export default PaymentRequests

import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Paper,
  Select,
  getThemeColor,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconArrowRight, IconPlusClear } from '@/components/icons'
import { useParams } from 'react-router-dom'
import { FormEvent } from 'react'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { PurposeOfAccountType } from '@/api/org/types'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { colorAliases } from '@/theme/mantineTheme'
import { checkboxStyles } from '../onboardingUtils'
import dayjs from 'dayjs'

const purposeOfAccountOptions: {
  value: PurposeOfAccountType
  label: string
}[] = [
  {
    value: 'PAYMENTS_TO_SERVICE_PROVIDER',
    label: 'Payments to service providers',
  },
  {
    value: 'RECEIVING_FUNDS_FROM_YOUR_CLIENTS',
    label: 'Receiving funds from clients',
  },
  {
    value: 'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS',
    label: 'Receiving funds on behalf of company(ies) or person(s)',
  },
  {
    value: 'INTRA_GROUP_TRANSACTIONS_CLIENTS',
    label: 'Intra-group transactions',
  },
  {
    value: 'EMPLOYEE_REMUNERATION_SALARY',
    label: 'Employee remuneration / Salary',
  },
  {
    value: 'RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS',
    label: 'Receiving merchant processing settlements',
  },
  { value: 'OTHER', label: 'Other' },
]

const BasicDetails = () => {
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()

  const handleChangeInPurposesOfAccount = (newValues: PurposeOfAccountType[]) => {
    form.setFieldValue('account.purposesOfAccount', newValues)

    if (newValues.includes('PAYMENTS_TO_SERVICE_PROVIDER')) {
      if (form.values.account?.purposesOfAccountAdditionalInformation?.serviceProviders?.length === 0) {
        form.insertListItem('account.purposesOfAccountAdditionalInformation.serviceProviders', {
          locationCountryCode: null,
          name: '',
        })
      }
    } else {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.serviceProviders', [])
    }

    if (newValues.includes('RECEIVING_FUNDS_FROM_YOUR_CLIENTS')) {
      if (form.values.account?.purposesOfAccountAdditionalInformation?.clients?.length === 0) {
        form.insertListItem('account.purposesOfAccountAdditionalInformation.clients', {
          locationCountryCode: null,
          name: '',
        })
      }
    } else {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.clients', [])
    }

    if (newValues.includes('RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS')) {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers', '')
    }

    if (newValues.includes('OTHER')) {
      form.setFieldValue('account.purposesOfAccountOther', '')
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (
      validateFields([
        'company.name',
        'company.dateOfIncorporation',
        'company.registrationNumber',
        'company.dateOfIncorporation',
        'account.purposesOfAccount',
        'account.purposesOfAccountAdditionalInformation.serviceProviders',
        'account.purposesOfAccountAdditionalInformation.clients',
        'account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers',
        'account.purposesOfAccountOther',
      ])
    ) {
      saveForm()
    }
  }

  return (
    <Paper p={{ base: '3xs', desktop: 'lg' }}>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href={`/onboarding/overview/${invitationCode}`} c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 1 | Basic Details</Box>
      </Breadcrumbs>
      <form onSubmit={handleSubmit}>
        <TextInput
          label='Company name'
          labelProps={{ c: 'secondary' }}
          placeholder='Enter company name'
          {...form.getInputProps('company.name')}
        />

        <DatePickerInput
          label='Registration date'
          labelProps={{ c: 'secondary' }}
          placeholder='Pick a date'
          {...form.getInputProps('company.dateOfIncorporation')}
          mt='md'
          value={form.values.company?.dateOfIncorporation ? new Date(form.values.company?.dateOfIncorporation) : null}
          onChange={(e) => {
            if (e) {
              const formattedDate = dayjs(e).format('YYYY-MM-DD')
              form.setFieldValue('company.dateOfIncorporation', formattedDate)
            } else {
              form.setFieldValue('company.dateOfIncorporation', '')
            }
          }}
        />

        <TextInput
          label='Company registration number'
          labelProps={{ c: 'secondary' }}
          placeholder='Enter registration number'
          {...form.getInputProps('company.registrationNumber')}
          mt='md'
        />

        <Select
          label='Country of incorporation'
          labelProps={{ c: 'secondary' }}
          placeholder='Enter country of incorporation'
          {...form.getInputProps('company.countryOfIncorporation')}
          {...getSelectPropsForCountry(form.values.company.countryOfIncorporation)}
          mt='md'
        />

        <Checkbox.Group
          labelProps={{ c: 'secondary' }}
          label='What is the purpose of this account?'
          {...form.getInputProps('account.purposesOfAccount')}
          onChange={(v) => handleChangeInPurposesOfAccount(v as PurposeOfAccountType[])}
        >
          <Stack mt='xs'>
            {purposeOfAccountOptions.map((option) => (
              <>
                <Checkbox key={option.value} value={option.value} label={option.label} styles={checkboxStyles} />

                {/* Section - PAYMENTS_TO_SERVICE_PROVIDER */}

                {option.value === 'PAYMENTS_TO_SERVICE_PROVIDER' &&
                  form.values.account.purposesOfAccount.includes('PAYMENTS_TO_SERVICE_PROVIDER') && (
                    <Stack gap='xl' pl='2xl'>
                      <Box c='textPrimary' fz='sm'>
                        Who are your main service providers?
                      </Box>

                      {(form.values.account.purposesOfAccountAdditionalInformation.serviceProviders ?? []).map(
                        (s, index) => (
                          <Group
                            key={index}
                            p='lg'
                            sx={(theme) => ({
                              background: getThemeColor(colorAliases.light700, theme),
                              border: '1pt dashed gray',
                            })}
                          >
                            <TextInput
                              labelProps={{ c: 'secondary' }}
                              label='Service provider name'
                              {...form.getInputProps(
                                `account.purposesOfAccountAdditionalInformation.serviceProviders.${index}.name`
                              )}
                            />
                            <Select
                              labelProps={{ c: 'secondary' }}
                              label='Location'
                              searchable
                              {...getSelectPropsForCountry(s?.locationCountryCode)}
                              {...form.getInputProps(
                                `account.purposesOfAccountAdditionalInformation.serviceProviders.${index}.locationCountryCode`
                              )}
                            />
                            {index > 0 && (
                              <Button
                                bg='negative'
                                c='error'
                                onClick={() =>
                                  form.removeListItem(
                                    'account.purposesOfAccountAdditionalInformation.serviceProviders',
                                    index
                                  )
                                }
                                sx={{ justifySelf: 'end' }}
                              >
                                Remove
                              </Button>
                            )}
                          </Group>
                        )
                      )}
                      {form.values.account.purposesOfAccountAdditionalInformation?.serviceProviders.length < 3 && (
                        <Button
                          color='secondary'
                          h='2.6rem'
                          fz='xs'
                          onClick={() =>
                            form.insertListItem('account.purposesOfAccountAdditionalInformation.serviceProviders', {
                              locationCountryCode: null,
                              name: '',
                            })
                          }
                          leftSection={<IconPlusClear style={{ color: 'white', height: '1.25rem' }} />}
                        >
                          Add another service provider
                        </Button>
                      )}
                    </Stack>
                  )}

                {/* Section - RECEIVING_FUNDS_FROM_YOUR_CLIENTS */}

                {option.value === 'RECEIVING_FUNDS_FROM_YOUR_CLIENTS' &&
                  form.values.account.purposesOfAccount.includes('RECEIVING_FUNDS_FROM_YOUR_CLIENTS') && (
                    <Stack gap='xl' pl='2xl'>
                      <Box c='textPrimary' fz='sm'>
                        Who are your main clients?
                      </Box>

                      {(form.values.account.purposesOfAccountAdditionalInformation?.clients ?? []).map(
                        (client, index) => (
                          <Group
                            gap='xl'
                            key={index}
                            px='lg'
                            py='md'
                            sx={(theme) => ({
                              background: getThemeColor(colorAliases.light700, theme),
                              border: '1pt dashed gray',
                            })}
                          >
                            <TextInput
                              labelProps={{ c: 'secondary' }}
                              label='Client name'
                              {...form.getInputProps(
                                `account.purposesOfAccountAdditionalInformation.clients.${index}.name`
                              )}
                            />

                            <Select
                              labelProps={{ c: 'secondary' }}
                              label='Location'
                              searchable
                              {...getSelectPropsForCountry(client?.locationCountryCode)}
                              {...form.getInputProps(
                                `account.purposesOfAccountAdditionalInformation.clients.${index}.locationCountryCode`
                              )}
                            />

                            {index > 0 && (
                              <Button
                                color='error'
                                onClick={() =>
                                  form.removeListItem('account.purposesOfAccountAdditionalInformation.clients', index)
                                }
                                sx={{ justifySelf: 'end' }}
                              >
                                Remove
                              </Button>
                            )}
                          </Group>
                        )
                      )}

                      {form.values.account.purposesOfAccountAdditionalInformation?.clients?.length === undefined ||
                        (form.values.account.purposesOfAccountAdditionalInformation?.clients.length < 3 && (
                          <Button
                            color='secondary'
                            h='2.6rem'
                            fz='xs'
                            onClick={() =>
                              form.insertListItem('account.purposesOfAccountAdditionalInformation.clients', {
                                locationCountryCode: null,

                                name: '',
                              })
                            }
                            leftSection={<IconPlusClear style={{ color: 'white', height: '1.25rem' }} />}
                          >
                            Add another client
                          </Button>
                        ))}
                    </Stack>
                  )}

                {/* Section - RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS */}

                {option.value === 'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS' &&
                  form.values.account.purposesOfAccount.includes(
                    'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS'
                  ) && (
                    <Box pl='2xl'>
                      <TextInput
                        labelProps={{ c: 'secondary' }}
                        label='On whose behalf will the payments be collected?'
                        {...form.getInputProps(
                          'account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers'
                        )}
                      />
                    </Box>
                  )}

                {/* Section - OTHER */}

                {option.value === 'OTHER' && form.values.account.purposesOfAccount.includes('OTHER') && (
                  <Box pl='2xl'>
                    <TextInput
                      labelProps={{ c: 'secondary' }}
                      label='Please provide details, including what the purpose of the incoming and outgoing funds will be and names and locations of the main parties transacting with your Leikur account.'
                      {...form.getInputProps('account.purposesOfAccountOther')}
                      description={`${form.values.account.purposesOfAccountOther?.length ?? '0'} of 500 characters`}
                      maxLength={500}
                    />
                  </Box>
                )}
              </>
            ))}
          </Stack>
        </Checkbox.Group>

        <Group justify='space-between'>
          <Button type='submit' mt='lg'>
            Save
          </Button>
          <Button type='button' mt='lg'>
            Save and continue
          </Button>
        </Group>
      </form>
    </Paper>
  )
}

export default BasicDetails

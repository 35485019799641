import { ActionIcon, Badge, Box, Container, Divider, Flex, getThemeColor, Loader, Paper, Text, Title, useMantineTheme } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import useFetchAccountGroupsByIBAN from '../../../api/queries/useFetchAccountGroupsByIBAN'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconHelpQuestion, IconViewInfoEye } from '../../../components/icons'
import currencies from '../../../utils/currencies'
import formatIBAN from '../../../utils/formatIBAN'
import { colorAliases } from '../../../theme/mantineTheme'
import { Flag } from '../../../components/Flag'

const Welcome = ({ dismissHelp, showHelp }: { dismissHelp: boolean; showHelp: () => void }) => {
  const navigate = useNavigate()
  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()
  const theme = useMantineTheme()

  return (
    <Paper p='lg' miw={360} mih={206} pos='relative' sx={{ zIndex: 1 }} mt={`${!dismissHelp ? '-20px' : 0}`} bg='transparent'>
      <Flex gap='3xs' pos='relative' align='center' justify='center'>
        <Title fz={{ base: 'xl', desktop: '2xl' }} ta='center' order={1}>
          Statement Centre
        </Title>
        {dismissHelp && (
          <ActionIcon variant='transparent' onClick={showHelp} color='primary' pos='absolute' right='0'>
            <IconHelpQuestion style={{ color: getThemeColor(colorAliases.borderSubtle, theme), height: '1.2em', width: '1.55em' }} />
          </ActionIcon>
        )}
      </Flex>
      <Divider size='sm' mt='sm' />
      <Box ta='center' pt='md' c='textPrimary'>
        <Text pb='sm'>
          Welcome to the Statement Centre! <b>Click on an account below to get started</b>
        </Text>
      </Box>
      <Title order={2} fz='lg' ta='center' fw={300} c={getThemeColor(colorAliases.surfaceDarkgray, theme)}>
        Your Accounts
      </Title>
      {isLoading && (
        <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </Container>
      )}

      <CSSGrid
        templateColumns={{ base: '1fr', desktop: 'repeat(3, 1fr)' }}
        alignContent='center'
        autoRows='1fr'
        gap={{ base: 'sm', desktop: 'md' }}
        mx={{ desktop: 'auto' }}
        mb={{ desktop: 'md' }}
        maw={{ desktop: '85%' }}
      >
        {accountGroupsByIBAN &&
          Object.values(accountGroupsByIBAN).map((group) => (
            <Paper
              shadow='none'
              radius='xs'
              p='sm'
              key={group.iban}
              pos='relative'
              bg={colorAliases.surfaceLight}
              onClick={() => navigate(`/statement-centre/${group.iban}`, { replace: true })}
            >
              <Box
                ta='center'
                p='lg'
                sx={{
                  userSelect: 'none',
                  cursor: 'pointer',
                  ':hover': {
                    background: 'white',
                  },
                }}
              >
                <Flex justify='center' w='100%' pb='xs' align='center' gap='xs'>
                  <Text fz={{ base: 'lg', desktop: 'xl' }}>{group.alias}</Text>
                  <IconViewInfoEye style={{ color: getThemeColor(colorAliases.borderSubtle, theme), height: '1.2em', width: '1.55em' }} />
                </Flex>
                <Text fz='sm'>
                  <Box component='span' c={getThemeColor(colorAliases.textInvertSecondary, theme)}>
                    IBAN:{' '}
                  </Box>
                  <Box component='span' c={getThemeColor(colorAliases.textInvertSecondary, theme)}>
                    {formatIBAN(group.iban)}
                  </Box>
                </Text>
                <Flex fz='sm' pt='md' justify='center' wrap='wrap' gap='2xs'>
                  {group.accounts.slice(0, group.accounts.length > 10 ? 9 : 10).map((account) => (
                    <Badge
                      c='dark'
                      variant='subtle'
                      key={account.id}
                      p='0'
                      pl='0.2rem'
                      size='lg'
                      tt='none'
                      bg='white'
                      w={60}
                      h={24}
                      sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignContent: 'center' }}
                      styles={{
                        leftSection: { margin: 0 },
                      }}
                      leftSection={
                        <Flag
                          countryCode={currencies[account.currency]?.countryCodeForFlag}
                          square={true}
                          sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                          style={{
                            borderRadius: '50%',
                            width: '1rem',
                            height: '1rem',
                          }}
                        />
                      }
                    >
                      {account.currency}
                    </Badge>
                  ))}
                  {group.accounts.length > 10 && (
                    <Badge c='textMuted' variant='filled' size='sm' tt='none' w={54} fw='bold'>
                      + {group.accounts.length - 9}
                    </Badge>
                  )}
                </Flex>
              </Box>
            </Paper>
          ))}
      </CSSGrid>
    </Paper>
  )
}
export default Welcome

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { OnboardingSessionContext } from '../../../contexts/onboarding/OnboardingContext'
import { OnboardingSessionReducerAction, OnboardingSessionReducerActionType, UpdateOnboardingRequestPayloads } from '../../../types/onboarding'

const saveStep = async (dispatch: React.Dispatch<OnboardingSessionReducerAction>, request: UpdateOnboardingRequestPayloads) => {
  return new Promise((resolve) => {
    dispatch({
      type: OnboardingSessionReducerActionType.UPDATE_ONBOARDING_REQUEST,
      payload: request,
    })

    resolve(request)
  })
}

export default function useUpdateOnboardingRequest(onSuccess?: () => void) {
  const { onboardingDispatch } = useContext(OnboardingSessionContext)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: UpdateOnboardingRequestPayloads) => saveStep(onboardingDispatch, request),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['onboarding-request'] })
      if (onSuccess) onSuccess()
    },
  })
}

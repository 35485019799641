import { Accordion, Box, Modal, ModalProps, Title } from '@mantine/core'

const TaxRegulationsModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      size='xl'
      padding='lg'
      closeButtonProps={{ size: 'md' }}
      title={
        <Title order={2} size='h3' c='textPrimary'>
          Active and Passive Non Financial Entity/Non Financial Foreign Entity
        </Title>
      }
      {...props}
    >
      <Accordion variant='separated' c='textPrimary'>
        <Accordion.Item value='active_nfe'>
          <Accordion.Control>
            <Box c='textPrimary'>
              <Box fz='xs' mb='0.5rem'>
                TERM USED FOR CRS
              </Box>
              <Box>Active Non Financial Entity (Active NFE)</Box>
            </Box>
          </Accordion.Control>
          <Accordion.Panel>
            Active NFEs are companies meeting any of the following criteria:
            <ul>
              <li>
                Less than 50% of the NFE&apos;s gross income for the preceding calendar year or other appropriate
                reporting period is passive income and less than 50% of the assets held by the NFE during the preceding
                calendar year or other appropriate reporting period are assets that produce or are held for the
                production of passive income;
              </li>
              <li>
                The stock of the NFE is regularly traded on an established securities market or the NFE is a related
                entity of an entity the stock of which is regularly traded on an established securities market;
              </li>
              <li>
                The NFE is a Governmental entity, an International organization, a Central bank, or an Entity wholly
                owned by one or more of the foregoing;
              </li>
              <li>
                Substantially all of the activities of the NFE consist of holding (in whole or in part) the outstanding
                stock of, or providing financing and services to, one or more subsidiaries that engage in trades or
                businesses other than the business of a financial Institution, except that an entity does not qualify
                for this status if the Entity functions (or holds itself out) as an investment fund, such as a private
                equity fund, venture capital fund, leveraged buyout fund, or any investment vehicle whose purpose is to
                acquire or fund companies and then hold interests in those companies as capital assets for investment
                purposes;
              </li>
              <li>
                The NFE is not yet operating a business and has no prior operating history, but is investing capital
                into assets with the intent to operate a business other than that of a financial Institution, provided
                that the NFE does not qualify for this exception after the date that is 24 months after the date of the
                initial organization of the NFE;
              </li>
              <li>
                The NFE was not a financial Institution in the past five years, and is in the process of liquidating its
                assets or is reorganizing with the intent to continue or recommence operations in a business other than
                that of a financial Institution;
              </li>
              <li>
                The NFE primarily engages in financing and hedging transactions with, or for, related entities that are
                not financial Institutions, and does not provide financing or hedging services to any entity that is not
                a related entity, provided that the group of any such related entities is primarily engaged in a
                business other than that of a financial institution;
              </li>
            </ul>
            The NFE meets all of the following requirements:
            <ul>
              <li>
                It is established and operated in its jurisdiction of residence exclusively for religious, charitable,
                scientific, artistic, cultural, athletic, or educational purposes; or it is established and operated in
                its jurisdiction of residence and it is a professional organization, business league, chamber of
                commerce, labor organization, agricultural or horticultural organization, civic league or an
                organization operated exclusively for the promotion of social welfare; and
              </li>
              <li>It is exempt from income tax in its jurisdiction of residence; and</li>
              <li>
                It has no shareholders or members who have a proprietary or beneficial interest in its income or assets;
                and
              </li>
              <li>
                The applicable laws of the NFE&apos;s jurisdiction of residence or the NFE&apos;s formation documents do
                not permit any income or assets of the NFE to be distributed to, or applied for the benefit of, a
                private person or non-charitable entity other than pursuant to the conduct of the NFE&apos;s charitable
                activities, or as payment of reasonable compensation for services rendered, or as payment representing
                the fair market value of property which the NFE has purchased; and
              </li>
              <li>
                The applicable laws of the NFE&apos;s jurisdiction of residence or the NFE&apos;s formation documents
                require that, upon the NFE&apos;s liquidation or dissolution, all of its assets be distributed to a
                Governmental entity or other non-profit organization, or escheat to the government of the NFE&apos;s
                jurisdiction of residence or any political subdivision thereof.
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='active_nffe'>
          <Accordion.Control>
            <Box c='textPrimary'>
              <Box fz='xs' mb='0.5rem'>
                TERM USED FOR FATCA
              </Box>
              <Box>Active Non Financial Foreign Entity (Active NFFE)</Box>
            </Box>
          </Accordion.Control>
          <Accordion.Panel>
            Active NFFEs are companies meeting any of the following criteria:
            <ul>
              <li>
                Less than 50 percent of the NFFE&apos;s gross income for the preceding calendar year or other
                appropriate reporting period is passive income and less than 50 percent of the assets held by the NFFE
                during the preceding calendar year or other appropriate reporting period are assets that produce or are
                held for the production of passive income;
              </li>
              <li>
                The stock of the NFFE is regularly traded on an established securities market or the NFFE is a related
                entity of an entity the stock of which is regularly traded on an established securities market;
              </li>
              <li>
                The NFFE is organized in a U.S. Territory and all of the owners of the payee are bona fide residents of
                that U.S. Territory;
              </li>
              <li>
                The NFFE is a government (other than the U.S. government), a political subdivision of such government
                (which, for the avoidance of doubt, includes a state, province, county, or municipality), or a public
                body performing a function of such government or a political subdivision thereof, a government of a U.S.
                Territory, an international organization, a non-U.S. central bank of issue, or an entity wholly owned by
                one or more of the foregoing;
              </li>
              <li>
                Substantially all of the activities of the NFFE consist of holding (in whole or in part) the outstanding
                stock of, or providing financing and services to, one or more subsidiaries that engage in trades or
                businesses other than the business of a financial institution, except that an entity shall not qualify
                for NFFE status if the entity functions (or holds itself out) as an investment fund, such as a private
                equity fund, venture capital fund, leveraged buyout fund, or any investment vehicle whose purpose is to
                acquire or fund companies and then hold interests in those companies as capital assets for investment
                purposes;
              </li>
              <li>
                The NFFE is not yet operating a business and has no prior operating history, but is investing capital
                into assets with the intent to operate a business other than that of a financial institution, provided
                that the NFFE shall not qualify for this exception after the date that is 24 months after the date of
                the initial organization of the NFFE;
              </li>
              <li>
                The NFFE was not a financial institution in the past five years, and is in the process of liquidating
                its assets or is reorganizing with the intent to continue or recommence operations in a business other
                than that of a financial institution;
              </li>
              <li>
                The NFFE primarily engages in financing and hedging transactions with, or for, related entities that are
                not financial institutions, and does not provide financing or hedging services to any entity that is not
                a related entity, provided that the group of any such related entities is primarily engaged in a
                business other than that of a financial institution;
              </li>
              <li>
                The NFFE is a non-financial entity like holding companies, treasury centers and captive finance
                companies that are members of a non-financial group, start-up companies; entities that are liquidating
                from bankruptcy; and non-profit organizations; and direct reporting NFFEs and sponsored direct reporting
                NFFEs;
              </li>
            </ul>
            The NFFE meets all of the following requirements:
            <ul>
              <li>
                It is established and operated in its jurisdiction of residence exclusively for religious, charitable,
                scientific, artistic, cultural, athletic, or educational purposes; or it is established and operated in
                its jurisdiction of residence and it is a professional organization, business league, chamber of
                commerce, labor organization, agricultural or horticultural organization, civic league or an
                organization operated exclusively for the promotion of social welfare; and
              </li>
              <li>It is exempt from income tax in its jurisdiction of residence; and</li>
              <li>
                It has no shareholders or members who have a proprietary or beneficial interest in its income or assets;
                and
              </li>
              <li>
                The applicable laws of the NFFE&apos;s jurisdiction of residence or the NFFE&apos;s formation documents
                do not permit any income or assets of the NFFE to be distributed to, or applied for the benefit of, a
                private person or non-charitable Entity other than pursuant to the conduct of the NFFE&apos;s charitable
                activities, or as payment of reasonable compensation for services rendered, or as payment representing
                the fair market value of property which the NFFE has purchased; and
              </li>
              <li>
                The applicable laws of the NFFE&apos;s jurisdiction of residence or the NFFE&apos;s formation documents
                require that, upon the NFFE&apos;s liquidation or dissolution, all of its assets be distributed to a
                governmental entity or other non-profit organization, or escheat to the government of the NFFE&apos;s
                jurisdiction of residence or any political subdivision thereof.
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='passive_nfe'>
          <Accordion.Control>
            <Box c='textPrimary'>
              <Box fz='xs' mb='0.5rem'>
                TERM USED FOR CRS
              </Box>
              <Box> Passive Non Financial Entity (Passive NFE)</Box>
            </Box>
          </Accordion.Control>
          <Accordion.Panel>
            Passive NFE is a legal entity, not a financial institution, described below:
            <ul>
              <li>
                Company, having more than 50% percent of passive income (dividends, interest) or more than 50% of its
                property generates passive income; or
              </li>
              <li>
                Investment entity that is managed by another financial institution and located in a non-participating
                jurisdiction.
              </li>
              <li>
                In general, Passive NFE is a legal entity, having more than 50% percent of passive income or more than
                50% of its property generates passive income.
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='passive_nffe'>
          <Accordion.Control>
            <Box c='textPrimary'>
              <Box fz='xs' mb='0.5rem'>
                TERM USED FOR FATCA
              </Box>
              <Box>Passive Non Financial Foreign Entity (Passive NFFE)</Box>
            </Box>
          </Accordion.Control>
          <Accordion.Panel>
            Passive NFFE means any NFFE that is not:
            <ul>
              <li>An Active NFFE, or</li>
              <li>
                A withholding foreign partnership or withholding foreign trust pursuant to relevant U.S. Regulations.
              </li>
              In general, Passive NFFE is a legal entity, having more than 50% percent of passive income or more than
              50% of its property generates passive income.
            </ul>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Modal>
  )
}

export default TaxRegulationsModal

import { Center, Loader } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { RouterProvider } from 'react-router-dom'
import modals, { modalProps } from './components/modals'
import browserRouter from './routes/browserRouter'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID!}>
      <ModalsProvider modals={modals} modalProps={modalProps}>
        <RouterProvider
          router={browserRouter}
          fallbackElement={
            <Center p='xl'>
              <Loader />
            </Center>
          }
        />
      </ModalsProvider>
    </GoogleOAuthProvider>
  )
}

export default App

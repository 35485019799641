import { Container, Loader, Stack, Text } from '@mantine/core'

interface LoadingOrErrorProps {
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  loadingMessage?: string
}

const LoadingOrError = ({
  isLoading,
  isError,
  errorMessage = 'Error loading details. Please try again later.',
  loadingMessage = 'Loading... Please wait. If this takes too long, please check your connection or try again later.',
}: LoadingOrErrorProps) => {
  if (isLoading) {
    return (
      <Container py={{ base: 'xl', desktop: '2xl' }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack align='center'>
          <Loader />
          <Text ta='center' mt='md'>
            {loadingMessage}
          </Text>
        </Stack>
      </Container>
    )
  }

  if (isError) {
    return (
      <Container py={{ base: 'xl', desktop: '2xl' }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack align='center'>
          <Text ta='center' mt='md' c='red'>
            {errorMessage}
          </Text>
        </Stack>
      </Container>
    )
  }

  return null // If not loading or error, render nothing
}

export default LoadingOrError

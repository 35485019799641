import { Box, Button, Flex, Paper, PaperProps, TextInput, Title } from '@mantine/core'
import { PaymentTemplateFilters } from '.'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { getPropsForCurrencySearchableSelect } from '../../../../components/common/currencyDropdownHelper'
import { IconCloseCross, IconDownArrow, IconSearch } from '../../../../components/icons'
import { SearchableSelect } from '../../../../components/inputs/SearchableSelect'
import { colorAliases } from '../../../../theme/mantineTheme'
import useBeneficiaryCurrencyList from '../../../../utils/useBeneficiaryCurrencyOptions'

const ActionBar: React.FC<
  PaperProps & {
    filters: PaymentTemplateFilters
    setFilters: (val: Partial<PaymentTemplateFilters> | ((current: PaymentTemplateFilters) => Partial<PaymentTemplateFilters>)) => void
  }
> = ({ filters, setFilters, ...otherPaperProps }) => {
  const beneficiaryCurrencyList = useBeneficiaryCurrencyList()

  return (
    <Paper mih={270} pos='relative' w={{ base: '100%', desktop: 390 }} py='2xl' bg={colorAliases.surfaceLightgray} {...otherPaperProps}>
      <Box px='xl' pb='xl'>
        <CSSGrid gap='lg'>
          <Title fz={{ base: 'lg' }} ta='center' order={2} c={colorAliases.textInvert}>
            Search payment templates
          </Title>
          <TextInput
            sx={(theme) => ({ input: { paddingTop: theme.spacing.lg, paddingBottom: theme.spacing.lg } })}
            placeholder='Search by name, alias or reference...'
            rightSection={<Box component={IconSearch} h='1em' c='muted' />}
            value={filters.nameOrAliasOrReference}
            onChange={(n) => setFilters({ nameOrAliasOrReference: n.target.value })}
          />
          <SearchableSelect
            sx={(theme) => ({ input: { paddingTop: theme.spacing.lg, paddingBottom: theme.spacing.lg } })}
            placeholder='Filter by currency'
            {...getPropsForCurrencySearchableSelect(beneficiaryCurrencyList, filters.currency)}
            variant='default'
            rightSection={<IconDownArrow style={{ height: '0.4em', color: colorAliases.brandGray }} />}
            onChange={(v) => setFilters({ currency: v })}
            value={filters.currency}
          />
        </CSSGrid>
      </Box>
      <Flex justify='center'>
        <Button
          leftSection={<IconCloseCross style={{ height: '1em' }} />}
          onClick={() =>
            setFilters({
              nameOrAliasOrReference: '',
              currency: '',
            })
          }
          size='xs'
          radius='xs'
          variant='filled'
          h='2.1rem'
          color={colorAliases.green900}
          c='white'
          w='10rem'
        >
          Clear filters
        </Button>
      </Flex>
    </Paper>
  )
}

export default ActionBar

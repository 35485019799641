import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Account } from '../../utils/interfaces'
import { delayEmulation } from '../config'

export const fetchAccounts = (accounts: Account[]) => {
  return new Promise<Account[]>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve(accounts ?? []))
      .catch(() => reject(new Error()))
  })
}

export default function useFetchAccounts() {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['accounts', session.accounts],
    queryFn: () => fetchAccounts(session.accounts),
    notifyOnChangeProps: ['data'],
  })
}

import { Box, Button, Container, Divider, Flex, getThemeColor, Paper, Stepper, Title, useMantineTheme } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCounter } from '@mantine/hooks'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconArrowRight } from '../../../../components/icons'
import { LogoLeikur, LogoSepa } from '../../../../components/logos'
import { colorAliases } from '../../../../theme/mantineTheme'
import { Beneficiary, Currency, IDefaultPayment, PaymentType } from '../../../../utils/interfaces'
import BeneficiaryForm from './BeneficiaryForm'
import FinaliseBeneficiary from './FinaliseBeneficiary'
import { EMAIL_REGEX, regexValidate } from '../../../../components/inputs/email-validation'
import { InternationalPhoneNumber } from '../../../../components/inputs/PhoneNumber'

type BeneficiaryFormType = Omit<Beneficiary, 'entity_type' | 'country' | 'default_payment'> & {
  entity_type: '' | 'individual' | 'corporate'
  country: string | null
  default_payment: Omit<IDefaultPayment, 'amount'> & { amount: '' | number }
}
const AddBeneficiary: React.FC = () => {
  const mantineTheme = useMantineTheme()
  const accountChoiceBoxProps = {
    bg: 'background',
    p: 'md',
    w: { base: '100%', desktop: '50%' },
    sx: {
      borderRadius: mantineTheme.radius.lg,
      border: `1pt solid ${getThemeColor('muted.2', mantineTheme)}`,
    },
    templateRows: 'auto auto 1fr auto auto',
  }

  const [step, handlers] = useCounter(0, { min: 0, max: 3 })

  const beneficiaryForm = useForm<BeneficiaryFormType>({
    initialValues: {
      id: '',
      name: '',
      alias: '',
      payment_type: PaymentType.LEIKUR,
      entity_type: '',
      currency: Currency.EUR,
      email: '',
      status: 'active',
      account_details: {
        type: PaymentType.LEIKUR,
        email: '',
        iban: '',
      },
      default_payment: {
        amount: '',
        popEmail: '',
        popMobileNumber: undefined,
        documents: [],
      },
      country: null,
      reference: '',
    },

    // functions will be used to validate values at corresponding key
    validate: {
      entity_type: (value: string) => (value.length === 0 ? 'Please select the entity type' : null),
      email: (value: string) => (!regexValidate(value, true, EMAIL_REGEX) ? 'Please enter a valid email address' : null),
      alias: (value: string) => (value.length < 2 ? 'Please enter an alias' : null),
      name: (value: string) => (value.length < 2 ? 'Please enter a name' : null),
      account_details: {
        iban: (value: string) => (value.length < 5 ? 'Please enter a valid IBAN' : null),
      },
      country: (value: string | null, values) => (values.account_details.type === PaymentType.SEPA && value === null ? 'Please select a country' : null),
      payment_type: (value: string) => (value.length === 0 ? 'Please select a payment type' : null),
      default_payment: {
        popEmail: (value) => (value && !regexValidate(value, false, EMAIL_REGEX) ? 'Please enter a valid email address' : null),
        popMobileNumber: (value: InternationalPhoneNumber | undefined) =>
          value?.localPhoneNumber && !value.isValid ? 'Please enter a valid mobile number' : null,
      },
    },
  })

  const handleBeneficiaryFormShow = (type: PaymentType) => {
    beneficiaryForm.setValues({
      payment_type: type,
      account_details: type === PaymentType.SEPA ? { type, iban: '' } : { type, email: '', iban: '' },
    })
    handlers.set(1)
  }

  return (
    <Container my='lg' py={{ base: 'xl', desktop: '3xl' }} sx={{ isolation: 'isolate' }}>
      <Paper p={{ base: '2xl', desktop: '3xl' }} mx={{ desktop: '96px' }}>
        <Stepper
          active={step}
          onStepClick={handlers.set}
          allowNextStepsSelect={false}
          // FIXME: v7 - Stepper no longer supports breakpoint prop, you can apply responsive styles with Styles API
          // breakpoint='desktop'
          color='secondary'
          contentPadding='3xl'
          size='sm'
          styles={{ steps: { marginInline: '1rem' } }}
        >
          <Stepper.Step c={colorAliases.textInvert} label='Account Type' description='Choose an account type'>
            <Flex gap={{ base: 'md', desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
              <CSSGrid {...accountChoiceBoxProps}>
                <Flex my='md' fz='3rem' align='center' justify='center'>
                  <LogoLeikur style={{ height: '1em' }} />
                </Flex>
                <Title order={2} fz='xl' lh={1.8} ta='center'>
                  Leikur account
                </Title>

                <Box c='textPrimary' fw={400} fz='sm' lh={1.8} py='xs' px='lg'>
                  Send funds directly to a Leikur account holder using an email address.
                  <br />
                  Funds are immediately available.
                </Box>

                <Divider my='md' size='md' color='secondary' />
                <Flex display='flex' justify='center'>
                  <Button
                    radius='xs'
                    rightSection={<IconArrowRight style={{ height: '1.3em', width: '1.3em' }} />}
                    variant='filled'
                    color='secondary'
                    onClick={() => handleBeneficiaryFormShow(PaymentType.LEIKUR)}
                  >
                    Next
                  </Button>
                </Flex>
              </CSSGrid>
              <CSSGrid {...accountChoiceBoxProps}>
                <Flex my='md' fz='3rem' align='center' justify='center'>
                  <LogoSepa style={{ height: '1em' }} />
                </Flex>
                <Title order={2} fz='xl' lh={1.8} ta='center'>
                  IBAN account
                </Title>
                <Box c='textPrimary' fw={400} fz='sm' lh={1.8} py='xs' px='lg'>
                  Initiate a SEPA standard bank transfer to any IBAN account in participating EU and EFTA countries.
                  <br />
                  Funds are available in 1 - 2 business days.
                </Box>

                <Divider my='md' size='lg' color='secondary' />
                <Flex display='flex' justify='center'>
                  <Button
                    radius='xs'
                    rightSection={<IconArrowRight style={{ height: '1.3em', width: '1.3em' }} />}
                    variant='filled'
                    color='secondary'
                    onClick={() => handleBeneficiaryFormShow(PaymentType.SEPA)}
                  >
                    Next
                  </Button>
                </Flex>
              </CSSGrid>
            </Flex>
          </Stepper.Step>
          <Stepper.Step c={colorAliases} label='Beneficiary details' description='Set the details'>
            <BeneficiaryForm back={handlers.decrement} next={handlers.increment} beneficiaryForm={beneficiaryForm as never} editMode={false} />
          </Stepper.Step>
          <Stepper.Step c={colorAliases.textInvert} label='Finalise' description='Review and create'>
            <FinaliseBeneficiary back={handlers.decrement} beneficiaryForm={beneficiaryForm as never} />
          </Stepper.Step>
        </Stepper>
      </Paper>
    </Container>
  )
}

export default AddBeneficiary

import { Anchor, Avatar, Badge, Box, Breadcrumbs, Button, Flex, List, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchOnboardingInfo from '../../../api/queries/onboarding/useFetchOnboardingInfo'
import useUpdateOnboardingRequest from '../../../api/queries/onboarding/useUpdateOnboardingRequest'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconArrowRight, IconCloseCross, IconDownArrow } from '../../../components/icons'
import { colorAliases } from '../../../theme/mantineTheme'
import { ClientRole, DeepPartial, OnboardingSteps, Person, Step04RepresentativeDetailsRequest } from '../../../types/onboarding'
import { helpBorder } from '../onboardingUtils'
import AddRepresentative from './AddRepresentative'
import ListSummary from './ListSummary'

const RepresentativesDetails = () => {
  const selectedStep = 4

  const { data: onboardingInfo } = useFetchOnboardingInfo()
  const { mutate: saveRequest } = useUpdateOnboardingRequest()
  // const [existingRepresentatives, setExistingRepresentatives] = useState<Person[]>([])
  const [showForm, setShowForm] = useState(false)
  const [selectedRepresentative, setSelectedRepresentative] = useState<Person | null>(null)
  const [addedRoles, setAddedRoles] = useState<DeepPartial<ClientRole>[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const uniqueRoles = Array.from(new Set(onboardingInfo?.onboarding_request?.persons?.flatMap((person) => person?.roles)))

    setAddedRoles(uniqueRoles as DeepPartial<ClientRole>[])
  }, [onboardingInfo?.onboarding_request?.persons])

  // useEffect(() => {
  //   const existingReps = Array.isArray(onboardingInfo?.onboarding_request?.persons) ? (onboardingInfo?.onboarding_request?.persons as Person[]) : []
  //   setExistingRepresentatives(existingReps)
  // }, [onboardingInfo?.onboarding_request?.persons])

  const persistRepresentative = (representative: Person) => {
    // filter out rep to ensure we're replacing it always (for both add and edit)
    const existingRepresentatives = onboardingInfo?.onboarding_request?.persons?.filter((person) => person?.personId !== representative.personId) as Person[]

    const updatedRepresentatives = [...existingRepresentatives, representative]
    const updatedRoles = Array.from(new Set(updatedRepresentatives.flatMap((person) => person.roles)))

    const hasAllRequiredRoles = [ClientRole.MAR, ClientRole.UBO, ClientRole.DIRECTOR].every((role) => updatedRoles.includes(role as DeepPartial<ClientRole>))

    saveRequest(
      {
        persons: updatedRepresentatives,
        step: OnboardingSteps.STEP_04_REPRESENTATIVES,
        status: hasAllRequiredRoles ? 'completed' : 'incomplete',
      } as Step04RepresentativeDetailsRequest,
      {
        onSuccess: () => {
          setShowForm(false)
        },
      }
    )
  }

  const handleFormOpen = ({ isEdit = false, representative = null }: { isEdit?: boolean; representative?: Person | null } = {}) => {
    if (isEdit) {
      setSelectedRepresentative(representative)
    } else {
      setSelectedRepresentative(null)
    }

    setShowForm(true)
  }

  const deleteRepresentative = (representativeId: string | undefined) => {
    const updatedRepresentatives = onboardingInfo?.onboarding_request?.persons?.filter((person) => person?.personId !== representativeId)

    const updatedRoles = Array.from(new Set(updatedRepresentatives?.flatMap((person) => person?.roles)))

    const hasAllRequiredRoles = [ClientRole.MAR, ClientRole.UBO, ClientRole.DIRECTOR].every((role) => updatedRoles.includes(role as DeepPartial<ClientRole>))

    modals.openConfirmModal({
      radius: 'md',
      padding: 'md',
      title: 'Confirm removing representatives',
      children: (
        <Box py='md'>
          <Text size='sm' mb='md' c='primary'>
            Are you sure you want to completely remove this representative and all of his or her saved data?
          </Text>
        </Box>
      ),
      labels: { confirm: 'Yes', cancel: 'No' },
      confirmProps: {
        color: 'primary',
        radius: 'xs',
        leftSection: <IconDownArrow style={{ height: '0.55em', transform: 'rotate(270deg)' }} />,
      },
      cancelProps: {
        radius: 'xs',
        c: colorAliases.textInvert,
        color: colorAliases.borderSubtle,
        variant: 'outline',
        rightSection: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} />,
      },
      onConfirm: () => {
        saveRequest({
          persons: updatedRepresentatives,
          step: OnboardingSteps.STEP_04_REPRESENTATIVES,
          status: hasAllRequiredRoles ? 'completed' : 'incomplete',
        } as Step04RepresentativeDetailsRequest)
      },
    })
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 4 | Representative Details</Box>
      </Breadcrumbs>

      {!showForm && (
        <>
          <ListSummary roles={addedRoles} />

          <Box p='md' sx={helpBorder}>
            <Text mb='xs' c='secondary' ml='md' fz='sm'>
              To Add a representative:
            </Text>
            <List type='ordered' spacing='2xs' size='sm' c='secondary' pl='md'>
              <List.Item>Click &quot;Create representative&quot;</List.Item>
              <List.Item>Select the roles for the person</List.Item>
              <List.Item>Fill in the details and add the person.</List.Item>
            </List>
            <Text mb='2xs' c='secondary' mt='xs' ml='md' fz='xs' fs='italic'>
              * One MAR, Director and UBO are required.
            </Text>
          </Box>

          <Box ta={{ base: 'center', desktop: 'left' }}>
            <Button mt='lg' mb='md' variant='outline' onClick={() => handleFormOpen()} size='xs'>
              Create representative
            </Button>
          </Box>
          {onboardingInfo?.onboarding_request.persons && onboardingInfo?.onboarding_request.persons.length > 0 && (
            <Text c='textPrimary' mb='sm' fw={600}>
              Added representatives:
            </Text>
          )}
          {onboardingInfo?.onboarding_request?.persons?.map((representative) => {
            return (
              <Flex key={representative?.personId} justify='space-between' mb='3xs' align='center' w='100%'>
                <Flex align='center'>
                  <Avatar radius='xl' />
                  <Text fz='md' ml='sm'>
                    {`${representative?.firstName || ''} ${representative?.lastName || ''}`}
                  </Text>
                </Flex>

                <Flex>
                  <Flex gap='xs' align='center' mr='xs'>
                    {representative?.roles?.map((role) => (
                      <Badge h='1.6rem' key={role} variant='light' bg='primary.2'>
                        <Text px='3xs' fz='xs' lh={1} c='white'>
                          {role}
                        </Text>
                      </Badge>
                    ))}
                  </Flex>
                  <Button variant='outline' size='xs' onClick={() => handleFormOpen({ isEdit: true, representative: representative as Person })}>
                    Edit details
                  </Button>
                  <Button
                    variant='subtle'
                    size='xs'
                    onClick={() => {
                      deleteRepresentative(representative?.personId)
                    }}
                  >
                    <IconCloseCross style={{ height: '1em', color: 'red' }} />
                  </Button>
                </Flex>
              </Flex>
            )
          })}
        </>
      )}

      {showForm && (
        <AddRepresentative representative={selectedRepresentative ?? null} persistRepresentative={persistRepresentative} setShowForm={setShowForm} />
      )}
      {!showForm && (
        <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto' gap='sm' mt='md'>
          <Button mt='md' onClick={() => navigate(`/onboarding/step/${selectedStep - 1}`)} color='dark'>
            Previous
          </Button>
          <Button
            h='2.4rem'
            size='xs'
            mt='md'
            color='primary'
            onClick={() => navigate(`/onboarding/step/${selectedStep + 1}`)}
            disabled={onboardingInfo?.onboarding_request?.persons?.length === 0}
          >
            Save and continue
          </Button>
        </CSSGrid>
      )}
    </>
  )
}

export default RepresentativesDetails

// for lazy loading via react-router-dom
export const Component: React.FC = RepresentativesDetails
Component.displayName = 'OnboardingRepresentativesDetailsComponent'

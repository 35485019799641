import { Flex, FlexProps, Text } from '@mantine/core'
import React from 'react'
import { Flag } from '../Flag'
import { getCountry } from '../inputs/countries'

export const DisplayCountryNameWithFlag: React.FC<FlexProps & { countryCode: string }> = ({ countryCode, ...props }) => {
  return (
    <Flex align='center' lh='1.3' {...props}>
      <Flag countryCode={countryCode} square={false} />
      &nbsp;&nbsp;
      <Text component='span' fw='bold'>
        {countryCode}
      </Text>
      <Text component='span'>
        &nbsp;
        {getCountry(countryCode).name}
      </Text>
    </Flex>
  )
}

import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { OnboardingSessionContext } from '../../../contexts/onboarding/OnboardingContext'
import { DeepPartial, OnboardingRequest, OnboardingSession, OnboardingStepStatusMap } from '../../../types/onboarding'
import { delayEmulation } from '../../config'

const fetchOnboardingInfo = async (session: OnboardingSession) => {
  return new Promise<{ onboarding_request: DeepPartial<OnboardingRequest>; steps_status_map: OnboardingStepStatusMap }>((resolve, reject) => {
    delayEmulation()
      .then(() => resolve({ onboarding_request: session.onboarding_request, steps_status_map: session.steps_status_map }))
      .catch(() => reject(new Error()))
  })
}

export default function useFetchOnboardingInfo() {
  const { onboardingSession } = useContext(OnboardingSessionContext)

  return useQuery({
    queryKey: ['onboarding-request', onboardingSession],
    queryFn: () => fetchOnboardingInfo(onboardingSession),
  })
}

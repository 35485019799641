import { Box, Group, Radio, SimpleGrid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Person } from '../../../../types/onboarding'
import { helpBorder } from '../../onboardingUtils'

interface PepDetailsProps {
  form: ReturnType<typeof useForm<Person>>
}

const PepDetails: React.FC<PepDetailsProps> = ({ form }) => (
  <SimpleGrid cols={1} style={{ rowGap: 'var(--mantine-spacing-sm)' }} mt='xl'>
    <Radio.Group
      labelProps={{ c: 'secondary', fz: 'lg', fw: 500, mb: 'md' }}
      label='Political Exposure'
      {...form.getInputProps('pep.isPep')}
      value={form.values.pep.isPep === undefined ? undefined : form.values.pep.isPep ? 'Yes' : 'No'}
      onChange={(value) => {
        form.setFieldValue('pep.isPep', value === 'Yes')
      }}
    >
      <Box lh='1.55' p='1rem' sx={helpBorder}>
        <Text fz='sm' fw='bold' c='secondary'>
          Politically exposed person or “PEP” - an individual who is entrusted with prominent public functions and immediate family members or persons known to
          be close associates of such person.
        </Text>
      </Box>
      <Group mt='md' gap='md' mb={form.getInputProps('pep.isPep').error ? 'sm' : 0}>
        <Radio
          value='Yes'
          label='Yes'
          sx={{
            label: { color: 'var(--mantine-color-dark-4)', fontWeight: 500 },
          }}
        />
        <Radio
          value='No'
          label='No'
          sx={{
            label: { color: 'var(--mantine-color-dark-4)', fontWeight: 500 },
          }}
        />
      </Group>
    </Radio.Group>

    {form.values.pep.isPep && <TextInput labelProps={{ c: 'secondary' }} mt='lg' ml='sm' label='Role/Position' {...form.getInputProps('pep.position')} />}
  </SimpleGrid>
)

export default PepDetails

import { Box, Flex } from '@mantine/core'
import currencies from '../../utils/currencies'
import { Currency } from '../../utils/interfaces'
import { Flag } from '../Flag'
import { SearchableSelectItem, SearchableSelectProps } from '../inputs/SearchableSelect'

export const getPropsForCurrencySearchableSelect = (data: Currency[], currentVal: string | null): SearchableSelectProps => {
  const currencyOptions = data.map(
    (currencyCode) =>
      ({
        value: currencyCode,
        label: `${currencyCode} (${currencies[currencyCode].regionName})`,
        labelNode: (
          <Flex gap='xs' align='center'>
            <Flag
              countryCode={currencies[currencyCode]?.countryCodeForFlag}
              square={true}
              style={{
                borderRadius: '50%',
              }}
            />
            <Box>{`${currencyCode} (${currencies[currencyCode].regionName})`}</Box>
          </Flex>
        ),
        leftSectionNode: (
          <Flag
            countryCode={currencies[currentVal as Currency]?.countryCodeForFlag}
            square={true}
            style={{
              borderRadius: '50%',
            }}
          />
        ),
        searchString: `${currencyCode} (${currencies[currencyCode].regionName})`,
      } as SearchableSelectItem)
  )

  return {
    items: currencyOptions,
  }
}

// type SelectItemProps = BoxProps & {
//   value: string
//   label: string
//   currency: Currency
//   selected: boolean
//   variant?: string
// }

// const SelectItem: React.FC<SelectItemProps> = React.forwardRef(function SelectItem({ value, ...others }, ref: React.ForwardedRef<HTMLDivElement>) {
//   delete others.variant
//   return (
//     <Box key={value} ref={ref} {...others}>
//       <Flex align='center' gap='sm'>
//         <Flex pos='relative' align='center' justify='center'>
//           <Flag
//             countryCode={currencies[value as Currency]?.countryCodeForFlag}
//             square={true}
//             sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
//             style={{
//               borderRadius: '50%',
//               width: '1rem',
//               height: '1rem',
//               zIndex: 1,
//             }}
//           />
//         </Flex>
//         <Text size='sm'>
//           {value} ({currencies[value as Currency].regionName})
//         </Text>
//       </Flex>
//     </Box>
//   )
// })

// export const getSelectPropsForCurrency = (data: Currency[], currentVal: string | null) =>
//   ({
//     variant: 'filled',
//     size: 'sm',
//     filter: (value: string, item: SelectItemProps) => item.label.toLowerCase().includes(value.toLowerCase().trim()),
//     data: [
//       ...data.map((currencyCode) => ({
//         value: currencyCode,
//         label: `${currencyCode} (${currencies[currencyCode].regionName})`,
//       })),
//     ],
//     itemComponent: SelectItem,
//     dropdownPosition: 'bottom',
//     icon:
//       currentVal !== undefined && currentVal !== null ? (
//         <Flex pos='relative' align='center' justify='center'>
//           <Flag
//             countryCode={currencies[currentVal as Currency]?.countryCodeForFlag}
//             square={true}
//             sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
//             style={{
//               borderRadius: '50%',
//               width: '1rem',
//               height: '1rem',
//               zIndex: 1,
//             }}
//           />
//         </Flex>
//       ) : undefined,
//   } as SelectProps)

// const MultiSelectValue = ({ value, label, onRemove, ...others }: MultiSelectValueProps & { value: string }) => {
//   delete others.classNames
//   return (
//     <Box {...others}>
//       <Box
//         sx={(theme) => ({
//           display: 'flex',
//           cursor: 'default',
//           alignItems: 'center',
//           backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
//           border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
//           paddingLeft: theme.spacing.xs,
//           borderRadius: theme.radius.sm,
//         })}
//       >
//         <Box mr={10}>
//           <Flag
//             countryCode={currencies[value as Currency]?.countryCodeForFlag}
//             square={true}
//             sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5' }}
//             style={{
//               borderRadius: '50%',
//               width: '1rem',
//               height: '1rem',
//               zIndex: 1,
//             }}
//           />
//         </Box>
//         <Box sx={{ lineHeight: 1, fontSize: rem(12) }}>{label}</Box>
//         <CloseButton onMouseDown={onRemove} variant='transparent' size={22} iconSize={14} tabIndex={-1} />
//       </Box>
//     </Box>
//   )
// }

// export const getMultiSelectPropsForCurrency = (data: Currency[]) =>
//   ({
//     variant: 'filled',
//     size: 'sm',
//     filter: (value: string, selected, item: SelectItemProps) => item.label.toLowerCase().includes(value.toLowerCase().trim()),
//     data: [
//       ...data.map((currencyCode) => ({
//         value: currencyCode,
//         label: `${currencyCode} (${currencies[currencyCode].regionName})`,
//       })),
//     ],
//     itemComponent: SelectItem,
//     valueComponent: MultiSelectValue,
//     dropdownPosition: 'bottom',
//   } as MultiSelectProps)

import { Flex, SimpleGrid, Text, NavLink, Container, Divider, Box } from '@mantine/core'
import { LogoLeikur } from '../logos'
import { useValidateSession } from '@/api/auth/hooks'

const date = new Date().getFullYear()

const links = [
  { label: 'Leikur FAQs', href: '/' },
  { label: 'Privacy Policy', href: '/' },
  { label: 'Rates & Fees', href: '/' },
  { label: 'Banking Regulations', href: '/' },
  { label: 'Terms of Use', href: '/' },
  { label: 'About Us', href: '/' },
]

const AppFooter = () => {
  const { data: isValidSession } = useValidateSession()

  if (!isValidSession) {
    return null
  }

  return (
    <>
      <Flex justify='center' align='center' p='0.75rem'>
        <SimpleGrid cols={{ base: 3, desktop: 6 }}>
          {links.map((link) => (
            <NavLink noWrap key={link.label} label={link.label} href={link.href} variant='subtle' style={{ textAlign: 'center' }} />
          ))}
        </SimpleGrid>
      </Flex>
      <Flex c='white' bg='secondary'>
        <Container>
          <Flex bg='secondary' align='center' gap='lg' direction={{ base: 'column', md: 'row' }} p='0.75rem'>
            <Box component={LogoLeikur} w='7rem' sx={{ path: { fill: 'white' } }} />
            <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 0, sm: 'md' }} justify='center'>
              <Text size='md' ta='center'>
                © Copyright Leikur Ltd. {date}
              </Text>
              <Divider
                orientation='vertical'
                sx={(theme) => ({
                  [theme.breakpoints.md]: {
                    display: 'none',
                  },
                })}
              />
              <Text size='md' ta='center'>
                Leikur UAB, Gedimino pr. 20, Vilnius, Lithuania
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </>
  )
}

export default AppFooter

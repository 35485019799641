import { Button, CopyButton, Flex, Table } from '@mantine/core'
import { ContextModalProps, modals } from '@mantine/modals'
import { BankAccountDetails } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { IconCopy } from '../icons'
import ModalWrapper from './ModalWrapper'

const BankAccountDetails = ({ context, id, innerProps }: ContextModalProps<{ bank_account_details: BankAccountDetails; themeColor?: string }>) => {
  return (
    <ModalWrapper title='Bank account details' themeColor={innerProps.themeColor} onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' p='sm'>
        <Table withRowBorders withColumnBorders withTableBorder c='textDarker'>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Beneficiary</td>
              <td>{innerProps.bank_account_details.account_holder_name}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>IBAN</td>
              <td>{innerProps.bank_account_details.iban}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>BIC</td>
              <td>{innerProps.bank_account_details.bic}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Bank name</td>
              <td>{innerProps.bank_account_details.bank_name}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Bank address</td>
              <td>{innerProps.bank_account_details.bank_address}</td>
            </tr>
          </tbody>
        </Table>
        <Flex align='center' justify='right' gap='sm'>
          <CopyButton
            value={`Beneficiary: ${innerProps.bank_account_details.account_holder_name}
IBAN: ${innerProps.bank_account_details.iban}
BIC: ${innerProps.bank_account_details.bic}
Bank name: ${innerProps.bank_account_details.bank_name}
Bank address: ${innerProps.bank_account_details.bank_address}`}
          >
            {({ copied, copy }) => (
              // <Tooltip color={'textPrimary'} label={'Copied'} withArrow position='top' opened={copied}>
              <Button
                radius='xl'
                leftSection={<IconCopy style={{ height: '1em' }} />}
                variant='filled'
                color={copied ? 'textPrimary' : innerProps.themeColor ?? 'primary'}
                onClick={copy}
              >
                {copied ? 'Copied!' : 'Copy'}
              </Button>
              // </Tooltip>
            )}
          </CopyButton>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForBankAccountDetailsProps = {
  bank_account_details: BankAccountDetails
  themeColor?: string
  onClose?: () => void
}

export function openModalForBankAccountDetails({ bank_account_details, themeColor = 'primary', onClose }: OpenModalForBankAccountDetailsProps) {
  return () =>
    modals.openContextModal({
      modal: 'bankAccountDetails',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        bank_account_details,
        themeColor,
      },
    })
}

export default BankAccountDetails

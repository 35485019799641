import { ActionIcon, Anchor, Box, Button, Center, Collapse, Flex, Loader, Text, Divider, Title, getThemeColor } from '@mantine/core'
import { modals } from '@mantine/modals'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchAccounts from '../../../../api/queries/useFetchAccounts'
import useFetchTransactionsByBeneficiary from '../../../../api/queries/useFetchTransactionsByBeneficiary'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import {
  IconBeneficiaryLeikur,
  IconBeneficiaryNameBookmark,
  IconBeneficiarySepa,
  IconCloseCross,
  IconDocChecked,
  IconDownArrow,
  IconEditModifyPen,
  IconFundsSend,
  IconSaveExport,
  IconTickMarkYes,
  IconViewInfoEye,
} from '../../../../components/icons'
import BeneficiaryContext from '../../../../contexts/BeneficiaryContext'
import { PAYMENT_TYPE_DESCRIPTION } from '../../../../utils/constants'
import currencies from '../../../../utils/currencies'
import formatIBAN from '../../../../utils/formatIBAN'
import { Beneficiary as BeneficiaryInterface, PaymentType } from '../../../../utils/interfaces'
import { downloadCSV } from '../../../../utils/jsToCSV'
import { colorAliases } from '../../../../theme/mantineTheme'
import BeneficiaryTransactionItem from '../../../../components/common/BeneficiaryTransactionItem'
import { Flag } from '../../../../components/Flag'
import { getCountry } from '../../../../components/inputs/countries'

interface Props {
  beneficiary: BeneficiaryInterface
  isLastInGroup: boolean
  handleFormShow: (options: { isEdit: boolean }) => void
}

const getOutlineButtonProps = (color = 'primary', hide?: 'mobile' | 'desktop' | undefined) => {
  return {
    size: 'xs',
    variant: 'filled',
    color,
    display: hide ? { base: hide === 'mobile' ? 'none' : undefined, desktop: hide === 'desktop' ? 'none' : 'block' } : undefined,
  }
}

const Beneficiary: React.FC<Props> = ({ beneficiary, isLastInGroup, handleFormShow }) => {
  const navigate = useNavigate()
  const [isOpened, setIsOpened] = useState(false)
  const { isLoading, data: transactionsByBeneficiary } = useFetchTransactionsByBeneficiary(beneficiary.id, isOpened)
  const { data: accounts } = useFetchAccounts()
  const { setSelectedBeneficiary } = useContext(BeneficiaryContext)

  // TODO: fix this flow
  const sendFunds = () => {
    navigate('/transaction-centre')
  }

  const handleEditBeneficiary = () => {
    setSelectedBeneficiary(beneficiary)
    handleFormShow({ isEdit: true })
  }

  const exportTransactions = () => {
    if (transactionsByBeneficiary && transactionsByBeneficiary.length > 0) {
      modals.openConfirmModal({
        radius: 'md',
        padding: 'md',
        title: 'Statement export',
        children: <Text size='sm'>Export the transactions for this beneficiary?</Text>,
        labels: { confirm: 'Download', cancel: 'Cancel' },
        confirmProps: {
          color: 'primary',
          radius: 'lg',
          rightSection: <IconSaveExport style={{ height: '1.1em', marginTop: '1px' }} />,
        },
        cancelProps: { radius: 'lg', color: 'error', variant: 'outline', rightSection: <IconCloseCross style={{ width: '0.75em', marginTop: '1px' }} /> },
        onConfirm: () =>
          downloadCSV(
            transactionsByBeneficiary.map((tx) => ({
              date: tx.created_at,
              reference: tx.reference,
              beneficiary: tx.counterparty.account_holder_name,
              from_account: accounts?.find((acc) => acc.id === tx.account_id)?.alias || '-',
              amount: tx.amount,
              currency: tx.currency,
              iban: tx.counterparty.iban,
              transaction_type: tx.type,
            })),
            { filename: beneficiary.alias + '-transactions-' + dayjs().format('YYYY-MM-DD') }
          ),
      })
    } else {
      modals.open({
        radius: 'md',
        padding: 'lg',
        title: 'No transactions',
        children: (
          <Text size='sm' c='textPrimary'>
            There are no transactions to export for this beneficiary.
          </Text>
        ),
      })
    }
  }

  return (
    <Box
      mb={isLastInGroup ? '3xs' : 0}
      pt='md'
      pb='lg'
      px='sm'
      sx={(theme) => ({
        cursor: 'pointer',
        borderColor: getThemeColor(colorAliases.borderSubtle, theme),
        borderWidth: '1pt',
        borderTopStyle: 'solid',
        borderBottomStyle: isLastInGroup ? 'solid' : 'none',
        '.outline-button': {
          background: getThemeColor('background.6', theme),
        },
      })}
    >
      <CSSGrid
        columnGap='md'
        h={{ base: 90, desktop: 72 }}
        templateColumns='auto 1fr auto'
        templateAreas={{
          base: '"icon name button" "icon typeAndLocation typeAndLocation"',
          desktop: '"icon name button" "icon typeAndLocation button"',
        }}
        onClick={() => setIsOpened((v) => !v)}
      >
        <Box w={24} sx={{ gridArea: 'icon' }} c='blueGroupThemeAccent'>
          {
            // beneficiary.payment_type === PaymentType.SWIFT ? (
            //   <IconBeneficiarySwift />
            // ) :
            beneficiary.payment_type === PaymentType.LEIKUR ? (
              <IconBeneficiaryLeikur style={{ height: '1.87rem', marginInline: '0.13rem' }} />
            ) : (
              <IconBeneficiarySepa style={{ color: colorAliases.warning }} />
            )
          }
        </Box>
        <Flex align='start' justify={{ desktop: 'space-between' }} direction={{ base: 'column', desktop: 'row' }} sx={{ gridArea: 'name' }}>
          <Text fw='bold'>{beneficiary.alias}</Text>
          <Text display='flex' fz='0.875rem' ta='center' sx={{ gap: '0.3rem' }} pt='0.2rem'>
            <Box component={IconBeneficiaryNameBookmark} c='primaryHighlight' w='sm' />
            <Text>{beneficiary.name}</Text>
          </Text>
        </Flex>
        <CSSGrid gap='xs' sx={{ gridArea: 'button' }}>
          <ActionIcon variant='transparent' color='primary' display={{ desktop: 'none' }}>
            <IconDownArrow
              style={{
                height: '0.5rem',
                transform: isOpened ? 'rotate(180deg)' : '',
                transition: 'transform 0.3s ease-in-out',
              }}
            />
          </ActionIcon>
          <Button
            h='2.2rem'
            {...getOutlineButtonProps('secondary', 'mobile')}
            leftSection={<IconFundsSend style={{ color: 'white', height: '0.75em' }} />}
            onClick={sendFunds}
          >
            Send payment
          </Button>
          <Button
            h='2.2rem'
            {...getOutlineButtonProps('white', 'mobile')}
            miw={160}
            c='dark'
            sx={(theme) => ({
              border: `1px solid ${getThemeColor(colorAliases.borderSubtle, theme)}`,
            })}
            className={isOpened ? '' : 'outline-button'}
            variant={isOpened ? 'filled' : 'outline'}
            leftSection={
              <IconDownArrow
                style={{
                  height: '0.5rem',
                  transform: isOpened ? 'rotate(180deg)' : '',
                  transition: 'transform 0.3s ease-in-out',
                }}
              />
            }
          >
            {isOpened ? 'Close Details' : 'View Details'}
          </Button>
        </CSSGrid>
        <Flex justify='space-between' fz='xs' sx={{ gridArea: 'typeAndLocation' }}>
          <Flex direction='column' gap='md'>
            <Text component='span' fw='bold'>
              {PAYMENT_TYPE_DESCRIPTION[beneficiary.payment_type]}
            </Text>
            <Flex align='center' lh='1.3'>
              <Flag countryCode={beneficiary.country} square={false} style={{ borderRadius: '4px' }} />
              &nbsp;&nbsp;
              <Text component='span' fw='bold'>
                {beneficiary.country}
              </Text>
              <Text component='span'>&nbsp;&nbsp;{getCountry(beneficiary.country).name}</Text>
            </Flex>
          </Flex>
          <Box ta='right' fz='xs' mt='sm'>
            <Text component='span' fz='sm'>
              Currency
            </Text>
            <Flex gap='2xs' fw='bold' justify='end' lh='1.3' align='center' mt='3xs'>
              <Flag
                countryCode={currencies[beneficiary.currency]?.countryCodeForFlag}
                square={true}
                style={{
                  borderRadius: '50%',
                  width: '1rem',
                  height: '1rem',
                }}
              />
              {beneficiary.currency}
            </Flex>
          </Box>
        </Flex>
      </CSSGrid>
      <Collapse mt='lg' in={isOpened} transitionDuration={500}>
        <Flex style={{ gridArea: 'button' }} display={{ base: 'flex', desktop: 'none' }} align='end' justify='space-between' pb='md' gap='xs'>
          <Button
            {...getOutlineButtonProps('primaryHighlight')}
            leftSection={<IconEditModifyPen style={{ height: '1em' }} />}
            onClick={() => handleEditBeneficiary()}
          >
            Edit beneficiary
          </Button>
        </Flex>
        <Box
          p='md'
          fz='xs'
          bg='white'
          c='textPrimary'
          sx={(theme) => ({
            border: `1pt dashed ${getThemeColor(colorAliases.borderSubtle, theme)}`,
          })}
        >
          <Text fz='sm' fw={700} pb='3xs' color={colorAliases.textInvertSecondary}>
            Beneficiary details
          </Text>
          <Box pl='2xs'>
            <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap={{ base: 'md', desktop: '2xl' }}>
              <Flex direction='column' gap='3xs'>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Alias
                  <b>{beneficiary.alias}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Name<b>{beneficiary.name}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Entity<b style={{ textTransform: 'capitalize' }}>{beneficiary.entity_type}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  IBAN
                  <b>{formatIBAN(beneficiary.account_details.iban)}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Network<b style={{ textTransform: 'uppercase' }}>{beneficiary.payment_type}</b>
                </Text>
                <br />
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Default Reference<b>{beneficiary.reference || '-'}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Mobile<b>{beneficiary.default_payment?.popMobileNumber?.formattedPhoneNumber ?? '-'}</b>
                </Text>
                <Text color={colorAliases.textInvertSecondary} display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
                  Email<b>{beneficiary.email}</b>
                </Text>
              </Flex>

              <CSSGrid justifyContent='space-between'>
                <Box>
                  <Center mb='xs'>
                    <Box component={IconDocChecked} />
                    <Text fz='xs' color={colorAliases.textInvertSecondary} ml='2xs'>
                      Supporting documentation justifying payment (contract, receipt, invoice)
                    </Text>
                  </Center>
                  <Divider size='xs' color='gray.2' mb='sm' />
                  {beneficiary.default_payment?.documents?.map((d) => (
                    <CSSGrid templateColumns='auto 1fr auto auto' key={d.document_id} mb='2xs' ml='md' gap='2xs' alignItems='center'>
                      <Box component={IconTickMarkYes} h='sm' c='success' ml={2}></Box>
                      <Box fz='xs' c='primary'>
                        <Anchor href={d.document_url} target='_blank'>
                          {d.document_name}
                        </Anchor>
                      </Box>
                      <Anchor href={d.document_url} target='_blank'>
                        <ActionIcon color='primary' w='1em'>
                          <IconViewInfoEye />
                        </ActionIcon>
                      </Anchor>
                    </CSSGrid>
                  ))}
                </Box>
                <Box display={{ base: 'none', desktop: 'flex' }} sx={{ alignItems: 'end', justifyContent: 'end' }}>
                  <Button
                    {...getOutlineButtonProps('primaryHighlight', 'mobile')}
                    w='auto'
                    leftSection={<IconEditModifyPen style={{ height: '1em' }} />}
                    onClick={() => handleEditBeneficiary()}
                  >
                    Edit beneficiary
                  </Button>
                </Box>
              </CSSGrid>
            </CSSGrid>
          </Box>
          <Flex
            align='center'
            justify='space-between'
            py='xs'
            mt='2xl'
            sx={(theme) => ({ borderTop: isOpened ? `1pt solid ${getThemeColor('transparentBlack.1', theme)}` : 'none' })}
          >
            <Title order={3} fz='sm' c={colorAliases.textInvert}>
              Transaction History
            </Title>
            <Button
              leftSection={<IconSaveExport style={{ height: '2em', width: '2em' }} />}
              variant='subtle'
              radius='xl'
              size='xs'
              onClick={exportTransactions}
            >
              Save/Export
            </Button>
          </Flex>
          {isLoading && (
            <Flex pl='2xs' align='center' justify='center'>
              <Loader />
            </Flex>
          )}
          {!isLoading && transactionsByBeneficiary && transactionsByBeneficiary.length > 0 ? (
            <Box>
              {transactionsByBeneficiary.map((t, i) => (
                <BeneficiaryTransactionItem key={t.id} transaction={t} isFirst={i === 0} />
              ))}
            </Box>
          ) : (
            <Box pl='2xs'>No recent transactions.</Box>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}

export default Beneficiary

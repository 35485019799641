import { Anchor, Box, Breadcrumbs, Button, Flex, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { helpBorder } from '../onboardingUtils'
import { IconArrowRight } from '../../../components/icons'
import { colorAliases } from '../../../theme/mantineTheme'

const IDVerification = () => {
  const navigate = useNavigate()

  // TODO: fetch info of the MAR
  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate: {
      firstName: (v) => (v === '' ? 'Please fill this field.' : null),
      lastName: (v) => (v === '' ? 'Please fill this field.' : null),
      // email: (v) => (v === '' ? 'Please fill this field.' : null),
    },
  })

  const startIDVerification = () => {
    form.validate()
    if (form.isValid()) {
      // TODO: start IDV
      console.log('start IDV')
    }
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 10 | ID Verification</Box>
      </Breadcrumbs>
      <CSSGrid rowGap='lg'>
        <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
          <Box fz='sm' fw='bold' c='secondary' pr='1rem' h='2rem'>
            Instructions
          </Box>
          <Box fz='xs' pr='1rem'>
            To be able to submit your application you will have to verify the ID of the Master Authorised (MAR) Representative. Please provide the basic details
            below and follow the instructions.
            <br />
            <br />
            In order to complete this ID verification stage, the MAR has to complete the whole process.
          </Box>
        </Box>
        <TextInput labelProps={{ c: 'secondary' }} label='First name' description='First name of the MAR' {...form.getInputProps('firstName')} />
        <TextInput labelProps={{ c: 'secondary' }} label='Last name' description='Last name of the MAR' {...form.getInputProps('lastName')} />
        <TextInput labelProps={{ c: 'secondary' }} label='Email address' description='Email address of the MAR' {...form.getInputProps('email')} />

        <Flex justify='space-between'>
          <Button h='2.4rem' size='xs' mt='md' color='dark' onClick={() => navigate('/onboarding/dashboard')}>
            Go to dashboard
          </Button>
          <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={startIDVerification}>
            Start
          </Button>
        </Flex>
      </CSSGrid>
    </>
  )
}

export default IDVerification

// for lazy loading via react-router-dom
export const Component: React.FC = IDVerification
Component.displayName = 'OnboardingIDVerificationComponent'

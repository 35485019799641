import { Box, Button, Chip, darken, Flex, getThemeColor, lighten, Paper, Space, Text, TextInput, useMantineTheme } from '@mantine/core'
import { BeneficiaryFilter, FILTER_ENTITY_TYPE } from '.'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { getPropsForCurrencySearchableSelect } from '../../../components/common/currencyDropdownHelper'
import { IconCloseCross, IconSearch } from '../../../components/icons'
import { SearchableSelect, usePropsForCountrySelect } from '../../../components/inputs/SearchableSelect'
import { colorAliases } from '../../../theme/mantineTheme'
import useBeneficiaryCurrencyList from '../../../utils/useBeneficiaryCurrencyOptions'

const BeneficiaryCentreSearchIndividual: React.FC<
  {
    filters: BeneficiaryFilter
    setFilters: (val: Partial<BeneficiaryFilter> | ((current: BeneficiaryFilter) => Partial<BeneficiaryFilter>)) => void
  } & React.ComponentPropsWithoutRef<'div'>
> = ({ filters, setFilters }) => {
  const beneficiaryCurrencyList = useBeneficiaryCurrencyList()
  const theme = useMantineTheme()
  const propsForCountrySelect = usePropsForCountrySelect()

  // If all Chips are the same, we can move this to the theme. Doing this for now.
  const chipStyles = {
    label: {
      fontSize: '0.8rem',
      paddingTop: '1.3rem',
      paddingBottom: '1.3rem',
      height: '2.5rem',
      backgroundColor: 'transparent',
      width: '100%',
      border: `1px solid ${getThemeColor('transparentBlack.3', theme)}`,
      '&[data-checked]:not([data-disabled])': {
        backgroundColor: `${getThemeColor('dark.0', theme)}`,
        ':hover': {
          backgroundColor: lighten(getThemeColor('dark.0', theme), 0.1),
        },
        ':active': {
          backgroundColor: darken(getThemeColor('dark.0', theme), 0.1),
        },
      },
      '&[data-variant="filled"]': {
        backgroundColor: `${getThemeColor('transparentBlack.3', theme)}`,
        ':hover': {
          backgroundColor: lighten(getThemeColor('dark.0', theme), 0.1),
        },
        ':active': {
          backgroundColor: lighten(getThemeColor('dark.0', theme), 0.1),
        },
      },
    },
  }

  return (
    <Paper p='lg' w={{ desktop: '390px' }} pos='relative' mb={{ desktop: '3xl' }} bg={colorAliases.surfaceLightgray}>
      <Box pos='relative'>
        <Text ta='center' size='xl'>
          Search individual beneficiaries
        </Text>
        <Space h='lg' />
        <CSSGrid gap='lg'>
          <TextInput
            placeholder='Search by name, alias or reference...'
            rightSection={<Box component={IconSearch} h='1em' c='muted' />}
            value={filters.nameOrAliasOrReference}
            styles={{ input: { height: '2.6rem' } }}
            onChange={(n) => setFilters({ nameOrAliasOrReference: n.target.value })}
          />
          <Chip.Group multiple={false} value={filters.entity} onChange={(n) => setFilters({ entity: n as FILTER_ENTITY_TYPE })}>
            <Flex align='center' gap='sm' direction={{ base: 'column', desktop: 'row' }}>
              <Chip styles={chipStyles} radius='xs' ta='center' size='sm' style={{ flex: 1 }} value={FILTER_ENTITY_TYPE.All}>
                Show all
              </Chip>
              <Chip styles={chipStyles} radius='xs' size='sm' ta='center' style={{ flex: 1 }} value={FILTER_ENTITY_TYPE.Individual}>
                Individual
              </Chip>
              <Chip styles={chipStyles} radius='xs' size='sm' ta='center' style={{ flex: 1 }} value={FILTER_ENTITY_TYPE.Corporate}>
                Companies
              </Chip>
            </Flex>
          </Chip.Group>

          <SearchableSelect
            placeholder='Filter by country'
            styles={{ input: { height: '2.6rem' } }}
            onChange={(v) => setFilters({ country: v })}
            value={filters.country}
            {...propsForCountrySelect}
          />

          <SearchableSelect
            // FIXME: check what this was doing
            // icon={<IconCloseCross style={{ height: '1em' }} />}
            styles={{ input: { height: '2.6rem' } }}
            placeholder='Filter by currency'
            {...getPropsForCurrencySearchableSelect(beneficiaryCurrencyList, filters.currency ?? '')}
            variant='default'
            onChange={(v) => setFilters({ currency: v })}
            value={filters.currency}
          />

          <Flex justify='center'>
            <Button
              leftSection={<IconCloseCross style={{ height: '1em' }} />}
              onClick={() =>
                setFilters({
                  nameOrAliasOrReference: '',
                  entity: FILTER_ENTITY_TYPE.All,
                  country: '',
                  currency: '',
                  status: 'active',
                })
              }
              size='xs'
              bg='secondary'
              variant='filled'
              c='white'
              w='8rem'
              h='2.2rem'
            >
              Clear filters
            </Button>
          </Flex>
        </CSSGrid>
      </Box>
    </Paper>
  )
}

export default BeneficiaryCentreSearchIndividual

import { Box, Text, BoxProps, Button, Flex, Space, useMantineTheme, getThemeColor, darken } from '@mantine/core'
import dayjs from 'dayjs'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconArrowPushBack, IconCash, IconPending, IconPreliminary, IconViewInfoEye } from '../../../../components/icons'
import currencies, { currencyFormat } from '../../../../utils/currencies'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import { colorAliases } from '../../../../theme/mantineTheme'
import { Flag } from '../../../../components/Flag'

const ApprovalRequestSummary: React.FC<BoxProps & { request: PaymentRequest; isSelected: boolean; onSelect: () => void }> = ({
  request,
  isSelected,
  onSelect,
}) => {
  const theme = useMantineTheme()

  return (
    <Box
      pos='relative'
      bg={isSelected ? colorAliases.surfaceBrand : 'white'}
      c={isSelected ? 'white' : 'textPrimary'}
      p='xl'
      sx={(theme) => ({
        userSelect: 'none',
        cursor: isSelected ? undefined : 'pointer',
        '&:hover': {
          filter: isSelected ? undefined : 'brightness(115%)',
          backgroundColor: isSelected ? undefined : darken(getThemeColor('white', theme), 0.1),
        },
      })}
      onClick={isSelected ? undefined : onSelect}
    >
      <Flex align='center' gap='sm'>
        <Box h='2.35rem' pos='relative'>
          <IconCash style={{ color: request.status === PaymentRequestStatus.READY ? getThemeColor(colorAliases.systemSuccess, theme) : 'secondary' }} />
          {request.status === PaymentRequestStatus.PENDING_APPROVAL && (
            <Box
              component={IconPending}
              h='1rem'
              pos='absolute'
              bg={isSelected ? colorAliases.surfaceBrand : 'white'}
              right={-1}
              bottom={2}
              sx={{ borderRadius: '50%' }}
            />
          )}
          {request.status === PaymentRequestStatus.READY && (
            <Box
              component={IconPreliminary}
              c={isSelected ? 'white' : 'textPrimary'}
              h='1rem'
              pos='absolute'
              bg={isSelected ? colorAliases.surfaceBrand : 'white'}
              right={-1}
              bottom={2}
              sx={{ borderRadius: '50%' }}
            />
          )}
          {request.status === PaymentRequestStatus.PUSHED_BACK && (
            <Box h='1rem' pos='absolute' bg={isSelected ? colorAliases.surfaceBrand : 'white'} right={-1} bottom={2} sx={{ borderRadius: '50%' }}>
              <Box
                component={IconArrowPushBack}
                c={isSelected ? 'warning' : 'warning.8'}
                h='100%'
                sx={{ borderRadius: '50%', border: '1pt solid currentcolor' }}
              />
            </Box>
          )}
        </Box>
        <Box>
          <Box mb='0.1em' fw='bold' fz='md' lh='1.5' sx={{ maxWidth: '28ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {request.status === PaymentRequestStatus.READY ? (
              <Box component='span' c={colorAliases.systemSuccess}>
                READY
              </Box>
            ) : (
              ''
            )}
            &nbsp;|&nbsp; {request.internal_reference}
          </Box>
          <Box fz='xs' c={isSelected ? colorAliases.textSecondaryInvert : 'textPrimary'}>
            Submitted by&nbsp;<b>{request.created_by}</b>
            &nbsp;{'/'}&nbsp;
            <b>
              {dayjs(request.last_modified_date).format('DD-MM-YYYY')}
              {', '}
              {dayjs(request.last_modified_date).format('HH:mm')}
            </b>
          </Box>
        </Box>
      </Flex>
      <Space h='sm' />
      <CSSGrid templateColumns='1fr auto' gap='2px' fz='md' alignItems='center'>
        <Flex align='center' gap='2xs'>
          <Text fz='sm'>{currencyFormat.format(request.beneficiaries[0].amount)}</Text>
          <Box>{currencies[request.currency].symbol}</Box>
          <Flag
            countryCode={currencies[request.currency]?.countryCodeForFlag}
            square={true}
            style={{
              borderRadius: '12px',
              width: '1.125rem',
              height: '1.125rem',
            }}
          />
        </Flex>
        <Button
          radius='xs'
          variant='filled'
          h='3.5em'
          disabled={isSelected}
          color={isSelected ? colorAliases.green1000 : colorAliases.brandSecondary}
          size='xs'
          px='xs'
          w='100px'
          styles={{
            root: {
              '&:disabled': {
                backgroundColor: getThemeColor(colorAliases.green1000, theme),
                color: 'white',
              },
            },
          }}
          leftSection={<IconViewInfoEye style={{ height: '1.7em', width: '1.7em' }} />}
          onClick={
            isSelected
              ? undefined
              : (e) => {
                  e.stopPropagation()
                  onSelect()
                }
          }
        >
          View
        </Button>
      </CSSGrid>
    </Box>
  )
}

export default ApprovalRequestSummary

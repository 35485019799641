import {
  Anchor,
  Badge,
  Box,
  BoxProps,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Flex,
  getThemeColor,
  Loader,
  MantineTheme,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchOnboardingInfo from '../../../api/queries/onboarding/useFetchOnboardingInfo'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { DisplayCountryNameWithFlag } from '../../../components/common/DisplayCountryNameWithFlag'
import { IconArrowRight, IconCrossCircle, IconEditModifyPen, IconTickCircle } from '../../../components/icons'
import { PurposeOfAccount, RequestedProduct, SourceOfIncome } from '../../../types/onboarding'
import currencies from '../../../utils/currencies'
import { Currency } from '../../../utils/interfaces'
import {
  getLabelForCRSFatcaType,
  getLabelForEnum,
  getLabelForPurposeOfAccount,
  getLabelForSourceOfIncome,
  industrySelectData,
  subIndustrySelectDataMap,
} from '../onboardingUtils'
import { numberOfEmployeesMap } from '../step5/FinancialDetails'
import { colorAliases } from '../../../theme/mantineTheme'
import { EmotionHelpers, EmotionSx } from '@mantine/emotion'
import { parseToInternationalPhoneNumber } from '../../../components/inputs/phone-number-validation'
import { Flag } from '../../../components/Flag'

const sectionWrapperStyles =
  (isValid: boolean): EmotionSx =>
  (theme: MantineTheme, u: EmotionHelpers) => ({
    backgroundColor: getThemeColor('light.3', theme),
    borderRightWidth: '2pt',
    borderRightStyle: 'solid',
    borderColor: isValid ? 'var(--mantine-color-positive-6)' : 'var(--mantine-color-negative-3)',
    paddingRight: theme.spacing.xs,
    [u.largerThan('desktop')]: {
      borderRightWidth: '3pt',
      paddingRight: theme.spacing.md,
    },
  })

const sectionStyles = (theme: MantineTheme, u: EmotionHelpers) => ({
  padding: `${theme.spacing.xl} ${theme.spacing.lg}`,
  borderRadius: theme.radius['sm'],
  [u.smallerThan('desktop')]: {
    padding: `${theme.spacing.xl} ${theme.spacing.md} ${theme.spacing.xl} ${theme.spacing.xl}`,
  },
})

const Col: React.FC<React.PropsWithChildren<BoxProps>> = (props) => <Box w='50%' {...props} />
const Label: React.FC<React.PropsWithChildren<BoxProps>> = (props) => <Box fz='sm' my='3xs' c='secondary' {...props} />
const Value: React.FC<React.PropsWithChildren<BoxProps>> = (props) => <Box fz='md' fw='500' c='textPrimary' {...props} />

const ReviewApplication = () => {
  const navigate = useNavigate()
  const { data: onboardingInfo, isLoading, isSuccess } = useFetchOnboardingInfo()
  const theme = useMantineTheme()

  const toDashboard = () => {
    navigate('/onboarding')
  }

  const editSection = (section: number) => () => {
    navigate(`/onboarding/step/${section}`)
  }

  if (isLoading || !isSuccess) {
    return (
      <Container py={{ base: '2xl', desktop: '4rem' }} sx={{ isolation: 'isolate' }} w={{ base: '100%', desktop: 'max(37em, 45%)' }}>
        <Box p='xs' pl={0} c='textPrimary'>
          <Flex align='center' justify='center'>
            <Loader />
          </Flex>
        </Box>
      </Container>
    )
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 11 | Review Application</Box>
      </Breadcrumbs>

      <CSSGrid rowGap='xl'>
        <Flex sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_01_BASIC_DETAILS.status === 'completed')}>
          <Box w='100%' sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_01_BASIC_DETAILS.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 1 | Basic Details
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(1)}>
                Edit Details
              </Button>
            </Flex>
            <Flex lh={1.5}>
              <Col>
                <Label>Company Name</Label>
                <Value>{onboardingInfo.onboarding_request.company?.name ?? '-'}</Value>
              </Col>
              <Col>
                <Label>Date of registration or incorporation</Label>
                <Value>{onboardingInfo.onboarding_request.company?.dateOfIncorporation ?? '-'}</Value>
              </Col>
            </Flex>
            <Flex lh={1.5}>
              <Col>
                <Label>Company registration number</Label>
                <Value>{onboardingInfo.onboarding_request.company?.registrationNumber ?? '-'}</Value>
              </Col>
              <Col>
                <Label>Country of incorporation</Label>
                <Value>
                  {onboardingInfo.onboarding_request.company?.countryOfIncorporation === undefined ? (
                    '-'
                  ) : (
                    <DisplayCountryNameWithFlag countryCode={onboardingInfo.onboarding_request.company.countryOfIncorporation} />
                  )}
                </Value>
              </Col>
            </Flex>
            <Box w='100%' mt='lg'>
              <Label>Purpose of the account</Label>
              {onboardingInfo.onboarding_request.account?.purposesOfAccount ? (
                (onboardingInfo.onboarding_request.account?.purposesOfAccount ?? [])
                  .filter(
                    (purpose) =>
                      purpose !== PurposeOfAccount.OTHER &&
                      purpose !== PurposeOfAccount.RECEIVING_FUNDS_FROM_YOUR_CLIENTS &&
                      purpose !== PurposeOfAccount.PAYMENTS_TO_SERVICE_PROVIDER &&
                      purpose !== PurposeOfAccount.RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS
                  )
                  .map((v) => (
                    <Box key={v} mt='xs' fw='500' c='textPrimary'>
                      <IconTickCircle style={{ height: '1em', marginRight: '0.5em' }} />
                      {getLabelForPurposeOfAccount(v as PurposeOfAccount)}
                    </Box>
                  ))
              ) : (
                <Value>-</Value>
              )}

              {onboardingInfo.onboarding_request.account?.purposesOfAccountAdditionalInformation?.serviceProviders && (
                <>
                  <Box mt='xs' fw='500' c='textPrimary'>
                    <IconTickCircle style={{ height: '1em', marginRight: '0.5em' }} />
                    Payments to service providers
                  </Box>
                  <Box>
                    {onboardingInfo.onboarding_request.account?.purposesOfAccountAdditionalInformation?.serviceProviders.map(
                      (sp) =>
                        sp &&
                        sp.locationCountryCode && (
                          <Value key={sp.name} pl='2rem'>
                            - {sp.name} (<DisplayCountryNameWithFlag countryCode={sp.locationCountryCode} sx={{ display: 'inline-flex' }} />)
                          </Value>
                        )
                    )}
                  </Box>
                </>
              )}

              {onboardingInfo.onboarding_request.account?.purposesOfAccountAdditionalInformation?.clients && (
                <>
                  <Box mt='xs' fw='500' c='textPrimary'>
                    <IconTickCircle style={{ height: '1em', marginRight: '0.5em' }} />
                    Receiving funds from clients
                  </Box>
                  <Box>
                    {onboardingInfo.onboarding_request.account?.purposesOfAccountAdditionalInformation?.clients.map(
                      (sp) =>
                        sp &&
                        sp.locationCountryCode && (
                          <Value key={sp.name} pl='2rem'>
                            - {sp.name} (<DisplayCountryNameWithFlag countryCode={sp.locationCountryCode} sx={{ display: 'inline-flex' }} />)
                          </Value>
                        )
                    )}
                  </Box>
                </>
              )}

              <>
                <Box mt='xs' fw='500' c='textPrimary'>
                  <IconTickCircle style={{ height: '1em', marginRight: '0.5em' }} />
                  Receiving funds on behalf of company(ies) or person(s)
                </Box>
                <Box>
                  <Value pl='2rem' lh='1.5' sx={{ display: 'inline-flex' }}>
                    - {onboardingInfo.onboarding_request.account?.purposesOfAccountAdditionalInformation?.receiveFundsOnBehalfOfOthers}
                  </Value>
                </Box>
              </>

              {onboardingInfo.onboarding_request.account?.purposesOfAccountOther && (
                <>
                  <Value lh={1.5}>- Other</Value>
                  <Value pl='2rem'>{onboardingInfo.onboarding_request.account?.purposesOfAccountOther} </Value>
                </>
              )}
            </Box>

            {onboardingInfo.steps_status_map.STEP_01_BASIC_DETAILS.status !== 'completed' && (
              <>
                <Divider my='xl' />
                <Box c='error' fw='bold' mb='md'>
                  Mandatory information is missing!
                </Box>
                <Box>
                  Please select &quot;Edit details&quot; and fill in the missing information.
                  <br />
                  <Button
                    mt='sm'
                    variant='outline'
                    color='primary'
                    size='xs'
                    leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                    onClick={editSection(1)}
                  >
                    Edit Details
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Flex>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_02_CONTACT_DETAILS.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_02_CONTACT_DETAILS.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 2 | Contact Details
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(2)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box>
                <Label>Registered office address</Label>
                {onboardingInfo.onboarding_request.company?.registeredOfficeAddress ? (
                  <>
                    {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.addressLine1 !== '' && (
                      <Value>{onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.addressLine1}, </Value>
                    )}
                    {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.addressLine2 !== '' && (
                      <Value>{onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.addressLine2}, </Value>
                    )}
                    {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.city !== '' && (
                      <Value>
                        {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.postcode},{' '}
                        {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.city}
                      </Value>
                    )}
                    <Value>
                      {onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.countryCode === undefined ||
                      onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.countryCode === '' ? (
                        '-'
                      ) : (
                        <DisplayCountryNameWithFlag countryCode={onboardingInfo.onboarding_request.company?.registeredOfficeAddress?.countryCode} />
                      )}
                    </Value>
                  </>
                ) : (
                  <Value>-</Value>
                )}
              </Box>
              <Box>
                <Label>Is the company&apos;s headquarters/main office at a different location than the registered address?</Label>
                <Value>
                  {JSON.stringify(onboardingInfo.onboarding_request.company?.registeredOfficeAddress) !==
                  JSON.stringify(onboardingInfo.onboarding_request.company?.headquartersOfficeAddress)
                    ? 'Yes'
                    : 'No'}
                </Value>
              </Box>
              {JSON.stringify(onboardingInfo.onboarding_request.company?.registeredOfficeAddress) !==
                JSON.stringify(onboardingInfo.onboarding_request.company?.headquartersOfficeAddress) && (
                <Box>
                  <Label>Headquarters/main office address</Label>
                  {onboardingInfo.onboarding_request.company?.headquartersOfficeAddress ? (
                    <>
                      <Value>{onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.addressLine1}, </Value>
                      <Value>{onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.addressLine2}, </Value>
                      <Value>
                        {onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.postcode},{' '}
                        {onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.city},{' '}
                      </Value>
                      <Value>
                        {onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.countryCode === undefined ||
                        onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.countryCode === '' ? (
                          '-'
                        ) : (
                          <DisplayCountryNameWithFlag countryCode={onboardingInfo.onboarding_request.company?.headquartersOfficeAddress?.countryCode} />
                        )}
                      </Value>
                    </>
                  ) : (
                    <Value>-</Value>
                  )}
                </Box>
              )}
              <Box>
                <Label>Company phone number</Label>
                <Value>
                  {onboardingInfo.onboarding_request.company?.phoneNumber?.countryCode
                    ? parseToInternationalPhoneNumber(
                        `${onboardingInfo.onboarding_request.company.phoneNumber.countryCode ?? ''}${
                          onboardingInfo.onboarding_request.company.phoneNumber.number ?? ''
                        }`
                      )?.formattedPhoneNumber
                    : '-'}
                </Value>
              </Box>

              <Box>
                <Label>Company email address</Label>
                <Value>{onboardingInfo.onboarding_request.company?.email === '' ? '-' : onboardingInfo.onboarding_request.company?.email}</Value>
              </Box>
              <Box>
                <Label>Company website</Label>

                <Value>{onboardingInfo.onboarding_request.company?.website === '' ? '-' : onboardingInfo.onboarding_request.company?.website}</Value>
              </Box>
              {onboardingInfo.steps_status_map.STEP_02_CONTACT_DETAILS.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(2)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_03_ADVANCED_DETAILS.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_03_ADVANCED_DETAILS.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 3 | Advanced Details
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(3)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box>
                <Label>What is your company type?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Is the company part of a larger group structure of companies forming the overall business?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>
                  Are any of the other companies in the group structure forming the overall business licensed by any government or government supported
                  institution?
                </Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Are any of the other companies in the group structure forming the overall business listed on any stock exchange?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Is the company licensed by any government or government supported institution?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Name of the public source where the license can be validated</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Is the company owned directly or indirectly by a nominee shareholder?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Full name of the nominee shareholder</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>The nominee shareholder is regulated in the following country</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>Is the company listed on any stock exchange?</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>ISIN code</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>List the countries from where you will receive funds</Label>
                <Value>-</Value>
              </Box>
              <Box>
                <Label>List the countries to where you will send funds</Label>
                <Value>-</Value>
              </Box>
              {onboardingInfo.steps_status_map.STEP_03_ADVANCED_DETAILS.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(3)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_04_REPRESENTATIVES.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_04_REPRESENTATIVES.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 4 | Representatives
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(4)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='2xs' lh={1.5}>
              <Box fz='md' c='secondary'>
                Added Representatives
              </Box>
              {onboardingInfo.onboarding_request?.persons &&
                onboardingInfo.onboarding_request?.persons?.length > 0 &&
                onboardingInfo.onboarding_request.persons.map((person, index) => (
                  <Text key={index} c='textPrimary'>
                    {' '}
                    {person?.firstName} {person?.lastName} - {person?.roles?.join(', ')}
                  </Text>
                ))}
              {onboardingInfo.steps_status_map.STEP_04_REPRESENTATIVES.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(4)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_05_FINANCIAL_DETAILS.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_05_FINANCIAL_DETAILS.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 5 | Company financial details
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(5)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box>
                <Label>Expected annual gross turnover of the account(s) within Leikur in euro:</Label>
                <Value>
                  {onboardingInfo.onboarding_request.account?.annualGrossTurnover
                    ? getLabelForEnum(onboardingInfo.onboarding_request.account.annualGrossTurnover)
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>Expected monthly turnover to and from your account(s) in Leikur in euro:</Label>
                <Value>
                  {onboardingInfo.onboarding_request.account?.monthlyGrossTurnover
                    ? getLabelForEnum(onboardingInfo.onboarding_request.account.monthlyGrossTurnover)
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>Expected monthly volume (an approximate number) of transactions to and from your account(s) in Leikur in euro:</Label>
                <Value>
                  {onboardingInfo.onboarding_request.account?.monthlyTransactionVolume
                    ? getLabelForEnum(onboardingInfo.onboarding_request.account.monthlyTransactionVolume)
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>Desired daily account(s) limits in euro:</Label>
                <Value>{onboardingInfo.onboarding_request.account?.limits?.daily ?? '-'}</Value>
              </Box>
              <Box>
                <Label>Desired monthly account(s) limits in euro:</Label>
                <Value>{onboardingInfo.onboarding_request.account?.limits?.monthly ?? '-'}</Value>
              </Box>
              <Box>
                <Label>Which Leikur products would you like to use?</Label>
                {onboardingInfo.onboarding_request.account?.requestedProducts && onboardingInfo.onboarding_request.account?.requestedProducts.length > 0 ? (
                  onboardingInfo.onboarding_request.account.requestedProducts.map((p) => (
                    <Value key={p} lh={1.5}>
                      - {getLabelForEnum(p as RequestedProduct)}
                    </Value>
                  ))
                ) : (
                  <Value>-</Value>
                )}
              </Box>
              <Box>
                <Label>Which currencies would you like to have available for payments?</Label>
                <Flex gap='xs'>
                  {[...(onboardingInfo.onboarding_request.other?.currenciesForPayment ?? []), Currency.EUR].map((currency, index) => (
                    <Badge
                      key={index}
                      color='white'
                      c='textPrimary'
                      variant='filled'
                      size='lg'
                      tt='none'
                      p='0'
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        alignContent: 'center',
                        gap: '2px',
                      }}
                      styles={{
                        leftSection: { margin: 0 },
                      }}
                      leftSection={
                        <Flag
                          countryCode={currencies[currency as Currency]?.countryCodeForFlag}
                          square={true}
                          sx={{ borderRadius: '6px', border: '1pt solid #f5f5f5' }}
                          style={{
                            borderRadius: '50%',
                            width: '1rem',
                            height: '1rem',
                          }}
                        />
                      }
                    >
                      {currency}
                    </Badge>
                  ))}
                </Flex>
              </Box>
              <Box>
                <Label>Total number of employees and exclusive contractors that work for the company</Label>
                <Value>
                  {onboardingInfo.onboarding_request.company?.numberOfEmployees
                    ? numberOfEmployeesMap.find((value) => value.value === onboardingInfo.onboarding_request.company?.numberOfEmployees)?.label
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>What will be the source of incoming funds into your Leikur account?</Label>
                {onboardingInfo.onboarding_request.account?.sourceOfIncome ? (
                  (onboardingInfo.onboarding_request.account?.sourceOfIncome ?? [])
                    .filter((purpose) => purpose !== SourceOfIncome.OTHER)
                    .map((v) => (
                      <Value key={v} lh={1.5}>
                        - {getLabelForSourceOfIncome(v as SourceOfIncome)}
                      </Value>
                    ))
                ) : (
                  <Value>-</Value>
                )}
                {onboardingInfo.onboarding_request.account?.sourceOfIncomeOther && (
                  <>
                    <Value lh={1.5}>- Other</Value>
                    <Value pl={'0.688rem'}>{onboardingInfo.onboarding_request.account?.sourceOfIncomeOther} </Value>
                  </>
                )}
              </Box>
              {onboardingInfo.steps_status_map.STEP_05_FINANCIAL_DETAILS.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(5)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_06_TAX_REGULATIONS.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_06_TAX_REGULATIONS.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 6 | Tax regulations
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(6)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box>
                <Label>Taxpayer Identification Number</Label>
                <Value>{onboardingInfo.onboarding_request.company?.taxIdentification?.tinCountryOfIncorporation ?? '-'}</Value>
              </Box>
              <Box>
                <Label>Are there any other tax residency countries for the company?</Label>

                {(onboardingInfo.onboarding_request.company?.taxIdentification?.additionalCountries ?? []).length > 0 ? (
                  <>
                    <Value>Yes</Value>
                    <Label>Tax residency country(ies)</Label>
                    {(onboardingInfo.onboarding_request.company?.taxIdentification?.additionalCountries ?? []).map((taxResidencyCountry) => (
                      <Value key={taxResidencyCountry?.tin} display='flex' sx={{ gap: 6, alignItems: 'center' }}>
                        {<DisplayCountryNameWithFlag countryCode={taxResidencyCountry?.countryCode ?? ''} />}
                        {' - '}
                        {taxResidencyCountry?.tin}
                      </Value>
                    ))}
                  </>
                ) : (
                  <Value>No</Value>
                )}
              </Box>

              <Box>
                <Label>Is the company an Active or Passive Non Financial Entity/Non Financial Foreign Entity for CRS/FATCA purposes?</Label>
                <Value>{getLabelForCRSFatcaType(onboardingInfo.onboarding_request.company?.crsFatcaType ?? ('-' as never))}</Value>
              </Box>
              <Box>
                <Label>Company is incorporated in the US</Label>
                <Value>
                  {onboardingInfo.onboarding_request.other?.fatcaStatus?.incorporatedInUS
                    ? onboardingInfo.onboarding_request.other?.fatcaStatus?.incorporatedInUS === 'TRUE'
                      ? 'Yes'
                      : 'No'
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>Company is a tax resident in the US</Label>
                <Value>
                  {onboardingInfo.onboarding_request.other?.fatcaStatus?.taxResidentInUS
                    ? onboardingInfo.onboarding_request.other?.fatcaStatus?.taxResidentInUS === 'TRUE'
                      ? 'Yes'
                      : 'No'
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>At least one of the UBOs that owns 25% or more of the shares is a US citizen or US tax resident</Label>
                <Value>
                  {onboardingInfo.onboarding_request.other?.fatcaStatus?.UBOisUSTaxResidentOrUSCitizen
                    ? onboardingInfo.onboarding_request.other?.fatcaStatus?.UBOisUSTaxResidentOrUSCitizen === 'TRUE'
                      ? 'Yes'
                      : 'No'
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>
                  A UBO with a controlling stake, veto power or owner of shares in the company with special voting power is a US citizen or tax resident
                </Label>
                <Value>
                  {onboardingInfo.onboarding_request.other?.fatcaStatus?.UBOwithVetoIsUSTaxResidentOrUSCitizen
                    ? onboardingInfo.onboarding_request.other?.fatcaStatus?.UBOwithVetoIsUSTaxResidentOrUSCitizen === 'TRUE'
                      ? 'Yes'
                      : 'No'
                    : '-'}
                </Value>
              </Box>
              {onboardingInfo.steps_status_map.STEP_06_TAX_REGULATIONS.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(6)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_07_BUSINESS_ACTIVITY.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_07_BUSINESS_ACTIVITY.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 7 | Business Activity
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(7)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box>
                <Label>Business description</Label>
                <Value>{onboardingInfo.onboarding_request.company?.businessActivityDescription ?? '-'}</Value>
              </Box>
              <Box>
                <Label>Industry type</Label>
                <Value>
                  {onboardingInfo.onboarding_request?.company?.businessActivityIndustry
                    ? industrySelectData.find((d) => d.value === onboardingInfo.onboarding_request.company?.businessActivityIndustry)?.label ?? '-'
                    : '-'}
                </Value>
              </Box>
              <Box>
                <Label>Industry sub type</Label>
                <Value>
                  {onboardingInfo.onboarding_request.company?.businessActivitySubIndustry && onboardingInfo.onboarding_request.company?.businessActivityIndustry
                    ? subIndustrySelectDataMap[onboardingInfo.onboarding_request.company.businessActivityIndustry].find(
                        (d) => d.value === onboardingInfo.onboarding_request.company?.businessActivitySubIndustry
                      )?.label ?? '-'
                    : '-'}
                </Value>
              </Box>
              {onboardingInfo.steps_status_map.STEP_07_BUSINESS_ACTIVITY.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(7)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>

        <Divider my='xs' />
        <Box sx={sectionWrapperStyles(onboardingInfo.steps_status_map.STEP_08_DATA_PROTECTION.status === 'completed')}>
          <Box sx={sectionStyles}>
            <Flex align='center' justify='space-between' mb='md'>
              <Flex align='center'>
                {onboardingInfo.steps_status_map.STEP_08_DATA_PROTECTION.status === 'completed' ? (
                  <IconTickCircle style={{ color: `${getThemeColor(colorAliases.systemSuccess, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                ) : (
                  <IconCrossCircle style={{ color: `${getThemeColor(colorAliases.systemError, theme)}`, height: '1.5em', marginRight: '0.5em' }} />
                )}
                <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
                  Step 8 | Data protection and declaration
                </Title>
              </Flex>
              <Button variant='outline' color='primary' size='xs' leftSection={<IconEditModifyPen style={{ width: '1em' }} />} onClick={editSection(8)}>
                Edit Details
              </Button>
            </Flex>
            <CSSGrid rowGap='sm' lh={1.5}>
              <Box c='primary'>
                I/we consent for the data submitted to be processed by ConnectPay for the purpose specified on this application.
                <br />
                <br />
                I/We understand that I/we have a right of access to, and the right to rectify, the personal data concerning me/us.
              </Box>
              {onboardingInfo.steps_status_map.STEP_08_DATA_PROTECTION.status !== 'completed' && (
                <>
                  <Divider my='sm' />
                  <Box c='error' fw='bold'>
                    Mandatory information is missing!
                  </Box>
                  <Box>
                    Please select &quot;Edit details&quot; and fill in the missing information.
                    <br />
                    <Button
                      mt='sm'
                      variant='outline'
                      color='primary'
                      size='xs'
                      leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
                      onClick={editSection(8)}
                    >
                      Edit Details
                    </Button>
                  </Box>
                </>
              )}
            </CSSGrid>
          </Box>
        </Box>
      </CSSGrid>
      <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto' gap='sm'>
        <Button mt='md' onClick={toDashboard} color='secondary'>
          Dashboard
        </Button>
        <Button mt='md' color='primary'>
          Submit and continue
        </Button>
      </CSSGrid>
    </>
  )
}

export default ReviewApplication

// for lazy loading via react-router-dom
export const Component: React.FC = ReviewApplication
Component.displayName = 'OnboardingReviewApplicationComponent'

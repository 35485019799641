import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { SessionContext } from '../../contexts/SessionContext'
import { Transaction, TransactionFilters } from '../../utils/interfaces'
import { delayEmulation } from '../config'
import dayjs from 'dayjs'

export const fetchTransactions = async (transactions: Transaction[], filters?: TransactionFilters) => {
  return new Promise<Transaction[]>((resolve, reject) => {
    delayEmulation()
      .then(() => {
        let filteredTransaction = transactions ?? []

        if (filters) {
          if (filters.searchString && filters.searchString !== '') {
            const searchString = filters.searchString.toLowerCase()
            filteredTransaction = filteredTransaction.filter(
              (transaction) =>
                transaction.counterparty.account_holder_name.toLowerCase().indexOf(searchString) > -1 ||
                transaction.reference.toLowerCase().indexOf(searchString) > -1
            )
          }
          if (filters.fromDate && filters.toDate) {
            const parsedFromDate = dayjs(filters.fromDate)
            const parsedToDate = dayjs(filters.toDate)

            if (parsedFromDate.isValid() && parsedToDate.isValid()) {
              filteredTransaction = filteredTransaction.filter((transaction) => {
                const createdAt = dayjs(transaction.created_at)
                return (
                  (createdAt.isSame(parsedFromDate, 'D') || createdAt.isAfter(parsedFromDate, 'D')) &&
                  (createdAt.isSame(parsedToDate, 'D') || createdAt.isBefore(parsedToDate, 'D'))
                )
              })
            }
          }
          if (filters.fromAmount !== undefined && filters.fromAmount !== '') {
            filteredTransaction = filteredTransaction.filter((transaction) => Math.abs(transaction.amount) >= (filters.fromAmount as number))
          }
          if (filters.toAmount !== undefined && filters.toAmount !== '') {
            filteredTransaction = filteredTransaction.filter((transaction) => Math.abs(transaction.amount) <= (filters.toAmount as number))
          }
        }

        resolve(filteredTransaction)
      })
      .catch(() => reject(new Error()))
  })
}

export default function useFetchTransactions(filters?: TransactionFilters) {
  const { session } = useContext(SessionContext)

  return useQuery({
    queryKey: ['transactions', session.transactions, filters],
    queryFn: () => fetchTransactions(session.transactions, filters),
  })
}

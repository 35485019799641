import { Flex, getThemeColor, Text } from '@mantine/core'

export const DescriptionBox: React.FunctionComponent<
  React.PropsWithChildren<{
    bg?: string
  }>
> = ({ bg = 'primary', children }) => (
  <Flex gap='1rem' align='center'>
    <Flex
      bg={bg}
      c='white'
      h='3.4rem'
      direction='column'
      justify='center'
      pos='relative'
      sx={(theme) => ({
        borderRadius: '6px',
        flexShrink: 0,
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 'calc(50% - 0.625rem)',
          right: '-1rem',
          height: '1.25rem',
          width: '1.25rem',
          border: '0.625rem solid transparent',
          borderLeft: `0.625rem solid ${getThemeColor(bg, theme)}`,
        },
      })}
    ></Flex>
    <Text fz='sm' lh={1.25}>
      {children}
    </Text>
  </Flex>
)

import { ApiResponse } from './interface'

// NOTE: Only to be used when an actual response is returned
export const fetchWithErrorHandling = async <T>(url: string, options: RequestInit = {}): Promise<T | null> => {
  const response = await fetch(url, options)

  const result = (await response.json()) as ApiResponse<T>

  if (!result.isSuccess) {
    throw result.errors![0]
  }

  return result.data as T
}

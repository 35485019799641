import { IdentityDocumentType } from '../types/onboarding'

export default (enumTypes: IdentityDocumentType[]) =>
  enumTypes.map((enumType) => {
    if (enumType.toUpperCase() === 'ID') {
      return { label: enumType, value: enumType }
    } else {
      const formattedLabel = enumType
        .split('_')
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
        .join(' ')
      return { label: formattedLabel, value: enumType }
    }
  })

import { Container, Loader, Paper, Stepper } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useCounter } from '@mantine/hooks'
import { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import useFetchBeneficiary from '../../../../api/queries/useFetchBeneficiary'
import { Beneficiary, Currency, PaymentType } from '../../../../utils/interfaces'
import BeneficiaryForm from './BeneficiaryForm'
import FinaliseBeneficiary from './FinaliseBeneficiary'

const EditBeneficiary: React.FC = () => {
  const { beneficiaryId = '' } = useParams()

  const { isLoading, data: beneficiary } = useFetchBeneficiary(beneficiaryId)

  const [step, handlers] = useCounter(1, { min: 1, max: 2 })

  const beneficiaryForm = useForm<Omit<Beneficiary, 'entity_type'> & { entity_type: '' | 'individual' | 'corporate' }>({
    initialValues: beneficiary || {
      id: '',
      name: '',
      alias: '',
      payment_type: PaymentType.LEIKUR,
      entity_type: '',
      currency: Currency.EUR,
      email: '',
      status: 'active',
      account_details: {
        type: PaymentType.LEIKUR,
        email: '',
        iban: '',
      },
      default_payment: {
        amount: 0,
        popEmail: '',
        popMobileNumber: undefined,
        documents: [],
      },
      country: '',
      reference: '',
    },

    // functions will be used to validate values at corresponding key
    validate: {
      email: (value: string) => (value.length === 0 ? 'Please enter an email' : null),
      alias: (value: string) => (value.length === 0 ? 'Please enter an alias' : null),
    },
  })

  useEffect(() => {
    if (beneficiary) {
      beneficiaryForm.setValues(beneficiary)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiary])

  if (isLoading) {
    return (
      <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader />
      </Container>
    )
  }

  if (beneficiary === null || beneficiary === undefined) {
    return <Navigate to='/beneficiary-centre' />
  }

  return (
    <Container my='lg' py={{ base: 'xl', desktop: '3xl' }} sx={{ isolation: 'isolate' }}>
      <Paper p={{ base: '2xl', desktop: '3xl' }} mx={{ desktop: '96px' }}>
        <Stepper
          active={step}
          onStepClick={handlers.set}
          allowNextStepsSelect={false}
          // breakpoint='desktop' // This prop is not available in the latest version of Mantine, use StylesAPI
          color='primary'
          contentPadding='3xl'
          size='sm'
          styles={(_theme, _, u) => ({
            steps: { [u.largerThan('desktop')]: { marginInline: '1rem' } },
          })}
        >
          <Stepper.Step allowStepClick={false} c='primary' label='Select beneficiary' description='Choose a beneficiary from the list'></Stepper.Step>
          <Stepper.Step c='primary' label='Beneficiary details' description='Edit the details'>
            <BeneficiaryForm back={handlers.decrement} next={handlers.increment} beneficiaryForm={beneficiaryForm as never} editMode={true} />
          </Stepper.Step>
          <Stepper.Step c='primary' label='Finalise' description='Review and save'>
            <FinaliseBeneficiary back={handlers.decrement} beneficiaryForm={beneficiaryForm as never} editing={true} />
          </Stepper.Step>
        </Stepper>
      </Paper>
    </Container>
  )
}

export default EditBeneficiary

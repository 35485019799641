import { Flex, Group, Radio, Select, SimpleGrid, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { getSelectPropsForCountry } from '../../../../components/inputs/helper'
import { IdentityDocumentType, Person } from '../../../../types/onboarding'
import getIdentificationTypesAsLabels from '../../../../utils/getIdentificationTypesAsLabels'

const identityDocumentLabels = getIdentificationTypesAsLabels(Object.values(IdentityDocumentType))

interface IDDocumentDetailsProps {
  form: ReturnType<typeof useForm<Person>>
}

const IDDocumentDetails: React.FC<IDDocumentDetailsProps> = ({ form }) => (
  <>
    <Text size='lg' fw={500} c='secondary' mt='xl' mb='md'>
      Document Details
    </Text>
    <SimpleGrid cols={{ base: 1, md: 2 }} style={{ columnGap: 'var(--mantine-spacing-xl)', rowGap: 'var(--mantine-spacing-sm)' }}>
      <Radio.Group
        labelProps={{ c: 'secondary' }}
        label='Type of ID submitted'
        {...form.getInputProps('identityDocument.type')}
        onChange={(value: string) => {
          if ((value as IdentityDocumentType) === IdentityDocumentType.RESIDENT_PERMIT) {
            form.setFieldValue('identityDocument.issuingCountryCode', 'LT')
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          form.getInputProps('identityDocument.type').onChange(value)
        }}
      >
        <Group mt='md' gap='md' mb={form.getInputProps('identityDocument.type').error ? 'sm' : 0}>
          {identityDocumentLabels.map(({ label, value: idValue }) => (
            <Radio
              label={label}
              sx={{
                label: { color: 'var(--mantine-color-dark-4)', fontWeight: 500 },
              }}
              value={idValue}
              key={label}
            />
          ))}
        </Group>
      </Radio.Group>

      <TextInput labelProps={{ c: 'secondary' }} label='ID number' placeholder='Enter ID number' mb='md' {...form.getInputProps('identityDocument.number')} />
      <Select
        label='ID issue country'
        labelProps={{ c: 'secondary' }}
        placeholder='Select issue country'
        searchable
        clearable
        disabled={form.values.identityDocument?.type === IdentityDocumentType.RESIDENT_PERMIT && form.values.identityDocument?.issuingCountryCode === 'LT'}
        {...getSelectPropsForCountry(form.values.identityDocument?.issuingCountryCode || null)}
        {...form.getInputProps('identityDocument.issuingCountryCode')}
      />
      <Flex gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='ID issue date'
          placeholder='Enter ID issue date'
          type='date'
          w={{ base: '100%', desktop: '50%' }}
          {...form.getInputProps('identityDocument.issueDate')}
        />
        <TextInput
          labelProps={{ c: 'secondary' }}
          label='ID expiry date'
          placeholder='Enter ID expiry date'
          type='date'
          w={{ base: '100%', desktop: '50%' }}
          {...form.getInputProps('identityDocument.expiryDate')}
        />
      </Flex>
    </SimpleGrid>
  </>
)

export default IDDocumentDetails

import { ActionIcon, Box, BoxProps, Button, ButtonProps, FileButton, Flex, getThemeColor, Input, useMantineTheme } from '@mantine/core'
import { acceptedDocumentTypes } from '../utils/constants'
import { IconCloseCross, IconPlus } from './icons'
import { colorAliases } from '../theme/mantineTheme'

const FileUpload: React.FC<
  {
    value?: File[]
    onChange: React.Dispatch<React.SetStateAction<File[]>>
    buttonAlignment?: 'center' | 'start' | 'end'
    buttonProps?: ButtonProps
    label?: React.ReactNode
    buttonText?: React.ReactNode
  } & BoxProps
> = ({ value, onChange, buttonAlignment = 'start', buttonText = 'Upload document', label, buttonProps, ...boxProps }) => {
  const theme = useMantineTheme()

  const appendFiles = (files: File[]) => {
    const newList = value ?? []
    for (let i = 0; i < files.length; i++) {
      const found = newList.find((v) => v.name === files[i].name)
      if (found === undefined) {
        newList.push(files[i])
      }
    }

    onChange(newList)
  }

  const removeFile = (targetFile: File) => {
    if (value) {
      const newList = value.filter((file) => file.name !== targetFile.name)
      onChange(newList)
    }
  }

  return (
    <Box fz='sm' c='textPrimary' {...boxProps}>
      {label && (
        <Input.Label c='secondary' mb='md'>
          {label}
        </Input.Label>
      )}
      {value?.map((file) => (
        <Flex
          key={file.name}
          p='xs'
          bg='background.1'
          mb='xs'
          align='center'
          sx={(theme) => ({
            border: `1pt solid ${getThemeColor('background', theme)}`,
            borderRadius: theme.radius['sm'],
          })}
        >
          <Box w='100%'>{file.name}</Box>
          <ActionIcon onClick={() => removeFile(file)} color='error' size='sm' p={5}>
            <IconCloseCross />
          </ActionIcon>
        </Flex>
      ))}
      <Flex w='100%' align='center' justify={buttonAlignment}>
        <FileButton onChange={appendFiles} accept={acceptedDocumentTypes} multiple>
          {(props) => (
            <Button
              {...props}
              size='xs'
              h='2.1rem'
              c={colorAliases.textInvert}
              color='dark'
              variant='outline'
              leftSection={
                <IconPlus height={30} width={30} viewBox='0 0 24 24' style={{ color: getThemeColor(colorAliases.dark500, theme), height: '1.25rem' }} />
              }
              {...buttonProps}
            >
              {buttonText}
            </Button>
          )}
        </FileButton>
      </Flex>
    </Box>
  )
}

export default FileUpload

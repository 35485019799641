import { ActionIcon, Button, Chip, createTheme, Input, InputWrapper, NumberInput, Paper, PasswordInput, rem, Select, Textarea, TextInput } from '@mantine/core'

export const colorAliases = {
  blue300: 'primary.0',
  blue500: 'primary.2',
  blue700: 'primary',
  blue900: 'primary.6',
  blue1000: 'primary.7',
  brandPrimary: 'primary',
  brandSecondary: 'secondary',
  brandSecondaryLight: 'secondary.3',
  brandGray: '#8A8A8D',
  dark500: 'dark.0',
  dark700: 'dark.2',
  dark900: 'dark',
  green300: 'secondary.0',
  green500: 'secondary.2',
  green700: 'secondary',
  green900: 'secondary.6',
  green1000: 'secondary.7',
  light500: 'light.0',
  light50: '#767c80',
  light700: 'light',
  light900: 'light.7',
  surfaceDark: 'dark',
  surfaceDarkgray: 'dark.2',
  surfaceLight: 'light',
  surfaceLightgray: 'light.7',
  surfaceBrand: 'secondary.6',
  surfaceSilver: 'dark.0',
  surfaceSemiWhite: 'light.0',
  systemAlert: 'alert',
  systemError: 'negative',
  systemIcon: 'secondary',
  systemSuccess: 'positive',
  textAccent: 'primary.6',
  textInvert: 'dark',
  textInvertSecondary: 'dark.2',
  textLink: 'primary.6',
  textPrimaryInvert: 'light.0',
  textSecondaryInvert: 'light.7',
  borderSubtle: 'transparentBlack.3',
  borderDefault: 'transparentBlack.5',
  borderHighlight: 'transparentBlack.8',
  warning: '#eac24b',
}

export default createTheme({
  primaryShade: 4, // index used for default color shade in theme.colors. i.e. theme.colors.primary[4] = 'primary.4' = 'primary'
  primaryColor: 'primary',
  fontFamily: '"Roboto", sans-serif',
  defaultRadius: 'xs',
  cursorType: 'pointer',

  // Default loader used in Loader and LoadingOverlay components
  // loader: 'bars', // configure as default props for Loader component

  white: '#ffffff', // lightest light from colors below
  black: '#0d0d10', // darkest dark from colors below

  lineHeights: {
    xs: '1.5',
    sm: '1.5',
    md: '1.5',
    lg: '1.5',
    xl: '1.5',
  },

  colors: {
    primary: [
      '#AFAEFF', // primary.0 -> blue300
      '#9795fc',
      '#6d6aed', // primary.2 -> blue500
      '#5653c9',
      '#4845E9', // default primary -> blue700, brand/primary
      '#403e97',
      '#3532D0', // primary.6 -> blue900, Text-accent, Text-Link
      '#19179C', // primary.7 -> blue1000
      '#14127d',
      '#0f0e5e',
    ],

    secondary: [
      '#ACDEFF', // secondary.0 -> green300 #ACDEFF
      '#82adca',
      '#69AEDC', // secondary.2 -> green500 #69AEDC
      '#5e96bb',
      '#4C8AB3', // default secondary -> green700, brand/secondary
      '#447ca1',
      '#356C91', // secondary.6 -> green900, surface-brand
      '#204963', // secondary.7 -> green1000
      '#1e3748',
      '#0f1c24',
    ],

    dark: [
      '#3C3C4B', // dark.0 -> dark500, surface-silver
      '#30303E',
      '#292934', // dark.2 -> dark700, surface-darkgray, Text-Invert-secondary
      '#1B1B22',
      '#16161C', // default dark -> dark900, surface-dark, Text-Invert
      '#111116',
      '#0D0D11',
      '#0A0A0D',
      '#060608',
      '#050506',
    ],

    light: [
      '#ffffff', // white, light.0 -> light500, surface-semiwhite, Text-primary-invert
      '#f9fbfd',
      '#f5fafc',
      '#f2f8fb',
      '#EFF6FA', // default light -> light700, surface-light
      '#e6eef2',
      '#dee8ed',
      '#d6e2e9', // light.7 => light900, surface-lightgray, text-secondary-invert
      '#c1cbd2',
      '#abb5ba',
    ],
    positive: [
      '#aee484',
      '#9ede6b',
      '#8ed953',
      '#7dd33a',
      '#5dc809', // default
      '#54b408',
      '#418c06',
      '#387805',
      '#2f6405',
      '#255004',
    ],
    negative: [
      '#fab1a2',
      '#f9a493',
      '#f89783',
      '#f78a74',
      '#f67d64', // default
      '#c56450',
      '#ac5846',
      '#944b3c',
      '#7b3f32',
      '#623228',
    ],
    alert: [
      '#f9e998',
      '#f8e687',
      '#f7e275',
      '#f6df64',
      '#f5db53', // default
      '#ddc54b',
      '#c4af42',
      '#ac993a',
      '#938332',
      '#7b6e2a',
    ],

    grey: [
      '#F8F8F8', // gray.0 -> lightest gray
      '#E0E0E0', // gray.1
      '#C8C8C8', // gray.2
      '#B0B0B0', // gray.3
      '#989898', // gray.4
      '#808080', // gray.5
      '#7B7B7B', // gray.6 -> default gray, brand/primary
      '#606060', // gray.7
      '#484848', // gray.8
      '#303030', // gray.9
      '#181818', // gray.10 -> darkest gray
    ],

    transparentBlack: [
      'rgba(0,0,0,0.05)',
      'rgba(0,0,0,0.1)',
      'rgba(0,0,0,0.15)',
      'rgba(0,0,0,0.2)', // light15%!!, transparentBlack.3 -> borderSubtle
      'rgba(0,0,0,0.25)',
      'rgba(0,0,0,0.3)', // light30%, transparentBlack.5 -> borderDefault
      'rgba(0,0,0,0.35)',
      'rgba(0,0,0,0.4)',
      'rgba(0,0,0,0.45)', // light50%!!, transparentBlack.8 -> borderHighlight
      'rgba(0,0,0,0.5)',
    ],
  },

  breakpoints: {
    tablet: '25.75em', // 412px
    // xs: '36em', // default mantine value
    // sm: '48em', // default mantine value
    // md: '62em', // default mantine value
    desktop: '62em', // 992px
    // lg: '75em', // default mantine value
    wide: '75em', // 1200px
    // xl: '88em', // default mantine value
  },

  fontSizes: {
    '3xs': '0.5rem',
    '2xs': '0.625rem',
    // xs: '0.75rem', // default theme values
    // sm: '0.875rem', // default theme values
    // md: '1rem' // default theme values
    // lg: '1.125rem', // default theme values
    // xl: '1.25rem', // default theme values
    '2xl': '1.5rem',
    '3xl': '1.75rem',
  },

  radius: {
    '3xs': '1px',
    '2xs': '2px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
  },

  spacing: {
    '4xs': '2px',
    '3xs': '4px', // prev + 2px
    '2xs': '6px', // + 2px
    // xs: '8px', // + 2px, default mantine value
    // sm: '12px', // +4px, default mantine value
    // md: '16px', // +4px, default mantine value
    // lg: '20px', // +4px, default mantine value
    // xl: '24px', // +4px, default mantine value
    '2xl': '32px', // +8px
    '3xl': '40px', // +8px
    '4xl': '48px', // +8px
  },

  headings: {
    fontFamily: '"Object Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    fontWeight: '400',
    sizes: {
      h1: { fontSize: '2.5rem', lineHeight: '1.3', fontWeight: '400' },
      h2: { fontSize: '2rem', lineHeight: '1.3', fontWeight: '400' },
      h3: { fontSize: '1.5rem', lineHeight: '1.3', fontWeight: '400' },
      h4: { fontSize: '1.25rem', lineHeight: '1.3', fontWeight: '400' }, // use as subtitle
    },
  },

  components: {
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      styles: {
        label: {
          color: 'var(--mantine-color-primary-4)',
          fontWeight: 'bold',
          marginBottom: '0.4rem',
          marginTop: '0.4rem',
        },
        error: {
          marginTop: '0.125rem',
        },
      },
    }),
    Input: Input.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
          '&[data-error="true"]': {
            color: 'var(--mantine-color-negative-4)',
          },
        },
      },
    }),
    Textarea: Textarea.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
        },
      },
    }),
    PasswordInput: PasswordInput.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
        },
      },
    }),
    TextInput: TextInput.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
        },
      },
    }),
    NumberInput: NumberInput.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
        },
      },
    }),
    Select: Select.extend({
      styles: {
        input: {
          border: '1px solid var(--mantine-color-transparentBlack-3)',
          borderRadius: 'var(--mantine-radius-sm)',
          background: 'var(--mantine-color-light-0)',
        },
        option: {
          color: 'var(--mantine-color-dark-4)',
          '&[data-selected]': {
            '&, &:hover': {
              backgroundColor: 'var(--mantine-color-primary-4)',
              color: 'var(--mantine-color-light-0)',
            },
          },
          '&[data-hovered]': {
            backgroundColor: 'var(--mantine-color-primary-4)',
            color: 'var(--mantine-color-light-0)',
          },
        },
      },
    }),

    Paper: Paper.extend({
      defaultProps: { radius: 'md' },
      styles: {
        root: {
          backgroundColor: 'var(--mantine-color-light-0)',
        },
      },
    }),

    Button: Button.extend({
      styles: {
        label: {
          fontFamily: '"Object Sans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
          fontWeight: 400,
        },
        root: {
          '&[data-size="small"]': {
            height: '2.25rem',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          '&[data-size="medium"]': {
            height: '3rem',
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
          },
          '&[data-size="large"]': {
            height: '3.625rem',
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
          },

          '&[data-variant="subtle"]': {},
          '&[data-variant="leikur-light"]': {
            border: `${rem(1)} solid ${colorAliases.borderSubtle}`,
            backgroundColor: colorAliases.surfaceLight,
            color: colorAliases.textAccent,
            textAlign: 'left',
          },
          '&[data-variant="leikur-dark"]': {
            border: `${rem(1)} solid ${colorAliases.borderSubtle}`,
            backgroundColor: colorAliases.surfaceLight,
            color: colorAliases.textAccent,
            display: 'flex',
            textAlign: 'left',
          },
        },
        section: {
          '[data-variant="leikur-dark"] &': {
            display: 'flex',
            height: '100%',
            aspectRatio: '1',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 'var(--mantine-radius-xs)',
            backgroundColor: 'var(--mantine-color-secondary-4)',
            color: 'var(--mantine-color-light-1)',
          },
          '[data-variant="leikur-light"] &': {
            display: 'flex',
            height: '100%',
            aspectRatio: '1',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(0,0,0,0.2)',
            borderRadius: 'var(--mantine-radius-xs)',
            color: 'var(--mantine-color-dark-6)',
          },
        },
      },
      // TODO: add custom button variants
    }),
    ActionIcon: ActionIcon.extend({
      styles: {
        root: {
          borderWidth: '2pt',
          '&[data-variant="outline"]': {
            '--ai-size-xs': 'calc(1.875rem * var(--mantine-scale))',
            '--ai-size-sm': 'calc(2.25rem * var(--mantine-scale))',
            '--ai-size-md': 'calc(2.625rem * var(--mantine-scale))',
            '--ai-size-lg': 'calc(3.125rem * var(--mantine-scale))',
            '--ai-size-xl': 'calc(3.75rem * var(--mantine-scale))',
          },
        },
      },
    }),
    Chip: Chip.extend({
      styles: { input: { position: 'absolute' }, label: { paddingTop: '1rem', paddingBottom: '1rem' } },
    }),
  },
})

import { Flex, Button, Loader, TextInput } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import { useEffect } from 'react'
import useEditAccountGroup from '../../api/queries/useEditAccountGroup'
import { EditAccountGroupRequest } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { IconCloseCross, IconDownArrow } from '../icons'
import ModalWrapper from './ModalWrapper'
import { colorAliases } from '../../theme/mantineTheme'
import setCustomInputColorStyles from '../../theme/setCustomInputColorStyles'

const AccountGroupAliasUpdate = ({ context, id, innerProps }: ContextModalProps<{ alias: string; iban: string; themeColor?: string }>) => {
  const { mutate: editAccount, isLoading: isEditLoading, isSuccess } = useEditAccountGroup()

  const form = useForm<EditAccountGroupRequest>({
    initialValues: {
      iban: innerProps.iban,
      alias: innerProps.alias,
    },
    validate: {
      alias: isNotEmpty('Please enter an alias'),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const sendEditAccountRequest = () => {
    form.validate()
    if (form.isValid()) {
      editAccount({
        ...form.values,
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      context.closeModal(id)
    }
  }, [isSuccess, id, context])

  if (isEditLoading || isSuccess) {
    return (
      <ModalWrapper title='Edit account alias' themeColor={innerProps.themeColor} onClose={() => context.closeModal(id)}>
        <CSSGrid py='xl' gap='md' alignContent='center' justifyItems='center' p='sm'>
          <Loader color={innerProps.themeColor ?? 'primary'} />
        </CSSGrid>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper title='Edit account alias' themeColor={innerProps.themeColor} onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md' p='sm'>
        <TextInput
          label='Alias'
          description='Enter the alias for the account'
          variant='filled'
          styles={setCustomInputColorStyles(innerProps.themeColor)}
          {...form.getInputProps('alias')}
        />
        <Flex align='center' justify='right' gap='sm'>
          <Button
            w={125}
            rightSection={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            radius='xs'
            color={colorAliases.borderSubtle}
            c={colorAliases.textInvert}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>

          <Button
            w={125}
            radius='xs'
            leftSection={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='primary'
            onClick={sendEditAccountRequest}
          >
            Confirm
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForAccountGroupAliasUpdateProps = {
  iban: string
  currentAlias: string
  themeColor?: string
  onClose?: () => void
}
export function openModalForAccountGroupAliasUpdate({ iban, currentAlias, themeColor = 'primary', onClose }: OpenModalForAccountGroupAliasUpdateProps) {
  return () =>
    modals.openContextModal({
      modal: 'accountGroupAliasUpdate',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        iban,
        alias: currentAlias,
        themeColor,
      },
    })
}

export default AccountGroupAliasUpdate

import { Box, Button, Flex, Paper, Text, Title } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { IconBeneficiaryAddNew } from '../../../../components/icons'
import { Beneficiary as BeneficiaryInterface } from '../../../../utils/interfaces'
import Beneficiary from './Beneficiary'

const BeneficiaryCentreList: React.FC<
  {
    beneficiaries: BeneficiaryInterface[]
  } & React.ComponentPropsWithoutRef<'div'>
> = ({ beneficiaries }) => {
  const navigate = useNavigate()

  return (
    <Paper p='lg' w='100%'>
      <Flex justify='space-between' align='center'>
        <Title order={2} fz='1.125rem' lh='1.1' mb='md'>
          Beneficiaries
        </Title>
        <Flex gap='1rem'>
          <Button
            display={{ desktop: 'none' }}
            variant='subtle'
            leftSection={<IconBeneficiaryAddNew height='1.25em' />}
            onClick={() => navigate('/beneficiary-centre/manage/add')}
          >
            Add
          </Button>
        </Flex>
      </Flex>
      <Box>
        {beneficiaries && beneficiaries.length > 0 ? (
          beneficiaries.map((b, i) => (
            <Beneficiary
              beneficiary={b}
              isLastInGroup={i === beneficiaries.length - 1}
              key={b.id}
              handleFormShow={() => navigate(`/beneficiary-centre/manage/edit/${b.id}`, { state: { beneficiary: b } })}
            />
          ))
        ) : (
          <Flex justify='center' align='center' p='xl' pb={0}>
            <Text fz={{ base: 'xl', desktop: 'xl' }} fw='bold' color='secondary' ta='center'>
              No Beneficiaries found. <br /> Try clearing the filters or adding a new beneficiary.
            </Text>
          </Flex>
        )}
      </Box>
    </Paper>
  )
}

export default BeneficiaryCentreList

import { useQuery, useMutation } from '@tanstack/react-query'
import { fetchWithErrorHandling } from '@/api/apiBase'
import { ApiError } from '../interface'
import { BusinessDetailsRequest, BusinessDetailsResponse } from './types'
import { OnboardingInfoResponse, SubmitOnboardingInfoRequest } from './interface'

export const useValidateOrgDetails = (invitationCode: string, options = {}) => {
  return useQuery<BusinessDetailsResponse | null, ApiError>(
    ['validateOrgDetails', invitationCode],
    async () =>
      fetchWithErrorHandling<BusinessDetailsResponse>(
        `${process.env.LEIKUR_API}/api/org/onboarding/invite/validate?invitationCode=${invitationCode}`
      ),
    {
      retry: 1,
      staleTime: 1000 * 60 * 5,
      enabled: !!invitationCode,
      ...options,
      onError: (error) => {
        console.error(`Validate Org Details error: ${error.code} - ${error.description}`)
      },
    }
  )
}

export const useSubmitOrgSignUp = () => {
  return useMutation<
    BusinessDetailsResponse | null,
    ApiError,
    { invitationCode: string; request: BusinessDetailsRequest }
  >(
    ({ invitationCode, request }) =>
      fetchWithErrorHandling<BusinessDetailsResponse>(`${process.env.LEIKUR_API}/api/org/signup/${invitationCode}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
      }),
    {
      onSuccess: (data) => {
        console.log('Org signup details submitted successfully:', data)
      },
      onError: (error) => {
        console.error(`Org Signup error: ${error.code} - ${error.description}`)
      },
    }
  )
}

export const useGetOnboardingDetails = (invitationCode: string, options = {}) => {
  return useQuery<OnboardingInfoResponse | null, ApiError>(
    ['onboardingDetails', invitationCode],
    async () =>
      fetchWithErrorHandling<OnboardingInfoResponse>(`${process.env.LEIKUR_API}/api/org/onboarding/${invitationCode}`),
    {
      retry: 1,
      enabled: !!invitationCode,
      ...options,
      onError: (error) => {
        console.error(`Get Onboarding Details error: ${error.code} - ${error.description}`)
        return error
      },
    }
  )
}

export const useSubmitPartialOnboardingDetails = () => {
  return useMutation<
    OnboardingInfoResponse | null,
    ApiError,
    { invitationCode: string; onboardingDetails: SubmitOnboardingInfoRequest }
  >(
    ({ invitationCode, onboardingDetails }) =>
      fetchWithErrorHandling<OnboardingInfoResponse>(`${process.env.LEIKUR_API}/api/org/onboarding/${invitationCode}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(onboardingDetails),
      }),
    {
      onSuccess: (data) => {
        console.log('Partial onboarding details submitted successfully:', data)
      },
      onError: (error) => {
        console.error(`Submit Partial Onboarding Details error: ${error.code} - ${error.description}`)
      },
    }
  )
}

import { Anchor, Box, Breadcrumbs, Button, Group, Paper } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { IconArrowRight } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'

const DataProtection = () => {
  const { invitationCode } = useParams()

  const { form, saveForm } = useOnboardingForm()

  const handleAccept = () => {
    form.setFieldValue('hasAcceptedPrivacyPolicy', true)
    saveForm()
  }

  return (
    <Paper p={{ base: '3xs', desktop: 'lg' }}>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href={`/onboarding/overview/${invitationCode}`} c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 8 | Data Protection</Box>
      </Breadcrumbs>

      <Box py={{ base: 'lg', desktop: 'lg' }} lh='1.5' c='textPrimary'>
        Leikur respects your privacy and will process your personal data in accordance with the GDPR (General Data
        Protection Regulation) and other applicable legal acts.
        <br />
        <br />
        Please read our <Anchor>Privacy Policy</Anchor>.
        <Group justify='space-between'>
          <Button size='xs' mt='md' color='dark'>
            Back
          </Button>
          <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={handleAccept}>
            Accept and continue
          </Button>
        </Group>
      </Box>
    </Paper>
  )
}

export default DataProtection

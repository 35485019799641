import { Container, Loader } from '@mantine/core'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import useFetchAccountGroupsByIBAN from '../../../api/queries/useFetchAccountGroupsByIBAN'
import { CSSGrid } from '../../../components/common/CSSGrid'
import Instructions from '../../../components/common/Instructions'
import AccountsArea from './AccountsArea'
import TransactionsArea from './TransactionsArea'
import Welcome from './Welcome'

const StatementCentre = () => {
  const { groupId, accountId } = useParams()

  const [dismissHelp, setDismissHelp] = useState(false)
  const { isLoading, data: accountGroupsByIBAN } = useFetchAccountGroupsByIBAN()

  const group = groupId && accountGroupsByIBAN && Object.hasOwn(accountGroupsByIBAN, groupId) ? accountGroupsByIBAN[groupId] : undefined

  if (isLoading || accountGroupsByIBAN === undefined) {
    return (
      <Container display='flex' sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Loader color={group ? group.theme + 'GroupThemeAccent' : 'primary'} />
      </Container>
    )
  }

  return (
    <Container py={{ base: 'xl', desktop: '3xl' }} sx={{ isolation: 'isolate' }}>
      {!dismissHelp && groupId === undefined && (
        <Instructions
          instructions='Three easy steps to search, find and export past transactions'
          stepOne='Choose an account from the list to start'
          stepTwo='Use the filters to search, narrow and refine your statement history'
          stepThree='Export and save exactly the information you need for easy accounting'
          dismissHelp={() => setDismissHelp(true)}
        />
      )}
      <CSSGrid columnGap={{ base: 'xl' }} rowGap='xl' templateColumns={{ base: '1fr', desktop: group ? 'auto 1fr' : '1fr' }} alignItems='start'>
        {group === undefined && <Welcome dismissHelp={dismissHelp} showHelp={() => setDismissHelp(false)} />}
        {group && <AccountsArea group={group} accountId={accountId} />}
        {group && <TransactionsArea group={group} selectedAccountId={accountId} />}
      </CSSGrid>
    </Container>
  )
}

export default StatementCentre

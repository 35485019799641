import { Anchor, Box, Text, Breadcrumbs, Button, List } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import FileUpload from '../../../components/FileUpload'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { helpBorder } from '../onboardingUtils'
import { IconArrowRight } from '../../../components/icons'
import { colorAliases } from '../../../theme/mantineTheme'

const Documents = () => {
  const selectedStep = 9
  const navigate = useNavigate()

  const form = useForm<{
    idList: File[]
    anyOtherDocuments: File[]
    beneficialOwnershipDocument: File[]
    extractFromCompanyRegistry: File[]
    uboStructureChart: File[]
    memorandumArticleOfAssociation: File[]
  }>({
    initialValues: {
      idList: [] as File[],
      anyOtherDocuments: [] as File[],
      beneficialOwnershipDocument: [] as File[],
      extractFromCompanyRegistry: [] as File[],
      uboStructureChart: [] as File[],
      memorandumArticleOfAssociation: [] as File[],
    },
    validate: {},
  })

  // TODO: consider partial saving
  const saveChanges = (continueToNext = false) => {
    form.validate()
    if (form.isValid()) {
      console.log('save details:', form.values, form.isValid())
      // saveRequest({ ...form.values } as SaveStepFiveFinancialDetailsRequest)
      if (continueToNext) {
        navigate(continueToNext ? `/onboarding/step/${selectedStep + 1}` : '/onboarding')
      }
    }
  }
  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 9 | Documents</Box>
      </Breadcrumbs>

      <Box lh='1.55' fz='sm' p='md' pr='xl' pb={0} bg={colorAliases.surfaceLightgray} mb='md'>
        <Text c='secondary'>General note</Text>
        <List px='xs' py='md' fz='xs'>
          <List.Item>To make your onboarding as easy as possible, it&apos;s essential that you provide all the documents requested.</List.Item>
          <List.Item>We reserve the right at any time to request additional documents if they are needed.</List.Item>
          <List.Item>
            All documents must be provided in English or Lithuanian language. If this is not possible, we can only accept documents that have been
            professionally translated (certified copy of the original alongside the translation).
          </List.Item>
        </List>
      </Box>

      <CSSGrid gap='lg'>
        <Box lh='1.55' p='md' pr='xl' sx={helpBorder} bg={colorAliases.surfaceLightgray} mb='md'>
          <Box fz='sm' c='secondary' fw='bold' pr='1rem' lh='1.6' pb='sm'>
            ID verification for all UBOs, directors and other individuals listed on this application form NOT marked as Authorised Representatives
          </Box>
          <Box fz='xs' pr='1rem'>
            Verification for all such individuals is completed remotely. Please upload the soft copy of the below stated document for each individual listed on
            this application.
          </Box>
        </Box>
        <FileUpload {...form.getInputProps('idList')} label='Clear copy of passport, National ID or Residence Permit' />

        <FileUpload
          {...form.getInputProps('memorandumArticleOfAssociation')}
          label='Memorandum and Articles of Association (or the equivalent for the applicable jurisdiction)'
        />
        <FileUpload {...form.getInputProps('extractFromCompanyRegistry')} label='Dated extract from the company register, issued within the past 3 months' />
        <FileUpload {...form.getInputProps('beneficialOwnershipDocument')} label='Documents confirming the beneficial ownership' />
        <FileUpload {...form.getInputProps('uboStructureChart')} label='Detailed corporate chart clearly defining the Ultimate Beneficial Owner(s)' />
        <FileUpload {...form.getInputProps('anyOtherDocuments')} label='Any other documents you would like to add to support this application (Optional)' />
      </CSSGrid>

      <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto' gap='sm' mt='md'>
        <Button h='2.4rem' size='xs' mt='md' color='dark' onClick={() => navigate(`/onboarding/step/${selectedStep - 1}`)}>
          Previous
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(false)}>
          Save
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(true)}>
          Save and continue
        </Button>
      </CSSGrid>
    </>
  )
}

export default Documents

// for lazy loading via react-router-dom
export const Component: React.FC = Documents
Component.displayName = 'OnboardingDocumentsComponent'

import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'
import { PaymentRequestTypes } from '../utils/interfaces'
import Authenticator from './Authenticator'
import SignIn from './SignIn'
import BeneficiaryCentre from './authenticated/BeneficiaryCentre'
import AddBeneficiary from './authenticated/BeneficiaryCentre/manage/AddBeneficiary'
import EditBeneficiary from './authenticated/BeneficiaryCentre/manage/EditBeneficiary'
import StatementCentre from './authenticated/StatementCentre'
import InitiatePayments from './authenticated/TransactionCentre/InitiatePayments'
import ManagePayments from './authenticated/TransactionCentre/InitiatePayments/ManagePayments'
import PaymentApproval from './authenticated/TransactionCentre/PaymentApproval'
import PaymentRequests from './authenticated/TransactionCentre/PaymentRequests'
import AwaitingEmailVerification from './onboarding/signup/AwaitingEmailVerification'
import SignUp from './onboarding/signup/SignUp'
import { OnboardingFormProvider } from '../contexts/onboarding/OnboardingFormProvider'
import BasicDetails from '../routes/onboarding/step1/BasicDetails'
import ContactDetails from './onboarding/step2/ContactDetails'
import AdvancedDetails from './onboarding/step3/AdvancedDetails'
import RepresentativesDetails from './onboarding/step4/RepresentativeDetails'
import FinancialDetails from './onboarding/step5/FinancialDetails'
import TaxRegulations from './onboarding/step6/TaxRegulations'
import BusinessActivity from './onboarding/step7/BusinessActivity'
import DataProtection from './onboarding/step8/DataProtection'
import Documents from './onboarding/step9/Documents'
import IDVerification from './onboarding/step10/IDVerification'
import ReviewApplication from './onboarding/step11/ReviewApplication'
import { useDisclosure } from '@mantine/hooks'
import { AppShell } from '@mantine/core'
import AppHeader from '../components/layout/AppHeader'
import AppFooter from '../components/layout/AppFooter'
import AppNavbar from '../components/layout/AppNavbar'
import Dashboard from './authenticated/Dashboard'
import OnboardingOverview from './onboarding/Overview/OnboardingOverview'

const MainLayout = () => {
  const [opened, { toggle }] = useDisclosure()

  return (
    <AppShell>
      <AppShell.Main>
        <AppHeader toggleNavbar={toggle} />
        <Outlet />
        <AppFooter />
      </AppShell.Main>
      <AppNavbar opened={opened} onClose={toggle} />
    </AppShell>
  )
}

const browserRouter = createBrowserRouter([
  { path: '*', element: <Navigate replace to='/dashboard' /> },
  {
    element: <MainLayout />,
    children: [
      { path: '/sign-in', element: <SignIn /> },
      { path: '/onboarding/sign-up/:invitationCode', element: <SignUp /> },
      { path: '/onboarding/sign-up/awaiting-verification', element: <AwaitingEmailVerification /> }, //I dont see a need for this perhaps we can remove it?
      {
        path: '/onboarding',
        element: (
          <OnboardingFormProvider>
            <Outlet />
          </OnboardingFormProvider>
        ),
        children: [
          { path: 'overview/:invitationCode', element: <OnboardingOverview /> },
          { path: 'step/1/:invitationCode', element: <BasicDetails /> },
          { path: 'step/2/:invitationCode', element: <ContactDetails /> },
          { path: 'step/3/:invitationCode', element: <AdvancedDetails /> },
          { path: 'step/4/:invitationCode', element: <RepresentativesDetails /> },
          { path: 'step/5/:invitationCode', element: <FinancialDetails /> },
          { path: 'step/6/:invitationCode', element: <TaxRegulations /> },
          { path: 'step/7/:invitationCode', element: <BusinessActivity /> },
          { path: 'step/8/:invitationCode', element: <DataProtection /> },
          { path: 'step/9/:invitationCode', element: <Documents /> },
          { path: 'step/10/:invitationCode', element: <IDVerification /> },
          { path: 'step/11/:invitationCode', element: <ReviewApplication /> },
        ],
      },
      {
        element: <Authenticator />,
        children: [
          { path: '/dashboard', element: <Dashboard /> },
          { path: '/statement-centre', element: <StatementCentre /> },
          { path: '/statement-centre/:groupId', element: <StatementCentre /> },
          { path: '/statement-centre/:groupId/:accountId', element: <StatementCentre /> },
          { path: '/beneficiary-centre', element: <BeneficiaryCentre /> },
          { path: '/beneficiary-centre/manage/add', element: <AddBeneficiary /> },
          { path: '/beneficiary-centre/manage/edit/:beneficiaryId', element: <EditBeneficiary /> },
          { path: '/transaction-centre', element: <InitiatePayments /> },
          {
            path: '/transaction-centre/manage/new-request',
            element: (
              <ManagePayments requestType={PaymentRequestTypes.NEW_REQUEST} key={PaymentRequestTypes.NEW_REQUEST} />
            ),
          },
          {
            path: '/transaction-centre/manage/new-request/:templateId',
            element: (
              <ManagePayments
                requestType={PaymentRequestTypes.NEW_FROM_TEMPLATE}
                key={PaymentRequestTypes.NEW_FROM_TEMPLATE}
              />
            ),
          },
          {
            path: '/transaction-centre/manage/new-template',
            element: (
              <ManagePayments requestType={PaymentRequestTypes.NEW_TEMPLATE} key={PaymentRequestTypes.NEW_TEMPLATE} />
            ),
          },
          { path: '/transaction-centre/payment-approval', element: <PaymentApproval /> },
          { path: '/transaction-centre/payment-requests', element: <PaymentRequests /> },
        ],
      },
    ],
  },
])

export default browserRouter

import { Flex, Button, Textarea } from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import useUpdatePaymentRequest from '../../api/queries/useUpdatePaymentRequest'
import { PaymentRequest, PaymentRequestStatus } from '../../utils/interfaces'
import { CSSGrid } from '../common/CSSGrid'
import { IconCloseCross, IconDownArrow } from '../icons'
import ModalWrapper from './ModalWrapper'
import { colorAliases } from '../../theme/mantineTheme'

const PaymentRejectionModal = ({ context, id, innerProps: { paymentRequest } }: ContextModalProps<{ paymentRequest: PaymentRequest }>) => {
  const { mutate: updatePaymentRequest, isSuccess } = useUpdatePaymentRequest(() => context.closeModal(id))

  const form = useForm<{
    rejectReason: string
  }>({
    initialValues: {
      rejectReason: '',
    },
    validate: {
      rejectReason: isNotEmpty('Please enter a rejection reason for this payment request'),
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  const sendPaymentRejection = () => {
    form.validate()
    if (form.isValid()) {
      updatePaymentRequest({
        ...paymentRequest,
        status: PaymentRequestStatus.REJECTED,
        statusNote: form.values.rejectReason,
      })
      if (isSuccess) context.closeModal(id)
    }
  }

  return (
    <ModalWrapper title='Reject payment request' onClose={() => context.closeModal(id)}>
      <CSSGrid gap='md'>
        <Textarea
          label='Reason'
          description='Enter a reason for rejecting this request'
          variant='filled'
          labelProps={{ c: 'dark' }}
          {...form.getInputProps('rejectReason')}
        />
        <Flex align='center' justify='right' gap='sm' mt='md'>
          <Button
            w={125}
            radius='xs'
            rightSection={<IconCloseCross style={{ height: '0.75em', width: '0.75rem' }} />}
            variant='outline'
            c={colorAliases.textInvert}
            color={colorAliases.borderSubtle}
            onClick={() => context.closeModal(id)}
          >
            Cancel
          </Button>

          <Button
            radius='xs'
            leftSection={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
            variant='filled'
            color='primary'
            onClick={sendPaymentRejection}
          >
            Send
          </Button>
        </Flex>
      </CSSGrid>
    </ModalWrapper>
  )
}

type OpenModalForPaymentRejectionModalProps = {
  paymentRequest?: PaymentRequest
  onClose?: () => void
}
export function openModalForPaymentRejection({ paymentRequest, onClose }: OpenModalForPaymentRejectionModalProps = {}) {
  return () =>
    modals.openContextModal({
      modal: 'paymentRejectionModal',
      sx: { '.mantine-Modal-content': { overflow: 'visible !important' } },
      withCloseButton: false,
      onClose: onClose,
      size: 'lg',
      innerProps: {
        paymentRequest: paymentRequest,
      },
    })
}

export default PaymentRejectionModal

import { ActionIcon, Box, Center, Flex, getThemeColor, NumberInput, Text, TextInput } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import dayjs from 'dayjs'
import currencies from '../../../utils/currencies'
import { Beneficiary, IDefaultPaymentDocuments, PaymentBeneficiary, PaymentRequest } from '../../../utils/interfaces'
import FileUploadButton from '../../../components/buttons/FileButton'
import { IconCloseCross, IconDocChecked, IconTickMarkYes, IconViewInfoEye } from '../../../components/icons'
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumber/PhoneNumberInput'
import { CSSGrid, CSSGridProps } from '../../../components/common/CSSGrid'
import { Flag } from '../../../components/Flag'

const PaymentDetailsForm: React.FC<
  CSSGridProps & {
    beneficiary: Beneficiary & PaymentBeneficiary
    form: UseFormReturnType<PaymentRequest>
  }
> = ({ beneficiary, form, ...props }) => {
  const handleViewDocument = (documentUrl: string) => {
    window.open(documentUrl, '_blank')
  }

  const handleFileChange = (file: File | null) => {
    if (!file) {
      return
    }

    const newDocument: IDefaultPaymentDocuments = {
      document_id: crypto.randomUUID(),
      document_name: `${beneficiary?.alias || 'user'}-${file?.name}`,
      document_url: '/dummy/dummy-invoice.pdf',
      uploaded_at: dayjs().valueOf(),
    }

    form.insertListItem('beneficiaries.0.documents', newDocument)
  }

  const handleRemoveFile = (docIndex: number) => {
    // const currentDocuments = form.values.beneficiaries[0].documents || []

    // const documentToRemove = currentDocuments.find((document) => document.document_id === documentId)
    // if (documentToRemove) {
    // const updatedDocuments = currentDocuments.filter((document) => document.document_id !== documentId)
    // form.setFieldValue('beneficiaries.0.documents', updatedDocuments)
    form.removeListItem('beneficiaries.0.documents', docIndex)
    // }
  }

  return (
    <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} columnGap='3xl' rowGap='md' c='textPrimary' {...props}>
      <TextInput
        variant='filled'
        labelProps={{ c: 'secondary' }}
        type='text'
        label='Reference'
        description='Reference for this transaction'
        {...form.getInputProps('beneficiaries.0.reference')}
        sx={(_theme, u) => ({
          input: { fontSize: '0.8rem', [u.largerThan('desktop')]: { fontSize: '0.875rem' } },
        })}
        placeholder='A standard reference for this transaction'
      />
      <CSSGrid gap='md' rowGap='xl' templateColumns={{ base: '1fr', desktop: '8rem 1fr' }}>
        <TextInput
          variant='filled'
          labelProps={{ c: 'secondary' }}
          type='text'
          label='Currency'
          description='Beneficiary currency'
          disabled
          defaultValue={`(${currencies[form.values.currency].symbol}) ${form.values.currency} `}
          sx={(theme) => ({
            input: { ':disabled': { color: getThemeColor('textPrimary', theme), opacity: 1, fontWeight: 500 } },
          })}
          leftSection={
            <Flag
              countryCode={currencies[form.values.currency].countryCodeForFlag}
              square={true}
              style={{
                borderRadius: '50%',
                width: '1rem',
                height: '1rem',
                zIndex: 1,
              }}
            />
          }
        />
        <NumberInput
          variant='filled'
          labelProps={{ c: 'secondary' }}
          type='text'
          placeholder='Payment amount'
          label='Payment amount'
          description="The amount you'd like to send"
          decimalScale={2}
          hideControls
          min={0}
          {...form.getInputProps('beneficiaries.0.amount')}
        />
      </CSSGrid>

      <TextInput
        variant='filled'
        labelProps={{ c: 'secondary' }}
        sx={{ flex: 1 }}
        type='text'
        label='Proof of payment - Email (optional)'
        description='An email is sent to this address when this payment is made'
        {...form.getInputProps('beneficiaries.0.popEmail')}
        value={form.values.beneficiaries[0]?.popEmail ?? ''}
        placeholder='Email address'
      />
      <PhoneNumberInput
        textInputProps={{
          variant: 'filled',
          placeholder: 'Phone number',
        }}
        searchableSelectProps={{
          variant: 'filled',
          placeholder: 'Dialling code',
        }}
        labelProps={{ c: 'secondary' }}
        description='An sms is sent to this number when this payment is made'
        label='Proof of payment - Mobile (optional)'
        {...form.getInputProps('beneficiaries.0.popMobileNumber')}
      />
      <Box>
        <Text c='secondary' fw={700} fz='sm' mb='3xs'>
          Supporting documents
        </Text>
        <Center mb='xs'>
          <Box component={IconDocChecked} />
          <Text fz='xs' c='dark.2' ml='2xs'>
            Supporting documentation justifying payment (contract, receipt, invoice)
          </Text>
        </Center>
        {form.values.beneficiaries[0]?.documents?.map((doc, index) => (
          <CSSGrid templateColumns='auto 1fr auto auto' key={doc.document_id} mb='2xs' gap='2xs' alignItems='center'>
            <Box component={IconTickMarkYes} h='sm' c='success' ml={2} />
            <Text fz='xs' c='primary'>
              {doc.document_name}
            </Text>
            <ActionIcon size='sm' variant='transparent' color='primaryHighlight' onClick={() => handleViewDocument(doc.document_url)}>
              <IconViewInfoEye />
            </ActionIcon>
            <ActionIcon size='sm' variant='transparent' color='error' p={3}>
              <IconCloseCross onClick={() => handleRemoveFile(index)} />
            </ActionIcon>
          </CSSGrid>
        ))}
        <Flex justify='center' mt='lg'>
          <FileUploadButton handleFileChange={handleFileChange} />
        </Flex>
      </Box>
    </CSSGrid>
  )
}

export default PaymentDetailsForm

import {
  ElementProps,
  Factory,
  Group,
  GroupProps,
  Input,
  StylesApiProps,
  TextInput,
  TextInputProps,
  __BaseInputProps,
  __InputStylesNames,
  factory,
  useProps,
} from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import React, { ComponentProps, useState } from 'react'
import { SearchableSelect, SearchableSelectProps, usePropsForCountryDiallingCodeSelect } from '../SearchableSelect'
import { getCountry } from '../countries'
import { formatValidPhoneNumber, isValidPhoneNumber } from '../phone-number-validation'

export type InternationalPhoneNumber = {
  countryCode: string
  localPhoneNumber: string
  diallingCode: string
  isValid: boolean
  formattedPhoneNumber: string
}

export interface PhoneNumberInputProps
  extends __BaseInputProps,
    // eslint-disable-next-line no-use-before-define
    StylesApiProps<PhoneNumberInputFactory>,
    Omit<ElementProps<'input', 'size'>, 'onChange' | 'value' | 'onBlur' | 'defaultValue'> {
  /** Controlled input value */
  value?: Partial<InternationalPhoneNumber>
  defaultValue?: Partial<InternationalPhoneNumber>
  /** Controlled input onChange handler */
  onChange?: (value: InternationalPhoneNumber) => void
  inputRef?: React.ForwardedRef<HTMLInputElement>
  textInputProps?: Partial<TextInputProps>
  searchableSelectProps?: Partial<SearchableSelectProps>
  groupProps?: Partial<GroupProps>
  form?: ComponentProps<'input'>['form']
  onBlur?: (value: Partial<InternationalPhoneNumber>) => void
}

export type PhoneNumberInputFactory = Factory<{
  props: PhoneNumberInputProps
  ref: HTMLInputElement
  stylesNames: __InputStylesNames
}>

const defaultProps: Partial<PhoneNumberInputProps> = {}

export const PhoneNumberInput = factory<PhoneNumberInputFactory>((_props, ref) => {
  const { label, description, error, ...props } = useProps('PhoneNumberInput', defaultProps, _props)

  const [_value, handleChange] = useUncontrolled<InternationalPhoneNumber>({
    value: props.value?.isValid === true ? (props.value as InternationalPhoneNumber) : undefined,
    defaultValue: {
      countryCode: props.defaultValue?.countryCode || '',
      localPhoneNumber: props.defaultValue?.localPhoneNumber || '',
      diallingCode: props.defaultValue?.diallingCode || '',
      formattedPhoneNumber: props.defaultValue?.formattedPhoneNumber || '',
      isValid: props.defaultValue?.isValid || false,
    },
    onChange: (v) =>
      props.onChange &&
      props.onChange({
        ...v,
        countryCode: v.countryCode || '',
        localPhoneNumber: v.localPhoneNumber || '',
        diallingCode: v.diallingCode || '',
        formattedPhoneNumber: v.formattedPhoneNumber || '',
        isValid: v.isValid || false,
      }),
  })

  const _handleChange = ({ localPhoneNumber, countryCode }: Partial<InternationalPhoneNumber>) => {
    const lpn = localPhoneNumber !== undefined ? localPhoneNumber : _value.localPhoneNumber
    const cc = countryCode !== undefined || countryCode === '' ? countryCode : _value.countryCode
    const isValid = isValidPhoneNumber({ localPhoneNumber: lpn, countryCode: cc })
    handleChange({
      isValid,
      localPhoneNumber: lpn,
      countryCode: cc,
      diallingCode: cc !== undefined && cc !== '' ? getCountry(cc).phoneCode : '',
      formattedPhoneNumber: isValid ? formatValidPhoneNumber({ localPhoneNumber: lpn, countryCode: cc }) : '',
    })
  }

  const [blurredInput, _setBlurredInput] = useState({ countryCode: false, phoneNumber: false })

  const setBlurredInput = (key: string) => {
    const newState = { ...blurredInput, [key]: true }
    if (newState.phoneNumber && newState.countryCode && props.onBlur !== undefined) {
      props.onBlur(_value)
    }
    _setBlurredInput(newState)
  }

  const propsForCountryDiallingCodeSelect = usePropsForCountryDiallingCodeSelect()

  return (
    <Input.Wrapper
      inputWrapperOrder={['label', 'input', 'description', 'error']}
      ref={ref}
      {...props.inputWrapperOrder}
      {...props.wrapperProps}
      label={label}
      description={description}
      error={error}
    >
      <Group mb={description ? 5 : undefined} wrap='nowrap' {...props.groupProps}>
        <input ref={props.inputRef} type='hidden' value={_value?.formattedPhoneNumber || ''} form={props.form} disabled={props.disabled} />
        <SearchableSelect
          w={100}
          value={_value?.countryCode}
          onChange={(countryCode) => _handleChange({ countryCode })}
          disabled={props.disabled}
          onBlur={() => setBlurredInput('countryCode')}
          error={error !== undefined}
          comboboxProps={{ width: 'max-content', position: 'bottom-start' }}
          {...propsForCountryDiallingCodeSelect}
          {...props.searchableSelectProps}
        />
        <TextInput
          type='tel'
          value={_value?.localPhoneNumber}
          onChange={(e) => _handleChange({ localPhoneNumber: e.currentTarget.value })}
          disabled={props.disabled}
          // {...sharedInnerInputProps}
          {...props.textInputProps}
          style={{ flexGrow: 1, ...props.textInputProps?.style }}
          onBlur={() => setBlurredInput('phoneNumber')}
          error={error !== undefined}
        />
      </Group>
    </Input.Wrapper>
  )
})

PhoneNumberInput.displayName = 'PhoneNumberInput'

/**
 * Enhanced an `InternationalPhoneNumber` with default calculated values.
 * @param value
 * @returns
 */
export const getInternationalPhoneNumberWithDefaults = (value: Partial<InternationalPhoneNumber>) => {
  const isValid = isValidPhoneNumber(value)
  return {
    countryCode: value.countryCode !== undefined ? value.countryCode : null,
    localPhoneNumber: value.localPhoneNumber !== undefined ? value.localPhoneNumber : '',
    isValid,
    diallingCode: value.countryCode !== undefined && value.countryCode !== null ? getCountry(value.countryCode).phoneCode : undefined,
    formattedPhoneNumber: isValid ? formatValidPhoneNumber(value) : undefined,
  } as InternationalPhoneNumber
}

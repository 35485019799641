import { ActionIcon, Badge, Box, Button, Checkbox, Flex, getThemeColor, NumberInput, Paper, Select, Text, TextInput, Title, Transition } from '@mantine/core'
import { UseFormReturnType } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAddPaymentRequest from '../../../../../api/queries/useAddPayment'
import useAddPaymentTemplate from '../../../../../api/queries/useAddPaymentTemplate'
import useFetchBeneficiaries from '../../../../../api/queries/useFetchBeneficiaries'
import useUpdatePaymentTemplate from '../../../../../api/queries/useUpdatePaymentTemplate'
import FileUploadButton from '../../../../../components/buttons/FileButton'
import { CSSGrid } from '../../../../../components/common/CSSGrid'
import { IconCloseCross, IconCross, IconDocChecked, IconDownArrow, IconTickMarkYes, IconViewInfoEye } from '../../../../../components/icons'
import { PhoneNumberInput } from '../../../../../components/inputs/PhoneNumber'
import currencies from '../../../../../utils/currencies'
import { IDefaultPaymentDocuments, PaymentRequest, PaymentRequestStatus, PaymentRequestTypes } from '../../../../../utils/interfaces'
import dayjs from 'dayjs'
import { colorAliases } from '../../../../../theme/mantineTheme'
import { Flag } from '../../../../../components/Flag'

const PaymentRequestForm: React.FC<{
  form: UseFormReturnType<PaymentRequest & { templateName?: string }>
  requestType: PaymentRequestTypes
}> = ({ form, requestType }) => {
  const navigate = useNavigate()

  const { isLoading, data: beneficiaries } = useFetchBeneficiaries()

  const [beneficiaryOptions, setBeneficiaryOptions] = useState<{ value: string; label: string }[]>([])
  const [saveTemplateChecked, setSaveTemplateChecked] = useState(false)

  const { mutate: addTemplate } = useAddPaymentTemplate(() => navigate('/transaction-centre'))
  const { mutate: updateTemplate } = useUpdatePaymentTemplate(() => navigate('/transaction-centre'))
  const { mutate: addPaymentRequest } = useAddPaymentRequest(() => navigate('/transaction-centre'))

  const isTemplateForm = requestType === PaymentRequestTypes.NEW_TEMPLATE
  const isNewFromTemplateForm = requestType === PaymentRequestTypes.NEW_FROM_TEMPLATE

  const handleViewDocument = (documentURL: string) => {
    // const documentURL = form.values.beneficiaries[0]?.documents?.find((document) => document.document_id === documentId)?.document_name

    // if (documentURL) {
    window.open(documentURL, '_blank')
    // }
  }

  const handleFileChange = (file: File | null) => {
    if (!file) {
      return
    }

    const existingDocuments = form.values.beneficiaries[0].documents || []
    const newDocument: IDefaultPaymentDocuments = {
      document_id: crypto.randomUUID(),
      document_name: `${form.values.beneficiaries?.[0]?.name.replaceAll(' ', '-') || 'user'}-${file?.name}`,
      document_url: '/dummy/dummy-invoice.pdf',
      uploaded_at: dayjs().valueOf(),
    }

    const updatedDocuments = [...existingDocuments, newDocument]

    form.setFieldValue('beneficiaries.0.documents', updatedDocuments)
  }

  const handleRemoveFile = (documentIndex: number) => {
    // const currentDocuments = form.values.beneficiaries[0].documents || []

    // const documentToRemove = currentDocuments.find((document) => document.document_id === documentId)
    // if (documentToRemove) {
    //   const updatedDocuments = currentDocuments.filter((document) => document.document_id !== documentId)
    form.removeListItem('beneficiaries.0.documents', documentIndex)
    // }
  }

  const submitPaymentRequest = (paymentRequest: PaymentRequest) => {
    addPaymentRequest({ ...paymentRequest, status: PaymentRequestStatus.PENDING_APPROVAL })
  }

  const saveTemplateAndUpdate = (paymentRequest: PaymentRequest) => {
    const templateRequest = { ...paymentRequest, status: PaymentRequestStatus.TEMPLATE }
    if (isNewFromTemplateForm) {
      updateTemplate(templateRequest)
      return
    }
    addTemplate(templateRequest)
  }

  const checkFormAndProcess = () => {
    form.validate()
    if (form.isValid()) {
      submitPaymentRequest({ ...form.values })

      if (saveTemplateChecked) {
        saveTemplateAndUpdate({ ...form.values })
      }
    }
  }

  const createTemplate = () => {
    form.validate()
    if (form.isValid()) {
      addTemplate({ ...form.values, status: PaymentRequestStatus.TEMPLATE })
      form.reset()
    }
  }

  const getBeneficiaryOptions = () => {
    const beneficiaryOptions = beneficiaries?.map((b) => ({ value: b.id, label: b.name }))
    setBeneficiaryOptions(beneficiaryOptions ?? [])
  }

  const updateSelectedBeneficiary = (id: string | null) => {
    const beneficiary = beneficiaries?.find((b) => b.id === id)
    if (beneficiary) {
      const paymentBeneficiary = {
        id: beneficiary.id,
        reference: beneficiary.reference || '',
        name: beneficiary.name || '',
        amount: beneficiary.default_payment.amount ?? 0,
        popEmail: beneficiary.default_payment.popEmail,
        popMobileNumber: beneficiary.default_payment.popMobileNumber,
        documents: beneficiary.default_payment.documents,
      }
      form.setFieldValue('beneficiaries.0', paymentBeneficiary)
      form.setFieldValue('currency', beneficiary.currency)
    }
  }

  const isBeneficiarySelected = form.values.beneficiaries?.[0]?.id?.length > 0

  useEffect(() => {
    if (beneficiaries) {
      getBeneficiaryOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries])

  return (
    <Paper mb={{ base: 'xl', desktop: '3xl' }} mt='3xl' mx='auto' p={{ base: 'md', desktop: '2xl' }} pos='relative' maw='68em'>
      <Title order={3} fw={700} fz={{ base: '2xl', desktop: '2xl' }} ta='center' mb={{ base: 'sm', desktop: 'lg' }}>
        {isTemplateForm ? 'Create payment template' : 'Initiate payment request'}
      </Title>
      {isNewFromTemplateForm && (
        <Box ta='center'>
          <Badge
            pos={{ desktop: 'absolute' }}
            top='2.75rem'
            left='2rem'
            // p={{ base: '0 0.5rem', desktop: 'sm' }}
            mx='auto'
            variant='outline'
            color='primaryHighlight'
            mb={{ base: 'sm' }}
          >
            {form.values.templateName}
          </Badge>
        </Box>
      )}
      <form style={{ width: '100%' }}>
        <CSSGrid gap='xl' columnGap='2xl' pos='relative' templateColumns={{ base: '1fr', desktop: '1fr 1fr' }}>
          <TextInput
            variant='filled'
            type='text'
            labelProps={{ c: 'secondary' }}
            label={!isTemplateForm ? 'Internal reference' : 'Template name'}
            description={`The ${!isTemplateForm ? 'internal reference' : 'template name'} for the payment request`}
            {...form.getInputProps('internal_reference')}
            placeholder={!isTemplateForm ? 'Payment internal reference' : 'Template name'}
          />

          <Select
            description='The beneficiary for the payment'
            label='Beneficiary'
            labelProps={{ c: 'secondary' }}
            placeholder={isLoading ? '...' : 'Select a Beneficiary'}
            variant='filled'
            data={beneficiaryOptions}
            {...form.getInputProps('beneficiaries.0.id')}
            onChange={(b) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              form.getInputProps('beneficiaries.0.id').onChange(b)
              updateSelectedBeneficiary(b)
            }}
          />
          <Transition mounted={isBeneficiarySelected} transition='fade' duration={400} timingFunction='ease'>
            {(styles) => (
              <>
                <TextInput
                  variant='filled'
                  type='text'
                  label='Reference'
                  labelProps={{ c: 'secondary' }}
                  description={isTemplateForm ? 'An initial reference for this transaction. It can be updated later.' : 'Reference for this transaction'}
                  {...form.getInputProps('beneficiaries.0.reference')}
                  sx={(_theme, u) => ({
                    input: { fontSize: '0.8rem', [u.largerThan('desktop')]: { fontSize: '0.875rem' } },
                  })}
                  placeholder='A standard reference for this transaction'
                  style={{ ...styles }}
                />
                <CSSGrid gap='md' rowGap='xl' templateColumns={{ base: '1fr', desktop: '8rem 1fr' }} style={{ ...styles }}>
                  <TextInput
                    variant='filled'
                    type='text'
                    label='Currency'
                    labelProps={{ c: 'secondary' }}
                    description='Beneficiary currency'
                    disabled
                    defaultValue={`(${currencies[form.values.currency].symbol}) ${form.values.currency} `}
                    leftSection={
                      <Flag
                        countryCode={currencies[form.values.currency].countryCodeForFlag}
                        square={true}
                        style={{
                          borderRadius: '50%',
                          width: '1rem',
                          height: '1rem',
                          zIndex: 1,
                        }}
                      />
                    }
                  />
                  <NumberInput
                    variant='filled'
                    type='text'
                    placeholder='Payment amount'
                    label='Payment amount'
                    labelProps={{ c: 'secondary' }}
                    description="The amount you'd like to send"
                    decimalScale={2}
                    hideControls
                    min={0}
                    {...form.getInputProps('beneficiaries.0.amount')}
                  />
                </CSSGrid>
                <TextInput
                  variant='filled'
                  sx={{ flex: 1 }}
                  type='text'
                  label='Proof of payment - Email (optional)'
                  labelProps={{ c: 'secondary' }}
                  description='An email is sent to this address when this payment is made'
                  {...form.getInputProps('beneficiaries.0.popEmail')}
                  value={form.values.beneficiaries[0]?.popEmail ?? ''}
                  placeholder='Email address'
                  style={{ ...styles }}
                />
                <PhoneNumberInput
                  textInputProps={{
                    variant: 'filled',
                    placeholder: 'Phone number',
                  }}
                  searchableSelectProps={{
                    variant: 'filled',
                    placeholder: 'Dialling code',
                  }}
                  description='An sms is sent to this number when this payment is made'
                  label='Proof of payment - Mobile (optional)'
                  labelProps={{ c: 'secondary' }}
                  {...form.getInputProps('beneficiaries.0.popMobileNumber')}
                  // FIXME: check styling
                  // style={{ ...styles }}
                />
                <Box style={{ ...styles }}>
                  <Text color='primary' fw={700} fz='sm' mb='3xs' c={colorAliases.textInvert}>
                    Supporting documents (optional)
                  </Text>
                  <Flex gap='xs' align='center' mb='sm'>
                    <Flex
                      align='center'
                      justify='center'
                      sx={(theme) => ({ border: `1px solid ${getThemeColor(colorAliases.borderSubtle, theme)}`, borderRadius: theme.radius.sm })}
                      p='xs'
                    >
                      <Box component={IconDocChecked} c='muted' sx={{ flexShrink: 0 }} />
                    </Flex>
                    <Text fz='xs' color='textPrimary'>
                      Supporting documentation justifying payment (contract, invoice, receipt). If you have a general document, upload it here, otherwise you
                      will be able to upload individual documents when you make a transaction.
                    </Text>
                  </Flex>
                  {form.values.beneficiaries[0]?.documents?.map((d, index) => (
                    <CSSGrid templateColumns='auto 1fr auto auto' key={d.document_id} mb='2xs' gap='2xs' alignItems='center'>
                      <Box component={IconTickMarkYes} h='sm' c='success' ml={2} />
                      <Text fz='xs' c='primary'>
                        {d.document_name}
                      </Text>
                      <ActionIcon size='sm' variant='transparent' color={colorAliases.borderHighlight} onClick={() => handleViewDocument(d.document_url)}>
                        <IconViewInfoEye />
                      </ActionIcon>
                      <ActionIcon size='sm' variant='transparent' color='error' p={3}>
                        <IconCloseCross onClick={() => handleRemoveFile(index)} />
                      </ActionIcon>
                    </CSSGrid>
                  ))}
                  <Flex justify='start' mt='lg'>
                    <FileUploadButton handleFileChange={handleFileChange} />
                  </Flex>
                </Box>
              </>
            )}
          </Transition>
        </CSSGrid>
        <Flex
          direction={{ base: 'column', desktop: 'row' }}
          align='center'
          justify={!isTemplateForm && isBeneficiarySelected ? 'space-between' : 'end'}
          gap='md'
          mt={{ base: 'xl', desktop: 'lg' }}
        >
          {!isTemplateForm && isBeneficiarySelected && (
            <Checkbox
              color='primary'
              sx={(theme) => ({
                label: { color: getThemeColor('textPrimary', theme) },
              })}
              label={isNewFromTemplateForm ? 'Update template' : 'Save as template'}
              size='sm'
              checked={saveTemplateChecked}
              onChange={(e) => setSaveTemplateChecked(e.currentTarget.checked)}
            />
          )}
          <CSSGrid gap='md' templateColumns='1fr 1fr'>
            <Button
              w={125}
              radius='xs'
              leftSection={<IconCross style={{ height: '1.4em', width: '1.4em' }} />}
              variant='outline'
              // leftIconColor={colorAliases.textInvert}
              c='dark'
              sx={(theme) => ({
                border: `1px solid ${getThemeColor(colorAliases.borderSubtle, theme)}`,
              })}
              onClick={() => navigate('/transaction-centre')}
            >
              Cancel
            </Button>

            <Button
              w={125}
              radius='xs'
              leftSection={<IconDownArrow style={{ height: '0.75em', width: '0.75rem', transform: 'rotate(270deg)' }} />}
              variant='filled'
              // leftIconColor='white'
              // FIXME: check style
              color='primary'
              onClick={isTemplateForm ? createTemplate : checkFormAndProcess}
            >
              Submit
            </Button>
          </CSSGrid>
        </Flex>
      </form>
    </Paper>
  )
}

export default PaymentRequestForm

import { ComboboxItem, ComboboxLikeRenderOptionInput, Group, MultiSelectProps, SelectProps, Text } from '@mantine/core'
import React from 'react'
import { Flag } from '../Flag'
import { Country, keyedCountries } from './countries'

const countries: readonly Country[] = Object.values(keyedCountries)

// interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
//   selectItemLabel: string
//   countryCode: string
//   searchLabel: string
// }

const MemoizedSelectItemInner = React.memo(((props) => (
  <Group wrap='nowrap' gap={props.spacing || 'md'}>
    <Flag countryCode={props.countryCode} style={{ borderRadius: 4 }} />
    <Text size='sm'>{props.selectItemLabel}</Text>
  </Group>
)) as React.FC<{ spacing?: string; countryCode: string; selectItemLabel: string }>)
MemoizedSelectItemInner.displayName = 'SelectItemInner'

const renderCountryItem: SelectProps['renderOption'] = ({ option }: ComboboxLikeRenderOptionInput<ComboboxItem>) => {
  return (
    <div>
      <MemoizedSelectItemInner countryCode={option.value} selectItemLabel={option.label} />
    </div>
  )
}

// const CountrySelectValue = ({ value, label, onRemove, size, disabled, readOnly, ...others }: MultiSelectValueProps & { value: string }) => {
//   return (
//     <div className={others.className}>
//       <Box
//         sx={(theme) => ({
//           display: 'flex',
//           // cursor: 'default',
//           alignItems: 'center',
//           // paddingLeft: 10,
//           borderRadius: 4,
//           backgroundColor: disabled
//             ? theme.colorScheme === 'dark'
//               ? theme.colors.dark[5]
//               : theme.colors.gray[3]
//             : theme.colorScheme === 'dark'
//             ? theme.colors.dark[7]
//             : theme.colors.gray[1],
//           color: disabled
//             ? theme.colorScheme === 'dark'
//               ? theme.colors.dark[1]
//               : theme.colors.gray[7]
//             : theme.colorScheme === 'dark'
//             ? theme.colors.dark[0]
//             : theme.colors.gray[7],
//           paddingLeft: `calc(${getSize({ size: size, sizes: theme.spacing })} - 0.375rem)`,
//           paddingRight: disabled || readOnly ? getSize({ size: size, sizes: theme.spacing }) : '0',
//           height: '100%',
//           fontWeight: 500,
//           fontSize: `calc(${getSize({ size: size, sizes: theme.fontSizes })} - 0.125rem)`,
//           cursor: disabled ? 'not-allowed' : 'default',
//           userSelect: 'none',
//         })}
//       >
//         <Flag countryCode={value} style={{ borderRadius: 4 }} />
//         <Box sx={{ lineHeight: 1, paddingLeft: 8 }}>{label}</Box>
//         <CloseButton onMouseDown={onRemove} variant='transparent' size={22} iconSize={14} tabIndex={-1} />
//       </Box>
//     </div>
//   )
// }

const countryCodesAsData = countries.reduce<ComboboxItem[]>(
  (acc, country) => [
    ...acc,
    {
      value: country.alpha2code,
      label: `${country.name} ${country.alpha2code}`,
      // countryCode: country.alpha2code,
      // selectItemLabel: country.name,
      // searchLabel: `${country.name} ${country.alpha2code}`,
    },
  ],
  []
)

export const getSelectPropsForCountry = (currentVal?: string | null, filter?: (item: ComboboxItem) => boolean) =>
  ({
    filter: ({ options, search }) => (options as ComboboxItem[]).filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim())),
    data: filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData,
    renderOption: renderCountryItem,
    leftSection: currentVal !== undefined && currentVal !== null ? <Flag countryCode={currentVal} style={{ borderRadius: 4 }} /> : undefined,
  } as SelectProps)

export const getMultiSelectPropsForCountry = (filter?: (item: ComboboxItem) => boolean) =>
  ({
    filter: ({ options, search }) => (options as ComboboxItem[]).filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim())),
    data: filter && typeof filter === 'function' ? countryCodesAsData.filter(filter) : countryCodesAsData,
    renderOption: renderCountryItem,
    // valueComponent: CountrySelectValue,
  } as MultiSelectProps)

import { Container } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import useFetchPaymentTemplates from '../../../../api/queries/useFetchPaymentTemplates'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import ActionBar from './ActionBar'
import ExistingTemplatesArea from './ExistingTemplatesArea'
import TimingsArea from './TimingsArea'

export interface PaymentTemplateFilters {
  nameOrAliasOrReference: string
  currency: string
}

const InitiatePayments = () => {
  const [filters, setFilters] = useSetState<PaymentTemplateFilters>({
    nameOrAliasOrReference: '',
    currency: '',
  })

  const { isLoading, isSuccess, data: paymentTemplates } = useFetchPaymentTemplates(filters)

  return (
    <Container py={{ base: 'xl', desktop: '3xl' }} mt='lg' sx={{ isolation: 'isolate' }}>
      <CSSGrid
        columnGap={{ base: 'xl', desktop: '3xl' }}
        rowGap='xl'
        alignItems='start'
        templateAreas={{
          base: '"filters" "report" "processing_times"',
          desktop: '"filters report" "processing_times report"',
        }}
        templateColumns={{ base: '1fr', desktop: 'auto 1fr' }}
        templateRows={{ base: 'auto', desktop: 'auto 1fr' }}
      >
        <ActionBar sx={{ gridArea: 'filters' }} w={{ base: '100%', desktop: 390 }} mih={250} filters={filters} setFilters={setFilters} />
        <TimingsArea w={{ base: '100%', desktop: 390 }} sx={{ gridArea: 'processing_times' }} />
        <ExistingTemplatesArea sx={{ gridArea: 'report' }} paymentTemplates={paymentTemplates ?? []} isLoading={isLoading} isSuccess={isSuccess} />
      </CSSGrid>
    </Container>
  )
}

export default InitiatePayments

import { Anchor, Box, Breadcrumbs, Button, Checkbox, Container, Flex, Loader, MultiSelect, NumberInput, Select, Stack, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFetchOnboardingInfo from '../../../api/queries/onboarding/useFetchOnboardingInfo'
import useUpdateOnboardingRequest from '../../../api/queries/onboarding/useUpdateOnboardingRequest'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { IconArrowRight } from '../../../components/icons'
import { DEFAULT_ONBOARDING_FORM_FIELDS } from '../../../contexts/onboarding/useOnboardingSessionManager'
import { colorAliases } from '../../../theme/mantineTheme'
import {
  AnnualGrossTurnover,
  DeepPartial,
  MonthlyGrossTurnover,
  MonthlyTransactionVolume,
  NumberOfEmployees,
  OnboardingSteps,
  RequestedProduct,
  SourceOfIncome,
  Step05FinancialDetailsRequest,
} from '../../../types/onboarding'
import currencies from '../../../utils/currencies'
import { Currency } from '../../../utils/interfaces'
import { checkboxStyles, getOptionsFromEnum, helpBorder } from '../onboardingUtils'

const annualGrossTurnover = getOptionsFromEnum(AnnualGrossTurnover)
const monthlyGrossTurnover = getOptionsFromEnum(MonthlyGrossTurnover)
const monthlyTransactionVolume = getOptionsFromEnum(MonthlyTransactionVolume)

export const numberOfEmployeesMap = [
  {
    label: '1',
    value: NumberOfEmployees.ONE,
  },
  {
    label: '2 - 5',
    value: NumberOfEmployees.TWO_TO_FIVE,
  },
  {
    label: '6 - 10',
    value: NumberOfEmployees.SIX_TO_TEN,
  },
  {
    label: '11 - 50',
    value: NumberOfEmployees.ELEVEN_TO_FIFTY,
  },
  {
    label: '51 - 100',
    value: NumberOfEmployees.FIFTY_ONE_TO_ONE_HUNDRED,
  },
  {
    label: 'More than 100',
    value: NumberOfEmployees.MORE_THAN_ONE_HUNDRED,
  },
]

const FinancialDetails = () => {
  const selectedStep = 5
  const { mutate: saveRequest } = useUpdateOnboardingRequest()
  const { data: onboardingInfo, isLoading, isSuccess } = useFetchOnboardingInfo()

  const navigate = useNavigate()

  const form = useForm<DeepPartial<Step05FinancialDetailsRequest>>({
    initialValues: {
      step: OnboardingSteps.STEP_05_FINANCIAL_DETAILS,
      status: 'in_progress',
      ...DEFAULT_ONBOARDING_FORM_FIELDS,
    },
    validate: {
      account: {
        annualGrossTurnover: (v) => (v === undefined ? 'Please select an option.' : null),
        monthlyGrossTurnover: (v) => (v === undefined ? 'Please select an option.' : null),
        monthlyTransactionVolume: (v) => (v === undefined ? 'Please select an option.' : null),
        limits: {
          daily: (v) => (v === undefined ? 'Please enter a value' : v < 1 ? 'Please enter a value greater than zero' : null),
          monthly: (v) => (v === undefined ? 'Please enter a value' : v < 1 ? 'Please enter a value greater than zero' : null),
        },
        requestedProducts: (v) => (v === undefined || v.length === 0 ? 'Please select at least one option.' : null),
        sourceOfIncome: (v) => (v === undefined || v.length === 0 ? 'Please select at least one option.' : null),
        sourceOfIncomeOther: (v, values) =>
          values.account?.sourceOfIncome !== undefined && values.account.sourceOfIncome.indexOf(SourceOfIncome.OTHER) > -1 && (v === undefined || v === '')
            ? 'Please give more details about the other source of income.'
            : null,
      },
      company: {
        numberOfEmployees: (v) => (v === undefined ? 'Please select an option.' : null),
      },
      other: {
        currenciesForPayment: (v) => (v === undefined ? 'Please select a currency.' : null),
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  // TODO: consider partial saving
  const saveChanges = (continueToNext = false) => {
    form.validate()
    if (form.isValid()) {
      console.log('save details:', form.values, form.isValid())
      saveRequest({ ...form.values } as Step05FinancialDetailsRequest, {
        onSuccess: () => (continueToNext ? navigate(`/onboarding/step/${selectedStep + 1}`) : null),
      })
    }
  }

  useEffect(() => {
    if (onboardingInfo && isSuccess && !isLoading) {
      const values = JSON.parse(JSON.stringify(onboardingInfo.onboarding_request)) as never
      form.setValues(values)
      form.resetDirty(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingInfo, isLoading, isSuccess])

  if (isLoading || !isSuccess) {
    return (
      <Container py={{ base: '2xl', desktop: '4rem' }} sx={{ isolation: 'isolate' }} w={{ base: '100%', desktop: 'max(37em, 45%)' }}>
        <Box p='xs' pl={0} c='textPrimary'>
          <Flex align='center' justify='center'>
            <Loader />
          </Flex>
        </Box>
      </Container>
    )
  }

  return (
    <>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor href='/onboarding/dashboard/' c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 5 | Financial Details</Box>
      </Breadcrumbs>

      <CSSGrid gap='lg'>
        <Select
          labelProps={{ c: 'secondary' }}
          data={annualGrossTurnover}
          label='Expected annual gross turnover within Leikur'
          placeholder='E.g. 500 000 to 1 000 000 EUR'
          {...form.getInputProps('account.annualGrossTurnover')}
        />
        <Select
          labelProps={{ c: 'secondary' }}
          data={monthlyGrossTurnover}
          label='Expected monthly gross turnover to Leikur'
          placeholder='E.g. 50 000 to 100 000 EUR'
          {...form.getInputProps('account.monthlyGrossTurnover')}
        />
        <Select
          labelProps={{ c: 'secondary' }}
          data={monthlyTransactionVolume}
          label='Expected monthly transactions to and from Leikur'
          placeholder='E.g. 5000 to 10 000 transactions'
          {...form.getInputProps('account.monthlyTransactionVolume')}
        />
        <NumberInput
          labelProps={{ c: 'secondary' }}
          label='Desired daily account limit (EUR)'
          {...form.getInputProps('account.limits.daily')}
          hideControls
          decimalScale={2}
          placeholder='E.g. 50000'
        />
        <NumberInput
          labelProps={{ c: 'secondary' }}
          label='Desired monthly account limit (EUR)'
          {...form.getInputProps('account.limits.monthly')}
          hideControls
          decimalScale={2}
          placeholder='E.g. 700000'
        />
        <Select
          labelProps={{ c: 'secondary' }}
          data={numberOfEmployeesMap}
          label='Total number of employees and exclusive contractors that work for the company'
          placeholder='E.g. 6 - 10'
          {...form.getInputProps('company.numberOfEmployees')}
        />

        <Checkbox.Group
          {...form.getInputProps('account.requestedProducts')}
          labelProps={{ c: 'secondary' }}
          label='Select the services that you would like to use.'
        >
          <Stack gap='sm' mt='sm' mb={form.getInputProps('account.requestedProducts').error ? 'sm' : 0}>
            <Checkbox label={<Text c={colorAliases.brandGray}>SEPA payments</Text>} value={RequestedProduct.SEPA_PAYMENTS} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Business cards</Text>} value={RequestedProduct.BUSINESS_CARDS} styles={checkboxStyles} />
            <Checkbox
              label={<Text c={colorAliases.brandGray}>International payments</Text>}
              value={RequestedProduct.INTERNATIONAL_PAYMENTS}
              styles={checkboxStyles}
            />
            <Checkbox label={<Text c={colorAliases.brandGray}>Merchant account</Text>} value={RequestedProduct.MERCHANT_ACCOUNT} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Segregated account</Text>} value={RequestedProduct.SEGREGATED_ACCOUNT} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Currency exchange</Text>} value={RequestedProduct.CURRENCY_EXCHANGE} styles={checkboxStyles} />
          </Stack>
        </Checkbox.Group>

        <MultiSelect
          multiple
          searchable
          data={Object.keys(currencies) as Currency[]}
          // FIXME: reimplement
          // {...getMultiSelectPropsForCurrency(Object.keys(currencies) as Currency[])}
          labelProps={{ c: 'secondary' }}
          label='Which currencies would you like to have available for payments?'
          variant='default'
          placeholder='E.g. USD (United States), GBP (United Kingdom)'
          {...form.getInputProps('other.currenciesForPayment')}
        />

        <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
          <Box fz='sm' c={colorAliases.textInvert} pr='1rem'>
            Note: Only EURO is available for incoming funds.
          </Box>
        </Box>

        <Checkbox.Group
          {...form.getInputProps('account.sourceOfIncome')}
          labelProps={{ c: 'secondary' }}
          label='What will be the source of incoming funds into your Leikur account?'
        >
          <Stack gap='sm' mt='sm' mb={form.getInputProps('account.sourceOfIncome').error ? 'sm' : 0}>
            <Checkbox label={<Text c={colorAliases.brandGray}>Dividends</Text>} value={SourceOfIncome.DIVIDENDS} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Donations</Text>} value={SourceOfIncome.DONATIONS} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Interest</Text>} value={SourceOfIncome.INTEREST} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Business proceeds</Text>} value={SourceOfIncome.BUSINESS_PROCEEDS} styles={checkboxStyles} />
            <Checkbox label={<Text c={colorAliases.brandGray}>Other</Text>} value={SourceOfIncome.OTHER} styles={checkboxStyles} />
          </Stack>
          {form.values.account?.sourceOfIncome?.includes(SourceOfIncome.OTHER) && (
            <Box mt='md'>
              <Textarea
                placeholder='More details on "Other"'
                description={`${form.values.account?.sourceOfIncomeOther?.length ?? '0'} of 200 characters`}
                maxLength={200}
                {...form.getInputProps('account.sourceOfIncomeOther')}
              />
            </Box>
          )}
        </Checkbox.Group>
      </CSSGrid>

      <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto' gap='sm' mt='md'>
        <Button h='2.4rem' size='xs' mt='md' color='dark' onClick={() => navigate(`/onboarding/step/${selectedStep - 1}`)}>
          Previous
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(false)}>
          Save
        </Button>
        <Button h='2.4rem' size='xs' mt='md' color='primary' onClick={() => saveChanges(true)}>
          Save and continue
        </Button>
      </CSSGrid>
    </>
  )
}

export default FinancialDetails

// for lazy loading via react-router-dom
export const Component: React.FC = FinancialDetails
Component.displayName = 'OnboardingFinancialDetailsComponent'

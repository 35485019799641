import { Button, Chip, Divider, Flex, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { useEffect } from 'react'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { EMAIL_REGEX, regexValidate } from '../../../../components/inputs/email-validation'
import { ClientRole, IdentityDocument, Ownership, Pep, Person } from '../../../../types/onboarding'
import Address from './Address'
import Directors from './Directors'
import IDDocumentDetails from './IDDocumentDetails'
import OwnershipFunction from './OwnershipFunction'
import PepDetails from './PepDetails'
import PersonDetails from './PersonDetails'

interface AddRepresentativeProps {
  representative: Person | null
  persistRepresentative: (representative: Person) => void
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>
}

const emptyRepresentative: Person = {
  personId: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  citizenshipCountryCode: '',
  externalReference: '',
  dateOfBirth: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    city: '',
    countryCode: '',
  },
  taxIdentification: {
    tinCountryOfResidency: '',
    additionalCountries: [],
  },
  identityDocument: {
    number: '',
    issueDate: '',
    expiryDate: '',
    issuingCountryCode: '',
    fileId: '',
  } as IdentityDocument,
  pep: {
    position: '',
  } as Pep,
  ownership: {
    type: '',
    typeOther: '',
    shareSize: 0,
  } as Ownership,
  uboSourceOfWealth: '',
  isDirectorExecutive: false,
}

const isMar = (roles: ClientRole[]) => roles.includes(ClientRole.MAR)
const isDir = (roles: ClientRole[]) => roles.includes(ClientRole.DIRECTOR)
const isUbo = (roles: ClientRole[]) => roles.includes(ClientRole.UBO)
const isDirOnly = (roles: ClientRole[]) => isDir(roles) && roles.length === 1
// const isDirUbo = (roles: ClientRole[]) => isDir(roles) && isUbo(roles)
// const isMarUbo = (roles: ClientRole[]) => isMar(roles) && isUbo(roles)
// const isMarUboDir = (roles: ClientRole[]) => isMar(roles) && isUbo(roles) && isDir(roles)
// const isMarDir = (roles: ClientRole[]) => isMar(roles) && isDir(roles)

const AddRepresentative: React.FC<AddRepresentativeProps> = ({ setShowForm, representative, persistRepresentative }) => {
  const initialValues = representative ? (JSON.parse(JSON.stringify(representative)) as Person) : emptyRepresentative

  const form = useForm<Person & { isMultipleTaxResidency?: boolean }>({
    initialValues: {
      ...initialValues,
    },
    validate: {
      firstName: (value) => {
        return value.length === 0 ? 'Please enter representatives First Name' : null
      },
      lastName: (value) => {
        return value.length === 0 ? 'Please enter representatives Last Name' : null
      },
      email: (value, values) => (isMar(values.roles) && !regexValidate(value, true, EMAIL_REGEX) ? 'Please enter a valid email address' : null),
      isDirectorExecutive: (value, values) => {
        if (isDir(values.roles)) {
          return value === undefined ? 'Please select director type' : null
        }
        return null
      },
      taxIdentification: {
        tinCountryOfResidency: (value, values) => {
          if (isUbo(values.roles)) {
            return !value ? 'Please enter Tax Identification Number' : null
          }
          return null
        },
      },
      isMultipleTaxResidency: (value, values) => {
        if (isUbo(values.roles)) {
          return value === undefined
            ? 'Please select if representative has multiple tax residencies'
            : value && values.taxIdentification.additionalCountries.length === 0
            ? 'Please add the required country/coutries'
            : null
        }
        return null
      },
      dateOfBirth: (value, values) => {
        if (!isMar(values.roles)) {
          return value.length === 0 ? 'Please enter Date of Birth' : null
        }
        return null
      },
      address: {
        addressLine1: (value, values) => {
          if (isUbo(values.roles) && !isMar(values.roles)) {
            return !value ? 'Please enter Address' : null
          }
          return null
        },
        city: (value, values) => {
          if (isUbo(values.roles) && !isMar(values.roles)) {
            return !value ? 'Please enter City' : null
          }
          return null
        },
        postcode: (value, values) => {
          if (isUbo(values.roles) && !isMar(values.roles)) {
            return !value ? 'Please enter Postcode' : null
          }
          return null
        },
        countryCode: (value, values) => {
          if (isUbo(values.roles) || (isDirOnly(values.roles) && !isMar(values.roles))) {
            return !value ? 'Please select Country' : null
          }
          return null
        },
      },
      pep: {
        isPep: (value, values) => {
          if (!isMar(values.roles)) {
            return value === undefined ? 'Please select if representative is politically exposed' : null
          }
        },
        position: (value, values) => {
          if (!isMar(values.roles)) {
            return !value && values.pep.isPep ? 'Please enter politically exposed position' : null
          }
        },
      },
      citizenshipCountryCode: (value, values) => {
        if (!isMar(values.roles)) {
          return !value ? 'Please select Citizenship Country' : null
        }
        return null
      },
      ownership: {
        type: (value, values) => {
          if (isUbo(values.roles)) {
            return !value ? 'Please select ownership function' : null
          }
          return null
        },
        shareSize: (value, values) => {
          if (isUbo(values.roles)) {
            return !value ? 'Please complete share size' : null
          }
          return null
        },
        hasVotingControl: (value, values) => {
          if (isUbo(values.roles)) {
            return value === undefined ? 'Please select if the representative has controlling stake, veto rights, or owns shares with voting powers' : null
          }
          return null
        },
        hasVotingControlAdditionalInformation: (value, values) => {
          if (isUbo(values.roles)) {
            return !value && values.ownership.hasVotingControl ? 'Please enter details' : null
          }
        },
      },
      uboSourceOfWealth: (value, values) => {
        if (isUbo(values.roles)) {
          return value.length === 0 ? 'Please select UBO Source of Wealth' : null
        }
        return null
      },
      identityDocument: {
        type: (value, values) => {
          if (!isMar(values.roles)) {
            return value === undefined ? 'Please select Identity Document type' : null
          }
          return null
        },
        expiryDate: (value, values) => {
          if (!isMar(values.roles)) {
            return !value ? 'Please select expiry date' : null
          }
          return null
        },
        issueDate: (value, values) => {
          if (!isMar(values.roles)) {
            return !value ? 'Please select issue date' : null
          }
          return null
        },
        issuingCountryCode: (value, values) => {
          if (!isMar(values.roles)) {
            return !value ? 'Please select issuing country' : null
          }
          return null
        },
        number: (value, values) => {
          if (!isMar(values.roles)) {
            return !value ? 'Please complete Identity Document information' : null
          }
          return null
        },
      },
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (representative === null && form.values.personId === '') {
      const id = crypto.randomUUID()
      form.setFieldValue('personId', id)
    }
  }, [representative, form])

  const saveRepresentative = () => {
    form.validate()
    // if (!isMar(form.values.roles)) {
    //   if (!form.isDirty('identityDocument.type')) {
    //     form.setFieldError('identityDocument.type', 'Please select Identity Document type')
    //   } else {
    //     form.clearFieldError('identityDocument.type')
    //   }
    // }

    if (form.isValid()) {
      persistRepresentative({ ...form.values })
    }
  }

  const chipStyles = {
    label: {
      fontSize: '0.8rem',
      paddingTop: '0.7rem',
      paddingBottom: '0.7rem',
      height: '2.3rem',
      width: '100%',
      color: form.values.roles.length > 0 ? 'var(--mantine-color-transparentBlack-7)!important' : 'var(--mantine-color-dark-0)!important',
      '&[data-checked]': {
        color: 'var(--mantine-color-white)!important',
      },
    },
  }

  return (
    <>
      <Title order={2} fz='md' mb='lg'>
        Select Representative type(s)
      </Title>
      <Flex align='center' gap='xs' direction={{ base: 'column', desktop: 'row' }} mb='lg'>
        <Chip.Group
          multiple={true}
          {...form.getInputProps('roles')}
          onChange={(values: string[]) => {
            const stringValues = values.map((role) => role.toString())
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            form.getInputProps('roles').onChange(stringValues)
            form.setErrors({})
          }}
        >
          <Chip styles={chipStyles} value={ClientRole.MAR}>
            Master Authorised Person (MAR)
          </Chip>
          <Chip styles={chipStyles} value={ClientRole.DIRECTOR}>
            Director
          </Chip>
          <Chip styles={chipStyles} value={ClientRole.UBO}>
            Ultimate Beneficial Owner (UBO)
          </Chip>
        </Chip.Group>
      </Flex>
      <Divider my={{ base: 'xl', desktop: '3xl' }} />
      {form.values.roles.length > 0 && (
        <>
          <Text fz='lg' fw='700' mb={{ base: 'lg', desktop: '2xl' }} ta='center' c='secondary'>
            Create Representative
          </Text>
          <PersonDetails form={form} />
          {!isMar(form.values.roles) && isUbo(form.values.roles) && <Address form={form} />}
          {!isMar(form.values.roles) && <IDDocumentDetails form={form} />}
          {!isMar(form.values.roles) && <PepDetails form={form} />}
          {isDir(form.values.roles) && <Directors form={form} />}
          {isUbo(form.values.roles) && <OwnershipFunction form={form} />}
        </>
      )}

      {/* {isMarUboDir(form.values.roles) ? (
        <Flex key='AllFields' gap='2xl' mb='sm' w='100%' direction={{ base: 'column', desktop: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
            <Directors form={form} />
            <OwnershipFunction form={form} />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <TaxIdentification form={form} />
          </Box>
        </Flex>
      ) : isMarUbo(form.values.roles) ? (
        <Flex key='MarUboFields' gap='2xl' mb='sm' w='100%' direction={{ base: 'column', desktop: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
            <TaxIdentification form={form} />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <OwnershipFunction form={form} />
          </Box>
        </Flex>
      ) : isMarDir(form.values.roles) ? (
        <Flex key='MarDirectorFields' gap='2xl' w='100%' direction={{ base: 'column', desktop: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <Directors form={form} />
          </Box>
        </Flex>
      ) : isDirUbo(form.values.roles) ? (
        <Flex key='DirectorUboFields' gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
            <Flex gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Date of birth'
                placeholder='Select date of birth'
                type='date'
                mb='sm'
                w='50%'
                {...form.getInputProps('dateOfBirth')}
              />
              <Select
                label='Country of citizenship'
                labelProps={{ c: 'secondary' }}
                placeholder='Select country'
                data={countryData}
                mb='sm'
                w='50%'
                {...form.getInputProps('citizenshipCountryCode')}
              />
            </Flex>
            <Address form={form} />
            <Space h='md' />
            <IDDocumentDetails form={form} />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <Directors form={form} />
            <PepDetails form={form} />
            <Box mt='xl'>
              <TaxIdentification form={form} />
            </Box>
            <OwnershipFunction form={form} />
          </Box>
        </Flex>
      ) : isMar(form.values.roles) ? (
        <Flex direction='column' w={{ base: '100%', desktop: '25rem' }}>
          <PersonDetails form={form} />
        </Flex>
      ) : isUbo(form.values.roles) ? (
        <Flex key='UboFields' gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Place of birth (optional)'
              placeholder='Enter place of birth'
              mb='sm'
              {...form.getInputProps('placeOfBirth')} // Not sure where this must be set to? wait for api release
            />
            <Flex gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Date of birth'
                placeholder='Select date of birth'
                type='date'
                mb='sm'
                w='50%'
                {...form.getInputProps('dateOfBirth')}
              />
              <Select
                label='Country of citizenship'
                labelProps={{ c: 'secondary' }}
                placeholder='Select country'
                data={countryData}
                mb='sm'
                w='50%'
                {...form.getInputProps('citizenshipCountryCode')}
              />
            </Flex>
            <Address form={form} />
            <IDDocumentDetails form={form} />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <PepDetails form={form} />
            <Space h='2xs' />
            <TaxIdentification form={form} />
            <OwnershipFunction form={form} />
          </Box>
        </Flex>
      ) : isDir(form.values.roles) ? (
        <Flex key='DirectorFields' gap='2xl' w='100%' direction={{ base: 'column', desktop: 'row' }}>
          <Box
            pr='xl'
            sx={(theme) => ({ flex: 1, borderRight: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}` })}
            w={{ base: '100%', desktop: '50%' }}
          >
            <PersonDetails form={form} />
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Date of birth'
              placeholder='Select date of birth'
              type='date'
              mb='sm'
              {...form.getInputProps('dateOfBirth')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Place of birth (optional)'
              placeholder='Enter place of birth'
              mb='sm'
              {...form.getInputProps('placeOfBirth')}
            />

            <Flex gap={{ base: 0, desktop: 'lg' }} direction={{ base: 'column', desktop: 'row' }}>
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Town/City'
                placeholder='Enter town/city'
                mb='sm'
                w='50%'
                {...form.getInputProps('address.city')}
              />
              <TextInput
                labelProps={{ c: 'secondary' }}
                label='Postal/Zip code'
                placeholder='Enter postal/zip code'
                mb='sm'
                w='50%'
                {...form.getInputProps('address.postcode')}
              />
            </Flex>
            <Select
              label='Country of residence'
              labelProps={{ c: 'secondary' }}
              placeholder='Select country'
              searchable
              clearable
              mb='sm'
              {...getSelectPropsForCountry(form.values.address?.countryCode || null)}
              {...form.getInputProps('address.countryCode')}
            />
            <Select
              label='Country of citizenship'
              labelProps={{ c: 'secondary' }}
              placeholder='Select country'
              searchable
              clearable
              mb='sm'
              {...getSelectPropsForCountry(form.values.citizenshipCountryCode || null)}
              {...form.getInputProps('citizenshipCountryCode')}
            />
          </Box>
          <Box w={{ base: '100%', desktop: '50%' }}>
            <IDDocumentDetails form={form} />
            <Directors form={form} />
            <PepDetails form={form} />
          </Box>
        </Flex>
      ) : null} */}
      <CSSGrid alignContent='center' justifyItems='start' templateColumns='1fr auto auto auto' gap='sm' mt='md'>
        <Button
          h='2.4rem'
          size='xs'
          mt='md'
          color='dark'
          onClick={() => {
            setShowForm(false)
          }}
        >
          Back
        </Button>
        <Button onClick={saveRepresentative} h='2.4rem' size='xs' mt='md' color='primary'>
          Save Person
        </Button>
      </CSSGrid>
    </>
  )
}

export default AddRepresentative

import { Badge, Box, Flex, MantineTheme, Paper, PaperProps, Title } from '@mantine/core'
import dayjs from 'dayjs'
import { PaymentRequest, PaymentRequestStatus } from '../../../../utils/interfaces'
import ApprovalRequestDetails from './ApprovalRequestDetails'
import { colorAliases } from '../../../../theme/mantineTheme'

const getBorderStyleForRequestStatus = (theme: MantineTheme, status: string) => {
  if (status === (PaymentRequestStatus.PUSHED_BACK as string)) {
    return `2px solid ${colorAliases.warning}`
  } else if (status === (PaymentRequestStatus.REJECTED as string)) {
    return `2px solid ${theme.colors.error[3]}`
  } else if (status === (PaymentRequestStatus.READY as string)) {
    return `2px solid ${colorAliases.systemSuccess}`
  } else {
    return '2px solid white'
  }
}

export const getStatusColor = (status: PaymentRequestStatus) =>
  status === PaymentRequestStatus.PUSHED_BACK ? 'warning' : status === PaymentRequestStatus.READY ? colorAliases.systemSuccess : colorAliases.textInvert

const ApprovalRequestDetailsArea: React.FC<PaperProps & { request: PaymentRequest; readyPaymentRequests: PaymentRequest[] }> = ({
  request,
  readyPaymentRequests,
  ...props
}) => (
  <Paper p='lg' {...props} sx={(theme) => ({ border: getBorderStyleForRequestStatus(theme, request.status) })}>
    <Box pb='sm'>
      <Flex justify='space-between' align='center'>
        <Box>
          <Title order={3} fz='lg'>
            {request.internal_reference}
          </Title>
          <Title order={4} c='textPrimary' fz='xs' fw={400} display={{ base: 'none', desktop: 'block' }}>
            Last modified on {dayjs(request.last_modified_date).format('DD MMMM, YYYY - HH:mm')}, by {request.last_modified_by}
          </Title>
          <Title order={4} c='textPrimary' fz='xs' fw={400} display={{ base: 'block', desktop: 'none' }}>
            Last modified on {dayjs(request.last_modified_date).format('DD MMMM YYYY')}, <br />
            {dayjs(request.last_modified_date).format('HH:mm')}, by {request.last_modified_by}
          </Title>
        </Box>
        <Badge tt='capitalize' p={{ base: '0 0.5rem', desktop: '0.9rem 1rem' }} fz='sm' variant='outline' color={getStatusColor(request.status)} ml='1rem'>
          {request.status.replace('-', ' ')}
        </Badge>
      </Flex>
    </Box>

    <ApprovalRequestDetails request={request} readyPaymentRequests={readyPaymentRequests} />
  </Paper>
)

export default ApprovalRequestDetailsArea

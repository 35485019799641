import { Box, Button, SimpleGrid } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSGridProps } from '../../../../components/common/CSSGrid'
import { IconAccount, IconExchange, IconReceive, IconSend } from '../../../../components/icons'
import { openModalForBankAccountDetails } from '../../../../components/modals/BankAccountDetails'
import { openModalForInternalAccountTransfer } from '../../../../components/modals/InternalAccountTransfer'
import { colorAliases } from '../../../../theme/mantineTheme'
import { AccountGroup } from '../../../../utils/interfaces'

const OptionButtons: React.FC<CSSGridProps & { group: AccountGroup; withoutAccounts?: boolean }> = ({ group, withoutAccounts = false }) => {
  const navigate = useNavigate()

  return (
    <SimpleGrid cols={2} spacing='sm'>
      <Button size='large' variant='leikur-dark' leftSection={<IconSend />} onClick={() => navigate('/transaction-centre/')}>
        <Box>
          Send
          <br />
          <Box component='span' c={colorAliases.light50}>
            pay beneficiary
          </Box>
        </Box>
      </Button>
      {!withoutAccounts && (
        <Button size='large' variant='leikur-dark' leftSection={<IconAccount />} onClick={() => navigate('/statement-centre/' + group.iban)}>
          <Box>
            Account
            <br />
            <Box component='span' c={colorAliases.light50}>
              information & statement
            </Box>
          </Box>
        </Button>
      )}
      <Button size='large' variant='leikur-dark' leftSection={<IconExchange />} onClick={openModalForInternalAccountTransfer({ selectedIban: group.iban })}>
        <Box>
          Transfer
          <br />
          <Box component='span' c={colorAliases.light50}>
            move internally
          </Box>
        </Box>
      </Button>
      <Button
        size='large'
        variant='leikur-dark'
        leftSection={<IconReceive />}
        onClick={openModalForBankAccountDetails({
          bank_account_details: group.accounts[0]?.account_addresses[0].bank_account_details,
        })}
      >
        <Box>
          Receive
          <br />
          <Box component='span' c={colorAliases.light50}>
            payment instructions
          </Box>
        </Box>
      </Button>
    </SimpleGrid>
  )
}

export default OptionButtons

import { ActionIcon, Container, Overlay, Paper } from '@mantine/core'
import React from 'react'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconCloseCross } from '../../../../components/icons'
import { AccountGroup } from '../../../../utils/interfaces'
import OptionButtons from './OptionButtons'
import { colorAliases } from '../../../../theme/mantineTheme'

const OverlayedMenu: React.FC<{
  close: () => void
  group: AccountGroup
  top: number
}> = ({ close, group, top }) => {
  return (
    <Overlay blur={5} opacity={0.2} fixed onClick={close}>
      <Container pos='absolute' inset={0}>
        <Paper p='lg' left={0} right={0} pos='absolute' top={top} bg='light.0'>
          <CSSGrid templateColumns='1fr auto' gap='md' h='100%'>
            <OptionButtons group={group} sx={{ alignSelf: 'center' }} />
            <ActionIcon variant='transparent' size='sm' color={colorAliases.borderHighlight} onClick={close}>
              <IconCloseCross />
            </ActionIcon>
          </CSSGrid>
        </Paper>
      </Container>
    </Overlay>
  )
}

export default OverlayedMenu

import { Box, BoxProps, Container, Flex } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '../common/Menu'
import { LogoLeikur } from '../logos'
import { colorAliases } from '../../theme/mantineTheme'
import { useSignOut } from '@/api/auth/hooks'

interface AppNavbarProps extends BoxProps {
  opened: boolean
  onClose: () => void
}

const AppNavbar: React.FC<AppNavbarProps> = ({ opened, onClose, ...props }) => {
  const navigate = useNavigate()
  const { mutate: signOut } = useSignOut()

  return (
    <>
      {opened && <Box pos='fixed' top={0} left={0} w='100vw' h='100vh' bg='rgba(0, 0, 0, 0.5)' onClick={onClose} sx={{ zIndex: 9 }} />}

      <Box
        component='nav'
        pos='fixed'
        top={0}
        left={opened ? 0 : '-100%'} // Slide in from the left
        w={300}
        h='100vh'
        bg='light.7'
        sx={{
          zIndex: 10,
          transition: 'left 0.3s ease-in-out', // Smooth slide-in effect
          boxShadow: '2px 0 8px rgba(0, 0, 0, 0.2)',
        }}
        {...props}
      >
        <Container pos='relative' h='4.25rem'>
          <Flex align='center' justify='space-between'>
            <Box component={LogoLeikur} w='8.5rem' />
          </Flex>
          <Menu
            closeButtonProps={{ size: 'lg' }}
            size={412}
            zIndex={1001}
            opened={opened}
            setOpened={onClose}
            title={
              <Box fz='2xl' fw={700} lh={1} ta='center' w='100%' c={colorAliases.textInvert}>
                Menu &amp; Settings
              </Box>
            }
            overlayProps={{ blur: 5, opacity: 0.2 }}
            shadow='lg'
          >
            <MenuItem
              title='Account Overview'
              textColor={colorAliases.textInvert}
              description='Dashboard of all your accounts'
              onClick={() => {
                navigate('/dashboard')
                onClose()
              }}
            />
            <MenuItem
              textColor={colorAliases.textInvert}
              title='Account Profile'
              description='Privileges, update info, personalization'
              borderWidth='sm'
              borderPosition='top'
              borderColor='gray.4'
            >
              <MenuItem
                textColor={colorAliases.textInvert}
                title='Profile Information'
                description='Passwords, manage info, address'
                borderWidth='xs'
                borderPosition='top'
                borderColor='gray.4'
                align='right'
                onClick={() => {
                  navigate('/')
                  onClose()
                }}
              />
            </MenuItem>
            <MenuItem
              textColor={colorAliases.textInvert}
              title='Help & Contact Us'
              description='Reach out for assistance & to give us feedback'
              borderWidth='sm'
              borderPosition='top'
              borderColor='gray.4'
              onClick={() => {
                navigate('/')
                onClose()
              }}
            />
            <MenuItem
              title='Log Out'
              textColor={colorAliases.textInvert}
              description='Log out of your banking profile'
              onClick={() => {
                signOut()
                onClose()
              }}
            />
          </Menu>
        </Container>
      </Box>
    </>
  )
}

export default AppNavbar

import { Box, Flex, getThemeColor } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { colorAliases } from '../../../../theme/mantineTheme'
import currencies, { currencyFormat } from '../../../../utils/currencies'
import { Account } from '../../../../utils/interfaces'
import { Flag } from '../../../../components/Flag'

const AccountDetails: React.FC<{ account: Account }> = ({ account }) => {
  const navigate = useNavigate()

  return (
    <CSSGrid
      gap='md'
      templateRows='auto'
      sx={(theme) => ({
        borderRadius: theme.radius['xs'],
        border: '1px solid',
        borderColor: getThemeColor(colorAliases.borderSubtle, theme),
        cursor: 'pointer',
        userSelect: 'none',
      })}
      bg={colorAliases.surfaceLightgray}
      onClick={() => navigate(`/statement-centre/${account.iban}/${account.id}`)}
      p={4}
    >
      <Flex
        p={4}
        w={146}
        h={56}
        bg={colorAliases.surfaceSemiWhite}
        align='center'
        justify='start'
        sx={{
          borderRadius: '2px',
        }}
      >
        <Box w='2.8125rem' h='2.8125rem'>
          <Flag
            countryCode={currencies[account.currency]?.countryCodeForFlag}
            square={true}
            sx={{ borderRadius: '50%', border: '1pt solid #f5f5f5', filter: 'brightness(1.4) saturate(0.8)' }}
            style={{
              borderRadius: '50%',
              width: '2.8125rem',
              height: '2.8125rem',
            }}
          />
        </Box>
        <Flex pl='sm' h={38} direction='column' gap={2} justify='space-between'>
          <Box c={colorAliases.textAccent} lh='1' fw='600' fz='lg'>
            {currencies[account.currency]?.symbol}
          </Box>
          <Box fw='900' ff='"Object Sans"' c='dark' lh='1' fz='sm'>
            {account.currency}
          </Box>
        </Flex>
      </Flex>
      <Box c='dark' ta='center'>
        <Box component='span' fw='600' fz='lg'>
          {currencyFormat.format(account.balance)}
        </Box>
        <br />
        <Box component='span' fz='sm'>
          {currencies[account.currency].regionName}
        </Box>
      </Box>
    </CSSGrid>
  )
}

export default AccountDetails

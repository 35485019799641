import { Navigate, Outlet } from 'react-router-dom'
import { useValidateSession } from '@/api/auth/hooks'
import { Loader } from '@mantine/core'

const Authenticator = () => {
  const { data: isValidSession, isLoading, isError } = useValidateSession()

  if (isLoading) return <Loader />

  if (isError || !isValidSession) return <Navigate replace to='/sign-in' />

  return <Outlet />
}

export default Authenticator

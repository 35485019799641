import { ActionIcon, Anchor, Box, BoxProps, Divider, Flex, Text, Title } from '@mantine/core'
import useFetchTransactionsByBeneficiary from '../../../api/queries/useFetchTransactionsByBeneficiary'
import formatIBAN from '../../../utils/formatIBAN'
import { Beneficiary, PaymentBeneficiary } from '../../../utils/interfaces'
import { IconDocChecked, IconTickMarkYes, IconViewInfoEye } from '../../../components/icons'
import BeneficiaryTransactionItem from '../../../components/common/BeneficiaryTransactionItem'
import { CSSGrid } from '../../../components/common/CSSGrid'
import { colorAliases } from '../../../theme/mantineTheme'

const BeneficiaryDetails: React.FC<
  BoxProps & {
    beneficiary: Beneficiary & PaymentBeneficiary
  }
> = ({ beneficiary, ...props }) => {
  const { data: transactionsByBeneficiary } = useFetchTransactionsByBeneficiary(beneficiary.id)

  const handleViewDocument = (documentUrl: string) => {
    window.open(documentUrl, '_blank')
  }
  return (
    <Box pt='md' pb='xs' fz='xs' c='textPrimary' {...props}>
      <Text fz='sm' fw={700} pb='3xs' c={colorAliases.textInvertSecondary}>
        Beneficiary details
      </Text>
      <Box pl='2xs'>
        <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap={{ base: 'md', desktop: '2xl' }}>
          <Flex direction='column' gap='3xs' c={colorAliases.textInvertSecondary}>
            <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
              Alias
              <b>{beneficiary.alias}</b>
            </Text>
            <Text display='flex' sx={{ justifyContent: 'space-between' }} lh={1.3}>
              Name<b>{beneficiary.name}</b>
            </Text>

            <Flex justify='space-between' lh={1.3}>
              Entity<b style={{ textTransform: 'capitalize' }}>{beneficiary.entity_type}</b>
            </Flex>
          </Flex>

          <Flex direction='column' gap='3xs' c={colorAliases.textInvertSecondary}>
            <Flex justify='space-between' lh={1.3}>
              IBAN
              <b>{formatIBAN(beneficiary.account_details.iban)}</b>
            </Flex>
            <Flex justify='space-between' lh={1.3}>
              Network
              <b style={{ textTransform: 'uppercase' }}>{beneficiary.payment_type}</b>
            </Flex>
          </Flex>
        </CSSGrid>

        <CSSGrid templateColumns={{ base: '1fr', desktop: '1fr 1fr' }} gap={{ base: 'md', desktop: '2xl' }} mt={{ base: 'md', desktop: 'md' }}>
          <Flex direction='column' gap='3xs' c={colorAliases.textInvertSecondary}>
            <Flex justify='space-between' lh={1.3}>
              Reference<b>{beneficiary.reference || '-'}</b>
            </Flex>
            <Flex justify='space-between' lh={1.3}>
              Default mobile number<b>{beneficiary.default_payment.popMobileNumber?.formattedPhoneNumber ?? 'N/A'}</b>
            </Flex>
            <Flex justify='space-between' lh={1.3} mb='xs'>
              Default email address<b>{beneficiary.default_payment.popEmail ?? 'N/A'}</b>
            </Flex>
          </Flex>
          <Box>
            <Flex align='center' mb='xs'>
              <Box component={IconDocChecked} />
              <Text fz='xs' c={colorAliases.textInvertSecondary} ml='2xs'>
                Supporting documentation justifying payment (contract, receipt, invoice)
              </Text>
            </Flex>
            {beneficiary.default_payment?.documents?.map((d) => (
              <CSSGrid templateColumns='auto 1fr auto auto' key={d.document_id} mb='2xs' gap='2xs' alignItems='center'>
                <Box component={IconTickMarkYes} h='sm' c='success' ml={2} />
                <Anchor href={d.document_url} target='_blank' ml='sm'>
                  {d.document_name}
                </Anchor>
                <ActionIcon size='sm' variant='transparent' color='primaryHighlight' onClick={() => handleViewDocument(d.document_url)}>
                  <IconViewInfoEye />
                </ActionIcon>
              </CSSGrid>
            ))}
          </Box>
        </CSSGrid>
      </Box>
      <Divider color='muted' />
      <Title order={3} fz='sm' py='sm' c={colorAliases.dark900}>
        Transaction history
      </Title>
      {transactionsByBeneficiary ? (
        <Box>
          {transactionsByBeneficiary.map((t, i) => (
            <BeneficiaryTransactionItem key={t.id} transaction={t} isFirst={i === 0} />
          ))}
        </Box>
      ) : (
        <Box pl='2xs'>No recent transactions.</Box>
      )}
    </Box>
  )
}

export default BeneficiaryDetails

import { Carousel, Embla } from '@mantine/carousel'
import { Box, Flex, getThemeColor } from '@mantine/core'
import { useWindowEvent } from '@mantine/hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { CSSGrid } from '../../../../components/common/CSSGrid'
import { IconPlus } from '../../../../components/icons'
import { openModalForCreateNewCurrencyAccount } from '../../../../components/modals/CreateNewCurrencyAccount'
import { colorAliases } from '../../../../theme/mantineTheme'
import currencies from '../../../../utils/currencies'
import { AccountGroup } from '../../../../utils/interfaces'
import useIsMobile from '../../../../utils/useIsMobile'
import AccountDetails from './AccountDetails'
import { CSSObject } from '@emotion/react'

const AccountsBar: React.FC<{ group: AccountGroup; height: string }> = ({ group, height }) => {
  const [embla, setEmbla] = useState<Embla | null>(null)
  const [numberOfAccountsShown, setNumberOfAccountsShown] = useState(2)

  const isMobile = useIsMobile()

  const showAddCurrencyButton = group.accounts.length < Object.keys(currencies).length
  const lastSlideIndex = showAddCurrencyButton ? group.accounts.length : group.accounts.length - 1

  const [canScrollPrev, setCanScrollPrev] = useState(false)
  const [canScrollNext, setCanScrollNext] = useState(false)

  const onSelect = useCallback(() => {
    setCanScrollPrev(embla?.slidesNotInView().includes(0) || false)
    setCanScrollNext(embla?.slidesNotInView().includes(lastSlideIndex) || false)
  }, [embla, lastSlideIndex])

  useEffect(() => {
    if (embla) {
      const slidesShown = embla.slidesInView().length
      setNumberOfAccountsShown(slidesShown)
      setCanScrollPrev(embla.slidesNotInView().includes(0))
      setCanScrollNext(embla.slidesNotInView().includes(lastSlideIndex))
      if (slidesShown < embla.slideNodes().length) embla.on('scroll', onSelect)
      return () => {
        if (slidesShown < embla.slideNodes().length) {
          embla.off('scroll', onSelect)
        }
      }
    }
  }, [embla, onSelect, lastSlideIndex])

  useWindowEvent('resize', () => {
    if (embla) {
      const slidesShown = embla.slidesInView().length
      setNumberOfAccountsShown(slidesShown)
      setCanScrollPrev(embla.slidesNotInView().includes(0))
      setCanScrollNext(embla.slidesNotInView().includes(lastSlideIndex))
    }
  })

  const shadowStyles: CSSObject = {
    zIndex: 2,
    position: 'absolute',
    width: isMobile ? 48 : 64,
    top: 0,
    bottom: 0,
    boxShadow: '0px 0px 8px 2px #1c1c1c5e',
  }

  return (
    <Carousel
      height={height}
      align='start'
      getEmblaApi={setEmbla}
      withControls={canScrollNext || canScrollPrev}
      px='2xl'
      slidesToScroll={numberOfAccountsShown > 2 ? numberOfAccountsShown - 1 : 2}
      inViewThreshold={0.975}
      containScroll='trimSnaps'
      dragFree={canScrollNext || canScrollPrev}
      draggable={canScrollNext || canScrollPrev}
      controlSize={isMobile ? 32 : 42}
      controlsOffset={isMobile ? 8 : 14}
      styles={(theme) => ({
        container: { gap: 4 },
        controls: {
          zIndex: 3,
        },
        control: {
          background: getThemeColor(colorAliases.brandPrimary, theme),
          color: '#ffffff',
          ':first-of-type': {
            opacity: canScrollPrev ? 1 : 0,
            cursor: canScrollPrev ? 'pointer' : 'default',
          },
          ':last-of-type': {
            opacity: canScrollNext ? 1 : 0,
            cursor: canScrollNext ? 'pointer' : 'default',
          },
        },
      })}
      // sx={{
      // overflow: 'hidden',

      // ':before': {
      //   // content: canScrollPrev ? '""' : undefined,
      //   // left: 0,
      //   ...shadowStyles,
      // },
      // ':after': {
      //   content: canScrollNext ? '""' : undefined,
      //   right: 0,
      //   ...shadowStyles,
      // },
      // }}

      //FIXME: test is this works
      style={{
        overflow: 'hidden',

        ':before': {
          content: canScrollPrev ? '""' : undefined,
          left: 0,
          ...shadowStyles,
        },
        ':after': {
          content: canScrollNext ? '""' : undefined,
          right: 0,
          ...shadowStyles,
        },
      }}
    >
      {group.accounts.map((acc) => (
        <AccountDetails key={acc.id} account={acc} />
      ))}

      {showAddCurrencyButton && (
        <CSSGrid
          gap='md'
          templateRows='auto'
          sx={(theme) => ({
            borderRadius: theme.radius['xs'],
            border: '1px solid',
            borderColor: getThemeColor(colorAliases.borderSubtle, theme),
            cursor: 'pointer',
            userSelect: 'none',
          })}
          bg={colorAliases.surfaceLightgray}
          onClick={openModalForCreateNewCurrencyAccount({
            accountGroup: group,
          })}
          p={4}
        >
          <Flex
            p={4}
            h={56}
            bg={colorAliases.surfaceSemiWhite}
            align='center'
            justify='center'
            sx={{
              borderRadius: '2px',
            }}
          >
            <Flex w='2.8125rem' h='2.8125rem' bg='primary' c='white' sx={{ borderRadius: '50%' }} justify='center' align='center'>
              <IconPlus height={30} width={30} viewBox='0 0 24 24' />
            </Flex>
          </Flex>
          <Box c='dark' ta='center'>
            <Box component='span' fw='600' fz='lg'>
              Add
            </Box>
            <br />
            <Box component='span' fz='sm'>
              Currency
            </Box>
          </Box>
        </CSSGrid>
      )}
    </Carousel>
  )
}

export default AccountsBar
